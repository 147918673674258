import { Tooltip, Typography } from "@material-ui/core";
import {
  GavelOutlined,
  MailOutlined,
  NotificationsOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import { format } from "date-fns";
import jsonExport from "jsonexport/dist";
import { debounce } from "lodash";
import qs from "qs";
import * as React from "react";
import {
  Datagrid,
  DateField,
  downloadCSV,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  ListProps,
  NullableBooleanInput,
  NumberInput,
  ReferenceField,
  ReferenceManyField,
  sanitizeListRestProps,
  SearchInput,
  SelectInput,
  TextField,
  TopToolbar,
  useGetIdentity,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { ActivitySummary } from "../Components/ActivitySummary";
import CustomPagination from "../Components/Pagination";
import { PaymentPlanSummary } from "../Components/PaymentPlanSummary";
import { OPS_MANAGERS } from "../config/AppConfig";
import { theme } from "../theme/theme";
import { stringToHslColor } from "../util/StyleUtils";

export const ClaimList = React.memo((props: ListProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  let jsonFilter: any = {
    isPlanned: "",
  };

  if (parsedLocation.filter) {
    try {
      jsonFilter = JSON.parse(parsedLocation.filter as string);
    } catch (e) {}
  }

  const [filters, setFilters] = React.useState({
    activitiesFilter: jsonFilter.activities?.toString(),
    creditorFeedbackRequiredFilter:
      jsonFilter.creditorFeedbackRequired?.toString(),
    stageFilter: jsonFilter.stage?.toString(),
    totalPendingFilterValue: jsonFilter.totalPending?.toString() || undefined,
    referenceFilterValue: jsonFilter.reference?.toString() || undefined,
    titleFilterValue: jsonFilter.title?.toString() || undefined,
  });

  const updateFilter = React.useCallback(
    (filterName: string, value: any) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: value,
      }));
    },
    [setFilters]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateFilter = React.useCallback(
    debounce((filterName, value) => {
      updateFilter(filterName, value);
    }, 300),
    [updateFilter]
  );

  const claimFilters = React.useMemo(
    () => [
      <SearchInput
        source="reference"
        alwaysOn
        placeholder="Reference"
        autoFocus
        fullWidth
        style={{ minWidth: 260 }}
        onChange={(event) => {
          const value = event.target?.value.trim();
          if (value.length > 2) {
            debouncedUpdateFilter("referenceFilterValue", value);
          } else {
            debouncedUpdateFilter("referenceFilterValue", undefined);
          }
        }}
      />,
      <SearchInput
        source="title"
        alwaysOn
        placeholder="Title"
        fullWidth
        style={{ minWidth: 260 }}
        onChange={(event) => {
          const value = event.target?.value.trim();
          if (value.length > 2) {
            debouncedUpdateFilter("titleFilterValue", value);
          } else {
            debouncedUpdateFilter("titleFilterValue", undefined);
          }
        }}
      />,
      <SelectInput
        alwaysOn
        source="status"
        label="Status"
        choices={[
          { label: "Open", value: "Open" },
          { label: "Reminder Level", value: "ReminderLevel" },
          { label: "Encashment Level", value: "EncashmentLevel" },
          { label: "Judicial Level", value: "JudicialLevel" },
          { label: "Paid", value: "Paid" },
          { label: "Cancelled", value: "Cancelled" },
          { label: "Paused", value: "Paused" },
          { label: "Closed", value: "Closed" },
        ]}
        optionText="label"
        optionValue="value"
        style={{ minHeight: "48px" }}
      />,
      <SelectInput
        alwaysOn
        source="activities"
        label="Activities"
        choices={[
          { label: "No", value: "false" },
          { label: "Yes", value: "true" },
          { label: "Planned: No", value: "noPlanned" },
          { label: "Planned: Yes", value: "yesPlanned" },
        ]}
        optionText="label"
        optionValue="value"
        value={filters.activitiesFilter}
        onChange={(event) => {
          updateFilter("activitiesFilter", event.target.value);
        }}
        style={{ minHeight: "48px" }}
      />,
      <NullableBooleanInput
        source="creditorFeedbackRequired"
        label="Creditor Feedback req."
        fullWidth
        style={{ minWidth: 180, minHeight: "40px" }}
        value={filters.creditorFeedbackRequiredFilter}
        onChange={(event) => {
          updateFilter("creditorFeedbackRequiredFilter", event.target.value);
        }}
      />,
      <SelectInput
        style={{ minWidth: 260, minHeight: "48px" }}
        source="stage"
        label="Stage"
        choices={[
          { label: "Reminder", value: "Reminder" },
          { label: "Precourt", value: "Precourt" },
          { label: "Court", value: "Court" },
          { label: "Monitoring", value: "Monitoring" },
        ]}
        value={filters.stageFilter}
        onChange={(event) => {
          updateFilter("stageFilter", event.target.value);
        }}
        optionText="label"
        optionValue="value"
      />,
      <NumberInput
        source="totalPending"
        placeholder="Total Pending"
        fullWidth
        onChange={(event) => {
          const value = Number(event.target.value);
          updateFilter("totalPendingFilterValue", value || undefined);
        }}
      />,
    ],
    [filters, debouncedUpdateFilter, updateFilter]
  );

  const filterValue = React.useMemo(() => {
    return {
      ...(filters.referenceFilterValue
        ? { reference: { contains: filters.referenceFilterValue || "" } }
        : { reference: { not: undefined } }),
      ...(filters.totalPendingFilterValue !== undefined && {
        totalPending: filters.totalPendingFilterValue,
      }),
      ...(filters.titleFilterValue
        ? { title: { contains: filters.titleFilterValue || "" } }
        : { title: { not: undefined } }),
      ...(filters.creditorFeedbackRequiredFilter === "true" && {
        creditorFeedbackRequired: { equals: true },
      }),
      ...(filters.stageFilter && { stage: filters.stageFilter }),
      ...(filters.creditorFeedbackRequiredFilter === "false" && {
        creditorFeedbackRequired: { equals: false },
      }),
      ...(filters.creditorFeedbackRequiredFilter !== "false" &&
        filters.creditorFeedbackRequiredFilter !== "true" && {
          creditorFeedbackRequired: {},
        }),
      ...(filters.activitiesFilter === "true" && {
        activities: [{ some: {} }],
      }),
      ...(filters.activitiesFilter === "false" && {
        activities: [{ none: {} }],
      }),
      ...(filters.activitiesFilter === "yesPlanned" && {
        activities: [{ some: { isPlanned: { equals: true } } }],
      }),
      ...(filters.activitiesFilter === "noPlanned" && {
        activities: [{ none: { isPlanned: { equals: true } } }],
      }),
      ...(filters.activitiesFilter !== "false" &&
        filters.activitiesFilter !== "true" &&
        filters.activitiesFilter !== "yesPlanned" &&
        filters.activitiesFilter !== "noPlanned" && {
          activities: [{}],
        }),
    };
  }, [filters]);

  const CustomActions = ({ maxResults, ...props }) => (
    <TopToolbar {...sanitizeListRestProps(props)}>
      <FilterButton style={{ marginRight: 10 }} />
      {OPS_MANAGERS.includes(identity?.username?.toString()) && (
        <ExportButton label="Export" maxResults={maxResults} />
      )}
    </TopToolbar>
  );

  const exporter = async (claims, fetchRelatedRecords) => {
    const claimsToReturn = [];
    const batchSize = 1000;
    const fetchBatchSize = 200; // Define the batch size for fetching users to avoid server overload

    // Helper function to fetch records in batches
    const fetchRecordsInBatches = async (records, field, type) => {
      const fetchedRecords = {};
      for (let i = 0; i < records.length; i += fetchBatchSize) {
        const batch = records.slice(i, i + fetchBatchSize);
        const batchResults = await fetchRelatedRecords(batch, field, type);
        Object.assign(fetchedRecords, batchResults);
      }
      return fetchedRecords;
    };

    // Pre-fetch all necessary debtor and creditor data in batches
    const allDebtors = await fetchRecordsInBatches(claims, "debtor.id", "User");
    const allCreditors = await fetchRecordsInBatches(
      claims,
      "creditor.id",
      "User"
    );

    // Extract address IDs from debtors
    const debtorsWithAddresses = Object.values(allDebtors).filter(
      (debtor: any) => debtor?.address?.id
    );

    // Fetch addresses related to debtor address IDs
    const allDebtorAddresses = await fetchRecordsInBatches(
      debtorsWithAddresses,
      "address.id",
      "Address"
    );

    // Helper function to process claims in batches
    const processBatch = async (batch) => {
      batch.forEach((record) => {
        const {
          id,
          comment,
          fileUrls,
          contactsIds,
          contacts,
          taxRate,
          ...claimForExport
        } = record;

        // Use pre-fetched data
        const debtor = allDebtors[record.debtor?.id];
        claimForExport.debtorName = debtor?.name;
        claimForExport.debtorType = debtor?.businessType;
        claimForExport.debtorEmailVerificationStatus =
          debtor?.emailVerificationStatus;
        claimForExport.debtorPhoneVerificationStatus =
          debtor?.phoneVerificationStatus;
        claimForExport.debtorPhone = !!debtor?.phone;
        claimForExport.debtorEmail = !!debtor?.email;

        // Add debtor address information
        const debtorAddress = allDebtorAddresses[debtor?.address?.id];
        claimForExport.debtorAddressCountry = debtorAddress
          ? debtorAddress.country
          : null;

        claimForExport.creditorName =
          allCreditors[record.creditor?.id]?.businessName;

        // Format dates only if necessary
        if (record.createdAt instanceof Date) {
          claimForExport.createdAt = format(record.createdAt, "dd.MM.yyyy");
        }
        if (record.dueDate instanceof Date) {
          claimForExport.dueDate = format(record.dueDate, "dd.MM.yyyy");
        }
        if (record.invoiceCreatedAt instanceof Date) {
          claimForExport.invoiceCreatedAt = format(
            record.invoiceCreatedAt,
            "dd.MM.yyyy"
          );
        }

        // Clean up unnecessary properties
        delete claimForExport["debtor"];
        delete claimForExport["creditor"];
        claimForExport["manager"] = "";
        claimForExport["assignee.id"] = "";

        claimForExport.managerId = record.manager?.id;

        claimsToReturn.push(claimForExport);
      });
    };

    // Process claims in batches
    for (let i = 0; i < claims.length; i += batchSize) {
      const batch = claims.slice(i, i + batchSize);
      await processBatch(batch);
    }

    // Export the processed claims to CSV
    jsonExport(
      claimsToReturn,
      {
        rowDelimiter: ",",
      },
      (err, csv) => {
        if (err) {
          console.error("Error exporting to CSV", err);
          return;
        }
        downloadCSV(csv, "claims");
      }
    );
  };

  const { identity } = useGetIdentity();

  return (
    <List
      {...props}
      actions={<CustomActions filters={claimFilters} maxResults={5000} />}
      exporter={
        OPS_MANAGERS.includes(identity?.username?.toString()) ? exporter : false
      }
      bulkActionButtons={false}
      hasCreate={false}
      title={"Claims"}
      perPage={10}
      empty={false}
      filter={filterValue}
      filters={claimFilters}
      pagination={<CustomPagination />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <ClaimListItems />
    </List>
  );
});

// Predefined styles for better performance
const datagridStyle = {
  marginTop: "1rem",
};

const referenceColumnStyle = {
  display: "flex",
  flexDirection: "column-reverse" as const,
  alignItems: "flex-start",
  justifyContent: "center",
};

const dateFieldStyle = {
  textAlign: "right" as const,
  verticalAlign: "middle",
  color: theme.palette.grey[500],
  fontSize: "0.75rem",
  fontWeight: "bold",
};

const dateFieldOptions = {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
  timeZone: "Europe/Berlin",
};

const textFieldStyle = {
  display: "block",
  minWidth: 110,
  maxWidth: 200,
};

// Memoized components for better performance
const MemoizedCreditorField = React.memo(({ record }: { record: any }) => {
  if (!record) return null;

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: 600,
        }}
      >
        <Typography
          component={"span"}
          variant={"body2"}
          style={{
            fontWeight: "600",
            maxWidth: "100%",
            color: stringToHslColor(record.businessName, 30),
            background: stringToHslColor(record.businessName, 95),
            borderRadius: 4,
            padding: "2px 6px",
          }}
        >
          {record.businessName}
        </Typography>
      </div>
      {!!record.operatorCategory && (
        <Tooltip
          className="widget"
          title={!!record.operatorCategory && ` ${record.operatorCategory}`}
        >
          <Typography
            component={"span"}
            variant={"body2"}
            style={{
              fontWeight: "600",
              wordBreak: "break-word",
              maxWidth: "100%",
            }}
          >
            <span
              style={{
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: stringToHslColor(record.operatorCategory, 70),
                color: stringToHslColor(record.operatorCategory, 30),
                padding: "3px 8px",
                fontSize: 10,
                fontWeight: "bold",
                borderRadius: 4,
                marginLeft: 5,
              }}
            >
              {record.operatorCategory?.slice(0, 4).toUpperCase()}
            </span>
          </Typography>
        </Tooltip>
      )}
    </div>
  );
});

const MemoizedDebtorField = React.memo(({ record }: { record: any }) => {
  if (!record) return null;

  const isConsumer = record.businessType === "Consumer";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <span
        title="Business Type"
        className="widget"
        style={{
          color: isConsumer ? "#995200" : "#000099",
          background: isConsumer ? "#ffc480" : "#d1d1ff",
          padding: 4,
          fontSize: 10,
          minWidth: 35,
          maxHeight: 30,
          borderRadius: 4,
        }}
      >
        {isConsumer ? "B2C" : "B2B"}
      </span>
      <div
        style={{
          display: "flex",
          fontSize: 13,
          verticalAlign: "middle",
          alignItems: "center",
          color: theme.palette.grey[700],
          fontWeight: 600,
        }}
      >
        {record.businessName || record.contactName || record.email}
      </div>
    </div>
  );
});

const statusIconStyle = { height: 14 };

const MemoizedStatusField = React.memo(({ record }: { record: any }) => {
  if (!record) return null;

  let bgColor;
  if (["Paid", "PaidOut"].includes(record.status)) {
    bgColor = theme.palette.success.dark;
  } else if (["Paused"].includes(record.status)) {
    bgColor = theme.palette.grey[800];
  } else if (["Open"].includes(record.status)) {
    bgColor = theme.palette.primary.main;
  } else if (["Closed", "Cancelled"].includes(record.status)) {
    bgColor = theme.palette.error.main;
  } else if (record.stage === "Court") {
    bgColor = theme.palette.error.dark;
  } else if (record.stage === "Precourt") {
    bgColor = theme.palette.secondary.main;
  } else if (record.stage === "Reminder") {
    bgColor = theme.palette.info.main;
  } else {
    bgColor = theme.palette.info.dark;
  }

  let StatusIcon = null;
  if (record.stage === "Precourt") {
    StatusIcon = <MailOutlined style={statusIconStyle} />;
  } else if (record.stage === "Court") {
    StatusIcon = <GavelOutlined style={statusIconStyle} />;
  } else if (record.stage === "Monitoring") {
    StatusIcon = <VisibilityOutlined style={statusIconStyle} />;
  } else if (record.stage === "Reminder") {
    StatusIcon = <NotificationsOutlined style={statusIconStyle} />;
  }

  const displayStatus = record.status.includes("Level")
    ? record.status.replace("Level", "")
    : record.status;

  return (
    <span
      style={{
        display: "flex",
        alignContent: "center",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 600,
        background: bgColor,
        color: "white",
        padding: "0.45rem 0.55rem",
        margin: "0 0.25rem",
        fontSize: "0.75rem",
        borderRadius: 8,
        minWidth: 180,
      }}
    >
      {StatusIcon}
      {record.stage} - {displayStatus}
    </span>
  );
});

// Memoized formatter for currency
const euroNumberFormat = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const amountContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  fontSize: 13,
  padding: "0 0.5rem",
  fontWeight: 600,
};

const MemoizedTotalPaidField = React.memo(({ record }: { record: any }) => {
  if (!record) return null;

  const style = {
    ...amountContainerStyle,
    color: theme.palette.success.dark,
  };

  return (
    <div style={style}>{euroNumberFormat.format(record.totalPaid || 0)}</div>
  );
});

const MemoizedTotalPendingField = React.memo(({ record }: { record: any }) => {
  if (!record) return null;

  // Create the formatter only when needed with the correct currency
  const numberFormat = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: record.currency || "EUR",
  });

  const style = {
    ...amountContainerStyle,
    color: theme.palette.grey[600],
  };

  return (
    <div style={style}>{numberFormat.format(record.totalPending || 0)}</div>
  );
});

export const ClaimListItems = React.memo(
  (props: { extended?: boolean; hiddenFields?: string[] }) => {
    const renderActivitySummary = React.useCallback(
      () => <ActivitySummary />,
      []
    );

    // Memoized render functions for FunctionFields
    const renderCreditor = React.useCallback(
      (record: any) => <MemoizedCreditorField record={record} />,
      []
    );

    const renderDebtor = React.useCallback(
      (record: any) => <MemoizedDebtorField record={record} />,
      []
    );

    const renderStatus = React.useCallback(
      (record: any) => <MemoizedStatusField record={record} />,
      []
    );

    const renderTotalPaid = React.useCallback(
      (record: any) => <MemoizedTotalPaidField record={record} />,
      []
    );

    const renderTotalPending = React.useCallback(
      (record: any) => <MemoizedTotalPendingField record={record} />,
      []
    );

    return (
      <Datagrid
        rowClick="show"
        stickyHeader
        optimized
        size={"small"}
        style={datagridStyle}
      >
        <ReferenceField
          link="show"
          label="Reference"
          source="id"
          reference="Claim"
        >
          <div style={referenceColumnStyle}>
            <DateField
              source="createdAt"
              label="Created"
              locales="de-DE"
              style={dateFieldStyle}
              options={dateFieldOptions}
            />
            <ReferenceField
              link="show"
              label="Reference"
              source="id"
              reference="Claim"
            >
              <TextField style={textFieldStyle} source="reference" />
            </ReferenceField>
          </div>
        </ReferenceField>

        {props.extended && (
          <ReferenceField
            link="show"
            label="Title"
            source="id"
            reference="Claim"
          >
            <TextField source="title" />
          </ReferenceField>
        )}

        {!props.hiddenFields?.includes("creditor") && (
          <ReferenceField
            link=""
            label="Creditor"
            source="creditor.id"
            reference="User"
          >
            <FunctionField label="Business Name" render={renderCreditor} />
          </ReferenceField>
        )}

        <ReferenceField
          link=""
          label="Debtor"
          source="debtor.id"
          reference="User"
        >
          <FunctionField label="Debtor" render={renderDebtor} />
        </ReferenceField>

        <FunctionField
          label="Status"
          source="status"
          sortable
          render={renderStatus}
        />

        <ReferenceManyField
          reference="Activity"
          target="ClaimId"
          label="Activities"
          sortable
          perPage={50}
          sort={{ field: "updatedAt", order: "DESC" }}
        >
          {renderActivitySummary()}
        </ReferenceManyField>

        <ReferenceManyField
          reference="PaymentPlan"
          target="ClaimId"
          label="Plans"
          sortable={false}
          perPage={1}
        >
          <PaymentPlanSummary />
        </ReferenceManyField>

        {props.extended && (
          <FunctionField
            source="totalPaid"
            label="Paid"
            sortable
            render={renderTotalPaid}
          />
        )}

        <FunctionField
          source="totalPending"
          label="Pending"
          sortable
          render={renderTotalPending}
        />

        {props.extended && (
          <DateField
            source="updatedAt"
            label="Updated"
            locales="de-DE"
            style={dateFieldStyle}
            options={dateFieldOptions}
          />
        )}
      </Datagrid>
    );
  }
);
