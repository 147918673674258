import qs from "qs";
import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceField,
  SearchInput,
  TextField,
} from "react-admin";
import { useLocation } from "react-router-dom";
import CustomPagination from "../Components/Pagination";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const PaymentInformationList = (
  props: ListProps
): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  let jsonFilter: any = {
    isPlanned: "",
  };

  if (parsedLocation.filter) {
    try {
      jsonFilter = JSON.parse(parsedLocation.filter as string);
    } catch (e) {}
  }

  const [accountFilterValue, setAccountFilterValue] = React.useState(
    jsonFilter.name?.toString() || undefined
  );

  const paymentInformationFilters = [
    <SearchInput
      filterToQuery={(searchText) => ({
        account: { startsWith: searchText },
      })}
      source="account"
      alwaysOn
      placeholder="Account"
      autoFocus
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 2) {
          setAccountFilterValue(event?.target.value);
        } else {
          setAccountFilterValue(undefined);
        }
      }}
      fullWidth
      style={{ minWidth: 260 }}
    />,
  ];

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"PaymentInformations"}
      perPage={50}
      empty={false}
      exporter={false}
      filter={{
        ...(!!accountFilterValue
          ? {
              account: { contains: accountFilterValue || "" },
            }
          : {
              account: { not: undefined },
            }),
      }}
      filters={paymentInformationFilters}
      pagination={<CustomPagination />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="show" optimized style={{ marginTop: "1rem" }}>
        <ReferenceField
          link="show"
          label="User"
          source="user.id"
          reference="User"
        >
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="Account" source="account" />
        <TextField label="Bank" source="bankIdentifier" />
        <TextField label="Method" source="method" />
        <TextField label="Currency" source="currency" />
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
      </Datagrid>
    </List>
  );
};
