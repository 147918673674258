import { Avatar } from "@material-ui/core";
import { AppBar, Layout, ReduxState, useGetIdentity, UserMenu } from "react-admin";
import { useSelector } from "react-redux";
import { AuthProviderHelper } from "../../auth-provider/auth-provider-wrapper";
// Custom UserMenu with Logout button
const CustomUserMenu = (props) => {
  const identity: any = useGetIdentity();
  
  return <UserMenu {...props} icon={<Avatar src={`${identity?.avatar}`} />}></UserMenu>;
};

// Custom AppBar that uses CustomUserMenu
const CustomAppBar = (props) => {
  return <AppBar {...props} userMenu={<CustomUserMenu />} />;
};

// Custom Layout that uses CustomAppBar
const CustomLayout = (props) => {
  const isSidebarOpen: boolean = useSelector(
    (state: ReduxState) => state.admin.ui.sidebarOpen
  );

  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      className={`${isSidebarOpen ? "-openSideBar" : "-closedSideBar"}`}
    >
      <AuthProviderHelper>{props.children}</AuthProviderHelper>
    </Layout>
  );
};

export default CustomLayout;
