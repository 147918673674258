import {
  Avatar,
  Badge,
  Button,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import React, { memo, Suspense, useCallback, useEffect, useState } from "react";
import { LoadingIndicator, useGetIdentity, useGetMany } from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import { VIP_USERS } from "../../../config/AppConfig";
import { useAircall } from "../../../context/hook/useAircall";
import { useClaim } from "../../../context/hook/useClaim";
import { useSocket } from "../../../context/hook/useSocket";
import { theme } from "../../../theme/theme";
import { ClaimDrawer } from "../../ClaimDrawer";
import { ActivitiesTab } from "./tabs/ActivitiesTab";

const GeneralTab = React.lazy(() => import("./tabs/GeneralTab"));
const PaymentsTab = React.lazy(() => import("./tabs/PaymentsTab"));
const CommentTab = React.lazy(() => import("./tabs/CommentTab"));
const FilesTab = React.lazy(() => import("./tabs/FilesTab"));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #e0e0e0",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    animation: "$flash 0.5s ease-in-out 1",
  },
  "@keyframes flash": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 1,
    },
  },
  MuiTabsIndicator: {
    backgroundColor: theme.palette.common.white,
  },
  "@keyframes iconBounce": {
    "0%, 100%": {
      transform: "scale(1) rotate(0deg)",
    },
    "25%": {
      transform: "scale(1.1) rotate(-10deg)",
    },
    "50%": {
      transform: "scale(1.2) rotate(0deg)",
    },
    "75%": {
      transform: "scale(1.1) rotate(10deg)",
    },
  },
  icon: {
    animation: "$iconBounce 0.5s ease-in-out",
  },
}));

const ActiveUsers = memo(({ claimId }: { claimId: string }) => {
  const [activeUsers, setActiveUsers] = React.useState<string[]>([]);
  const { socket, isConnected } = useSocket();
  const { identity } = useGetIdentity();

  // Fetch user details for active editors with debounced updates
  const { data: activeUserData } = useGetMany("User", activeUsers, {
    enabled: activeUsers.length > 0,
  });

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isConnected && socket && claimId && identity?.id) {
      Promise.resolve().then(() => {
        socket.emit("joinClaim", claimId);
        socket.emit("startEditing", claimId);
      });

      const handleActiveEditorsUpdate = (users: string[]) => {
        // Debounce updates to prevent rapid re-renders
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setActiveUsers(users);
        }, 300);
      };

      socket.on("activeEditorsUpdate", handleActiveEditorsUpdate);

      return () => {
        if (timeoutId) clearTimeout(timeoutId);
        socket.off("activeEditorsUpdate");

        // Async cleanup
        Promise.resolve().then(() => {
          if (socket.connected) {
            socket.emit("stopEditing", claimId);
          }
        });
      };
    }
  }, [isConnected, socket, claimId, identity?.id]);

  if (
    !activeUsers.length ||
    !activeUserData?.length ||
    (activeUserData.length === 1 &&
      activeUserData.some((user) => user.id === identity?.id))
  )
    return null;

  return (
    <div className="item-wrapper mobile-hide">
      {activeUserData.map((user) => {
        if (user.id === identity?.id) return null;
        return (
          <Tooltip
            key={user.id}
            title={`${user.name} is working on this claim`}
          >
            <Avatar
              style={{
                width: 25,
                height: 25,
                margin: "0 auto",
              }}
              className="active-user"
              src={
                "https://debtist2023.sharepoint.com/_layouts/15/userphoto.aspx?size=L&username=" +
                user?.username
              }
            />
          </Tooltip>
        );
      })}
    </div>
  );
});

export const ClaimTabs = React.memo((props: any) => {
  const {
    InfoOutlined,
    PaymentOutlined,
    TimelineOutlined,
    FolderOutlined,
    ForumOutlined,
    CallOutlined,
  } = icons;

  const [externalId, setExternalId] = useState<string>("");
  const [showActivityAdd, setShowActivityAdd] = useState(false);
  const [activityPlaceholderText, setActivityPlaceholderText] = useState("");
  const { identity } = useGetIdentity();
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(0);

  const { record, absoluteTotal, debtorData, creditorData } = useClaim(
    props.claimId
  );
  const { hidePhone, handleCallDebtor } = useAircall(record.id);
  const classes = useStyles();

  // Initialize tab from URL parameters
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    const tabFromUrl = parseInt(lastSegment, 10);

    if (!isNaN(tabFromUrl) && tabFromUrl >= 0) {
      setTabValue(tabFromUrl);
    } else {
      setTabValue(0); // Default to the first tab if invalid
    }
  }, [location.pathname]);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
      const pathSegments = location.pathname.split("/");
      const lastSegment = pathSegments[pathSegments.length - 2];

      const newPath =
        lastSegment === "show"
          ? pathSegments.slice(0, -1).join("/") + `/${newValue}`
          : pathSegments.join("/") + `/${newValue}`;

      history.push(newPath);
    },
    [history, location.pathname]
  );

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        classes={{
          root: classes.root,
        }}
      >
        <Tab
          label="General"
          icon={<InfoOutlined className={tabValue === 0 ? classes.icon : ""} />}
          classes={{
            selected: classes.selected,
          }}
        />
        <Tab
          label="Payments"
          icon={
            <PaymentOutlined className={tabValue === 1 ? classes.icon : ""} />
          }
          classes={{
            selected: classes.selected,
          }}
        />
        <Tab
          label="Activities"
          icon={
            <TimelineOutlined className={tabValue === 2 ? classes.icon : ""} />
          }
          classes={{
            selected: classes.selected,
          }}
        />
        {(identity?.username?.includes("debtist.de") ||
          identity?.username === "admin") && (
          <Tab
            label="Files"
            icon={
              <FolderOutlined className={tabValue === 3 ? classes.icon : ""} />
            }
            classes={{
              selected: classes.selected,
            }}
          />
        )}
        <Tab
          label="Comment"
          classes={{
            selected: classes.selected,
          }}
          icon={
            <Badge
              invisible={!record?.comment?.length}
              badgeContent={<></>}
              color={"primary"}
              variant="dot"
              overlap="rectangular"
              style={{ marginTop: 4 }}
            >
              <ForumOutlined className={tabValue === 4 ? classes.icon : ""} />
            </Badge>
          }
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: !hidePhone ? 75 : 20,
          }}
        >
          <ActiveUsers claimId={props.claimId} />
        </div>

        {!hidePhone && (
          <Button
            hidden={hidePhone}
            title="Call Debtor"
            onClick={handleCallDebtor}
            className="mobile-hide"
            style={{
              position: "absolute",
              right: 25,
              top: "50%",
              padding: 8,
              transform: "translateY(-50%)",
            }}
            size="small"
          >
            <>
              <CallOutlined />
              {(!debtorData.phoneVerificationStatus ||
                debtorData.phoneVerificationStatus === "UNKNOWN") && (
                <icons.Help
                  style={{
                    height: 14,
                    color: theme.palette.warning.dark,
                  }}
                />
              )}
              {debtorData.phoneVerificationStatus === "VALID" && (
                <icons.VerifiedUser
                  style={{
                    height: 14,
                    color: theme.palette.success.main,
                  }}
                />
              )}
              {debtorData.phoneVerificationStatus === "INVALID" && (
                <icons.RemoveCircle
                  style={{
                    height: 14,
                    color: theme.palette.error.main,
                  }}
                />
              )}
            </>
          </Button>
        )}
      </Tabs>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1em",
            paddingRight: 0,
            backgroundColor: "#f9fbff",
          }}
          className="notranslate"
          hidden={tabValue !== 0}
          id={`vertical-tabpanel-${0}`}
        >
          <GeneralTab claimId={props?.claimId} />{" "}
          {
            // always render it, contains important stuff we need on all tabs
          }
        </div>
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
            backgroundColor: "#f9fbff",
          }}
          className="notranslate"
          hidden={tabValue !== 1}
          id={`vertical-tabpanel-${1}`}
        >
          {tabValue === 1 && <PaymentsTab absoluteTotal={absoluteTotal} />}
        </div>
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
            backgroundColor: "#f9fbff",
          }}
          className="notranslate"
          hidden={tabValue !== 2}
          id={`vertical-tabpanel-${2}`}
        >
          {tabValue === 2 && (
            <ActivitiesTab
              showActivityAdd={showActivityAdd}
              setShowActivityAdd={setShowActivityAdd}
              activityPlaceholderText={activityPlaceholderText}
              setActivityPlaceholderText={setActivityPlaceholderText}
              externalId={externalId}
              setExternalId={setExternalId}
              creditor={creditorData}
              debtor={debtorData}
            />
          )}
        </div>
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
            backgroundColor: "#f9fbff",
          }}
          className="notranslate"
          hidden={tabValue !== 3}
          id={`vertical-tabpanel-${3}`}
        >
          {tabValue === 3 && <FilesTab />}
        </div>
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
            backgroundColor: "#f9fbff",
          }}
          className="notranslate"
          hidden={tabValue !== 4}
          id={`vertical-tabpanel-${4}`}
        >
          {tabValue === 4 && <CommentTab />}
        </div>
      </Suspense>
      {VIP_USERS.includes(identity?.username) && <ClaimDrawer />}
    </div>
  );
});
