import { Grid, Tooltip } from "@material-ui/core";
import { AttachMoney, AvTimer, SortByAlphaOutlined } from "@material-ui/icons";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import React, { useEffect, useState } from "react";
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import { GERMAN_SPEAKING_COUNTRIES } from "../../../api/address/EnumAddressCountry";
import { Claim } from "../../../api/claim/Claim";
import { theme } from "../../../theme/theme";
import { TODAY } from "../../../util/DateUtils";
import { getAuthToken } from "../../../util/JsonFilter";
import { ClaimSummary } from "../../ClaimSummary";
import GridHeader from "../../GridHeader";

const PlannedCallsToDoTab = React.memo((props: any) => {
  const limitPerPage = 25;
  const [claims, setClaims] = useState<Claim[]>([]);
  const [claimsToShow, setClaimsToShow] = useState(25); // local pagination
  const [languageFilter, setLanguageFilter] = useState<String | undefined>();
  const [userTypeFilter, setUserTypeFilter] = useState<String | undefined>();
  const [claimsSortOrder, setClaimsSortOrder] = useState("asc");
  const [claimsSortField, setClaimsSortField] = useState("updatedAt");
  const [activeSearchTerm, setActiveSearchTerm] = useState("");
  const [legalTypeFilter, setLegalTypeFilter] = useState<String | undefined>();

  function toggleOpenClaimsSortField(field: string): void {
    setClaimsSortField(claimsSortField === "updatedAt" ? field : "updatedAt");
    if (claimsSortOrder === "asc" && claimsSortField === "updatedAt") {
      setClaimsSortOrder("desc");
    }
  }

  function toggleOpenClaimsSortOrder(): void {
    setClaimsSortOrder(claimsSortOrder === "desc" ? "asc" : "desc");
  }

  const fetchData = () => {
    const authHeader = {
      headers: {
        Authorization: getAuthToken(),
        "Content-Type": "application/json",
      },
    };

    props.setIsLoading(true);
    fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
      method: "POST",
      body: JSON.stringify({
        type: "calls",
        disablePersonalization:
          props.personalization === false ? true : undefined,
        userId: !!props.selectedManager ? props.selectedManager : undefined,
        enableLegal: props.enableLegal === true ? true : undefined,
        where: {
          creditorFeedbackRequired: {
            not: true,
          },
          status: {
            notIn: ["Open", "Paid", "Cancelled", "Closed"],
          },
          activities: {
            some: {
              activityDate: {
                lte: TODAY,
              },
              activityType: "OutboundCall",
              isPlanned: true,
            },
          },
          ...((languageFilter || userTypeFilter) && {
            debtor: {
              ...(languageFilter === "German" && {
                address: {
                  country: {
                    in: GERMAN_SPEAKING_COUNTRIES,
                  },
                },
              }),
              ...(languageFilter === "Local" && {
                address: {
                  country: {
                    in: [
                      "ALB",
                      "ARE",
                      "ARM",
                      "AZE",
                      "BEL",
                      "BIH",
                      "BGR",
                      "BHR",
                      "BLR",
                      "CYP",
                      "CZE",
                      "DNK",
                      "ESP",
                      "EST",
                      "FIN",
                      "FRA",
                      "GBR",
                      "GEO",
                      "GRC",
                      "HRV",
                      "HUN",
                      "IRL",
                      "ISL",
                      "ITA",
                      "KAZ",
                      "KGZ",
                      "LTU",
                      "LUX",
                      "LVA",
                      "MKD",
                      "MLT",
                      "MNE",
                      "MNG",
                      "NLD",
                      "NOR",
                      "POL",
                      "PRT",
                      "ROU",
                      "RUS",
                      "SAU",
                      "SRB",
                      "SVK",
                      "SVN",
                      "SWE",
                      "TJK",
                      "TKM",
                      "UKR",
                      "UZB",
                      "BMU",
                      "JEY",
                      // africa
                      "DZA",
                      "AGO",
                      "BEN",
                      "BFA",
                      "BDI",
                      "CPV",
                      "CMR",
                      "CAF",
                      "COM",
                      "COD",
                      "DJI",
                      "EGY",
                      "GNQ",
                      "ERI",
                      "SWZ",
                      "ETH",
                      "GAB",
                      "GMB",
                      "GHA",
                      "GIN",
                      "CIV",
                      "KEN",
                      "LSO",
                      "LBR",
                      "MDG",
                      "MWI",
                      "MLI",
                      "MAR",
                      "MOZ",
                      "NAM",
                      "NER",
                      "NGA",
                      "RWA",
                      "STP",
                      "SEN",
                      "SYC",
                      "SLE",
                      "SOM",
                      "ZAF",
                      "SSD",
                      "SDN",
                      "TZA",
                      "TGO",
                      "TUN",
                      "UGA",
                      "ZMB",
                      "ZWE",
                    ],
                  },
                },
              }),
              ...(languageFilter === "NonLocal" && {
                address: {
                  country: {
                    notIn: [
                      "ALB",
                      "ARM",
                      "AZE",
                      "AUT",
                      "BEL",
                      "BIH",
                      "BGR",
                      "BLR",
                      "CHE",
                      "CYP",
                      "CZE",
                      "DEU",
                      "DNK",
                      "ESP",
                      "EST",
                      "FIN",
                      "FRA",
                      "GBR",
                      "GEO",
                      "GRC",
                      "HRV",
                      "HUN",
                      "IRL",
                      "ISL",
                      "ITA",
                      "KAZ",
                      "KGZ",
                      "LTU",
                      "LUX",
                      "LVA",
                      "MKD",
                      "MLT",
                      "MNE",
                      "NLD",
                      "NOR",
                      "POL",
                      "PRT",
                      "ROU",
                      "RUS",
                      "SRB",
                      "SVK",
                      "SVN",
                      "SWE",
                      "TJK",
                      "TKM",
                      "UKR",
                      "UZB",
                      // africa
                      "DZA",
                      "AGO",
                      "BEN",
                      "BFA",
                      "BDI",
                      "CPV",
                      "CMR",
                      "CAF",
                      "COM",
                      "COD",
                      "DJI",
                      "EGY",
                      "GNQ",
                      "ERI",
                      "SWZ",
                      "ETH",
                      "GAB",
                      "GMB",
                      "GHA",
                      "GIN",
                      "CIV",
                      "KEN",
                      "LSO",
                      "LBR",
                      "MDG",
                      "MWI",
                      "MLI",
                      "MAR",
                      "MOZ",
                      "NAM",
                      "NER",
                      "NGA",
                      "RWA",
                      "STP",
                      "SEN",
                      "SYC",
                      "SLE",
                      "SOM",
                      "ZAF",
                      "SSD",
                      "SDN",
                      "TZA",
                      "TGO",
                      "TUN",
                      "UGA",
                      "ZMB",
                      "ZWE",
                    ],
                  },
                },
              }),
              ...(languageFilter === "NonGerman" && {
                address: {
                  country: {
                    notIn: GERMAN_SPEAKING_COUNTRIES,
                  },
                },
              }),
              ...(userTypeFilter === "Consumer" && {
                businessType: "Consumer",
              }),
              ...(userTypeFilter === "Business" && {
                businessType: {
                  not: "Consumer",
                },
              }),
            },
          }),
          ...(legalTypeFilter === "Nothing" && {
            stage: "Precourt",
            contacts: {
              none: {
                userType: {
                  in: ["Lawyer", "InsolvencyAdministrator"],
                },
              },
            },
          }),
          ...(legalTypeFilter === "Court" && {
            stage: "Court",
          }),
          ...(legalTypeFilter === "Lawyers" && {
            contacts: {
              some: {
                userType: {
                  in: ["Lawyer", "InsolvencyAdministrator"],
                },
              },
            },
            stage: "Precourt",
          }),
          ...(activeSearchTerm && {
            creditor: {
              id: activeSearchTerm,
            },
          }),
        },
        orderBy: {
          [claimsSortField]: claimsSortOrder,
        },
      }),
      ...authHeader,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          props.setTotalPlannedCalls(responseJson.total);

          if (claimsSortField === "updatedAt") {
            const sortedClaims = responseJson.results?.sort(
              (a: Claim, b: Claim) => {
                // Sort by isVip first: true comes first
                /*
                if (a.creditor?.isVip && !b.creditor?.isVip) return -1;
                if (!a.creditor?.isVip && b.creditor?.isVip) return 1;
                */

                // Then sort by activityDate
                const dateA = new Date(
                  a.activities?.[0]?.activityDate || Date.now()
                ).getTime();
                const dateB = new Date(
                  b.activities?.[0]?.activityDate || Date.now()
                ).getTime();
                return dateA - dateB;
              }
            );
            setClaims(sortedClaims);
          } else {
            setClaims(responseJson.results);
          }
          props.setCallsLoaded(true);
          props.setIsLoading(false);
        }
      });
  };

  // Initial load when tab becomes active
  useEffect(() => {
    if (props.tabValue === 0 && !claims?.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabValue]);

  // Handle filter changes separately, only when tab is active
  useEffect(() => {
    if (props.tabValue === 0) {
      // Only fetch if we already have claims or any filter is set
      // This prevents a duplicate fetch when the tab is first activated
      if (
        claims?.length ||
        languageFilter ||
        userTypeFilter ||
        activeSearchTerm ||
        claimsSortField !== "updatedAt" ||
        claimsSortOrder !== "asc" ||
        props.personalization === false ||
        props.selectedManager ||
        props.enableLegal === true
      ) {
        fetchData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    claimsSortField,
    languageFilter,
    legalTypeFilter,
    userTypeFilter,
    claimsSortOrder,
    props.personalization,
    props.selectedManager,
    props.enableLegal,
    activeSearchTerm,
  ]);

  return (
    <div
      role="tabpanel"
      className="notranslate"
      hidden={props.tabValue !== 0}
      id={`vertical-tabpanel-${0}`}
    >
      <div className="tab-col">
        <div className="flex-row" id="filter-group">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SimpleForm
                toolbar={false}
                style={{
                  maxWidth: 340,
                }}
              >
                <ReferenceInput
                  perPage={5}
                  filterToQuery={(searchText) =>
                    !!searchText && searchText.trim().length > 2
                      ? {
                          businessName: { contains: searchText },
                          roles: { array_contains: "creditor" },
                        }
                      : { roles: { array_contains: "creditor" } }
                  }
                  isRequired
                  allowEmpty
                  emptyText="-"
                  source={"creditor.businessName"}
                  reference={"User"}
                  label={"Creditor"}
                  onChange={(value) => {
                    setActiveSearchTerm(value);
                  }}
                >
                  <AutocompleteInput
                    suggestionLimit={5}
                    shouldRenderSuggestions={(value) => {
                      return value && value.trim().length > 2;
                    }}
                    optionText={"businessName"}
                  />
                </ReferenceInput>
              </SimpleForm>
            </Grid>
            <Grid item xs={12} md={6} className="filter-container">
              <Tooltip title={"Refresh"}>
                <RotateLeftIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                  }}
                  onClick={() => fetchData()}
                />
              </Tooltip>

              <Tooltip
                title={
                  claimsSortField === "updatedAt"
                    ? "Sort by creation date"
                    : "Sort by last update"
                }
              >
                <AvTimer
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                    color:
                      claimsSortField === "updatedAt"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortField("createdAt");
                  }}
                >
                  {claimsSortField === "updatedAt"
                    ? "Sort by creation date"
                    : "Sort by last update"}
                </AvTimer>
              </Tooltip>

              <Tooltip
                title={
                  claimsSortField === "updatedAt"
                    ? "Sort by value"
                    : "Sort by date"
                }
              >
                <AttachMoney
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                    color:
                      claimsSortField !== "totalPending"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortField("totalPending");
                  }}
                >
                  {claimsSortField === "updatedAt"
                    ? "Sort by value"
                    : "Sort by date"}
                </AttachMoney>
              </Tooltip>

              <Tooltip
                title={
                  claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"
                }
              >
                <SortByAlphaOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    height: 20,
                    color:
                      claimsSortOrder === "asc"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortOrder();
                  }}
                >
                  {claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"}
                </SortByAlphaOutlined>
              </Tooltip>
              <Tooltip
                title={"Filter B2X"}
                onClick={() => {
                  setUserTypeFilter(
                    userTypeFilter === "Business"
                      ? ""
                      : userTypeFilter === "Consumer"
                      ? "Business"
                      : "Consumer"
                  );
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    color:
                      userTypeFilter === "Consumer"
                        ? "#995200"
                        : userTypeFilter === "Business"
                        ? "#000099"
                        : "",
                    background:
                      userTypeFilter === "Consumer"
                        ? "#ffc480"
                        : userTypeFilter === "Business"
                        ? "#d1d1ff"
                        : "",
                    padding: 5,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {userTypeFilter === "Consumer"
                    ? "B2C"
                    : userTypeFilter === "Business"
                    ? "B2B"
                    : "B2X"}
                </span>
              </Tooltip>
              <div
                onClick={() => {
                  if (!props.isLoading) {
                    setLanguageFilter(
                      languageFilter === undefined
                        ? "German"
                        : languageFilter === "German"
                        ? "Local"
                        : languageFilter === "Local"
                        ? "NonGerman"
                        : languageFilter === "NonGerman"
                        ? "NonLocal"
                        : undefined
                    );
                  }
                }}
              >
                <ReactCountryFlag
                  countryCode={languageFilter?.includes("Local") ? "eu" : "de"}
                  svg
                  style={{
                    cursor: "pointer",
                    padding: 5,
                    marginRight: 10,
                    marginLeft: 10,
                    borderRadius: 25,
                    fontSize: 22,
                    backgroundColor: languageFilter?.includes("Non")
                      ? theme.palette.error.light
                      : languageFilter === undefined
                      ? theme.palette.grey[400]
                      : theme.palette.success.light,
                    opacity: languageFilter === undefined ? 0.75 : 1,
                  }}
                />
                <span
                  style={{
                    cursor: "pointer",
                    color: languageFilter?.includes("Non")
                      ? theme.palette.error.light
                      : theme.palette.success.light,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {languageFilter &&
                    (languageFilter?.includes("Local") ? "EU+AFR" : "DACH")}
                </span>
              </div>
              <Tooltip
                title={"Filter Legal Type"}
                onClick={() => {
                  setClaimsToShow(0);
                  setClaims([]);
                  props.setIsLoading(true);
                  setLegalTypeFilter(
                    legalTypeFilter === "Court"
                      ? "Lawyers"
                      : legalTypeFilter === "Lawyers"
                      ? "Nothing"
                      : legalTypeFilter === "Nothing"
                      ? ""
                      : "Court"
                  );
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    color:
                      legalTypeFilter === "Court"
                        ? "#995200"
                        : legalTypeFilter === "Lawyers"
                        ? "#000099"
                        : legalTypeFilter === "Nothing"
                        ? "#16450e"
                        : "",
                    background:
                      legalTypeFilter === "Court"
                        ? "#ffc480"
                        : legalTypeFilter === "Lawyers"
                        ? "#d1d1ff"
                        : legalTypeFilter === "Nothing"
                        ? "#7fc46d"
                        : "",
                    padding: 5,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {legalTypeFilter === "Court"
                    ? "Court"
                    : legalTypeFilter === "Lawyers"
                    ? "Lawyers"
                    : legalTypeFilter === "Nothing"
                    ? "Non-Legal"
                    : "Legalities"}
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </div>

        <GridHeader />

        {claims && claims.length > 0
          ? claims
              .slice(0, claimsToShow || limitPerPage)
              .map((claim: Claim) => {
                return (
                  <ClaimSummary
                    claim={claim}
                    type="call"
                    key={claim.id}
                    refresh={() => {
                      fetchData();
                    }}
                  />
                );
              })
          : "-"}

        <Button
          style={{
            marginTop: 10,
            maxWidth: 200,
            marginLeft: 0,
          }}
          onClick={() => setClaimsToShow(claimsToShow + limitPerPage)}
          label="Load more"
        />
      </div>
    </div>
  );
});

export default PlannedCallsToDoTab;
