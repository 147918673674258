import { useGetList } from "react-admin";
import { User } from "../../api/user/User";
import { ITALIAN_GUY } from "../../config/AppConfig";

export const useManagers = (props?: { onlyVerified?: boolean }) => {
  const {
    data: managers,
    loaded,
    total,
  } = useGetList<User>(
    "User",
    { page: 1, perPage: 50 },
    { field: "contactName", order: "ASC" },
    {
      businessName: {
        equals: "Debtist GmbH",
      },
      manager: {
        id: ITALIAN_GUY,
      },
      ...(props?.onlyVerified && {
        isVerified: { equals: true },
      }),
    }
  );

  return {
    managers,
    totalManagers: total,
    managersLoaded: loaded,
  };
};
