import { getReadableResult } from "../api/activity/Activity";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { CLAIM_TYPES } from "../api/claim/Claim";
import { User } from "../api/user/User";

export const DATE_REGEX = /(\d{2}-\d{2}-\d{4})/;

export const startsWithAnyTwoLetters = (str: string): Boolean => {
  return /^[A-Z]{2}/.test(str.toUpperCase());
};

export const calculateTaxValue = (netValue: number, taxRate: number) => {
  return parseFloat((Math.round(netValue * 100 * taxRate) / 100).toFixed(2));
};

export function getClaimTypeTitle(key: string): string {
  const claim = CLAIM_TYPES.find((claim) => claim.key === key);
  return claim ? claim.title : "";
}

export const SIDE_CLAIM_POSITIONS = [
  "Einwohnermeldeamt-Anfrage",
  "Gerichtskosten",
  "Gerichtsvollzieher",
  "Mahnbescheid",
];

export const ActivityResultsForOutbound = [
  { label: getReadableResult("NotReached"), value: "NotReached" },
  { label: getReadableResult("Reached"), value: "Reached" },
  {
    label: getReadableResult("ReachedPromisedToPay"),
    value: "ReachedPromisedToPay",
  },
  {
    label: getReadableResult("ReachedIgnored"),
    value: "ReachedIgnored",
  },
  {
    label: getReadableResult("ReachedRefusedToPay"),
    value: "ReachedRefusedToPay",
  },
  {
    label: getReadableResult("ReachedRefusedCorrectness"),
    value: "ReachedRefusedCorrectness",
  },
  {
    label: getReadableResult("ReachedPaymentPlanSetup"),
    value: "ReachedPaymentPlanSetup",
  },
  {
    label: getReadableResult("ReachedPaymentPlanAdjustment"),
    value: "ReachedPaymentPlanAdjustment",
  },
  {
    label: getReadableResult("ReachedPaymentPlanStop"),
    value: "ReachedPaymentPlanStop",
  },
];

export const ActivityResultsForDecisions = [
  { label: getReadableResult("Positive"), value: "Positive" },
  { label: getReadableResult("Negative"), value: "Negative" },
];

export const getDebtorGreetingByLanguage = (
  debtor: User,
  debtorLanguage: EnumAddressCountry
): string => {
  const isTeam = !!debtor?.businessName;

  // Define greetings for different languages
  let greeting: string;
  let teamSuffix: string = "team";
  let greetingSuffix: string;

  switch (debtorLanguage) {
    case "DEU": // German
      greeting = isTeam ? "Sehr geehrtes" : "Sehr geehrte(r)";
      teamSuffix = "-Team";
      greetingSuffix = "Mit freundlichen Grüßen,";
      break;
    case "ESP": // Spanish
      greeting = isTeam ? "Estimado equipo de" : "Estimado/a";
      teamSuffix = "";
      greetingSuffix = "Atentamente,";
      break;
    case "FRA": // French
      greeting = isTeam ? "Cher équipe de" : "Cher/Chère";
      teamSuffix = "";
      greetingSuffix = "Cordialement,";
      break;
    case "ITA": // Italian
      greeting = isTeam ? "Gentile team di" : "Gentile";
      teamSuffix = "";
      greetingSuffix = "Cordiali saluti,";
      break;
    case "DNK": // Danish
      greeting = isTeam ? "Kære team hos" : "Kære";
      teamSuffix = "";
      greetingSuffix = "Med venlig hilsen,";
      break;
    case "SWE": // Swedish
      greeting = isTeam ? "Bästa teamet på" : "Bästa";
      teamSuffix = "";
      greetingSuffix = "Med vänliga hälsningar,";
      break;
    case "GBR": // British English
    default:
      greeting = "Dear";
      teamSuffix = "team";
      greetingSuffix = "Best regards,";
      break;
  }

  let recipient: string;
  if (debtor?.businessName) {
    // Handle business name with appropriate team suffix based on language
    if (debtorLanguage === "DEU") {
      recipient = `${debtor.businessName}${teamSuffix}`;
    } else if (["ESP", "FRA", "ITA", "DNK", "SWE"].includes(debtorLanguage)) {
      recipient = `${greeting} ${debtor.businessName}`;
      greeting = ""; // Reset greeting as it's included in recipient for these languages
    } else {
      recipient = `${debtor.businessName} ${teamSuffix}`;
    }
  } else if (debtor?.contactName) {
    recipient = debtor.contactName;
  } else {
    recipient = debtor?.name || "";
  }

  // Handle special case where greeting is already included in recipient
  if (greeting === "") {
    return `<p>${recipient},</p><br /><p>${greetingSuffix}</p>`;
  }

  return `<p>${greeting} ${recipient},</p><br /><p>${greetingSuffix}</p>`;
};
