import Event from "@material-ui/icons/Event";
import React from "react";
import { useListContext } from "react-admin";
import { PaymentPlan } from "../api/paymentPlan/PaymentPlan";
import { theme } from "../theme/theme";

// Pre-define styles to avoid recreation on each render
const iconStyle = { width: "auto", height: 18 };
const activeStyle = {
  color: theme.palette.success.main,
  fontSize: 10,
  display: "flex",
  alignItems: "flex-end",
};
const inactiveStyle = {
  color: theme.palette.grey[500],
  fontSize: 10,
  display: "flex",
  alignItems: "flex-end",
};

export const PaymentPlanSummary = React.memo(() => {
  const { data, loading } = useListContext();
  
  // Fast path for empty data
  if (loading || !data || Object.keys(data).length === 0) {
    return null;
  }
  
  // Quick access to first plan without unnecessary array conversion
  const planKeys = Object.keys(data);
  if (planKeys.length === 0) {
    return null;
  }
  
  const paymentPlan = data[planKeys[0]] as PaymentPlan;
  if (!paymentPlan) {
    return null;
  }
  
  const isActive = paymentPlan.isActive;
  const styleToUse = isActive ? activeStyle : inactiveStyle;

  return (
    <span title="Payment Plan Active" style={styleToUse}>
      <Event style={iconStyle} />
      {"x" + paymentPlan.numberOfPayments}
    </span>
  );
});
