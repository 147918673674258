import { Grid, Tooltip } from "@material-ui/core";
import { SortByAlphaOutlined } from "@material-ui/icons";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import React, { useEffect, useRef, useState } from "react";
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { getReadableSubjectByClaimAction } from "../../../api/activity/Activity";
import { Claim } from "../../../api/claim/Claim";
import { theme } from "../../../theme/theme";
import { TODAY } from "../../../util/DateUtils";
import { getAuthToken } from "../../../util/JsonFilter";
import { ClaimSummary } from "../../ClaimSummary";
import GridHeader from "../../GridHeader";
import { NonInput } from "../../NonInputComponent";

const OtherToDoTab = React.memo((props: any) => {
  const limitPerPage = 25;
  const [claims, setClaims] = useState<Claim[]>([]);
  const [claimsToShow, setClaimsToShow] = useState(0);
  const [activeSearchTerm, setActiveSearchTerm] = useState("");
  const [claimActionFilter, setClaimActionFilter] = useState("");
  const [claimsSortOrder, setClaimsSortOrder] = useState("asc");

  function toggleClaimsSortOrder(): void {
    setClaimsSortOrder(claimsSortOrder === "desc" ? "asc" : "desc");
  }

  const ref = useRef();

  const fetchData = (replace?: boolean) => {
    const authHeader = {
      headers: {
        Authorization: getAuthToken(),
        "Content-Type": "application/json",
      },
    };

    props.setIsLoading(true);
    fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
      method: "POST",
      body: JSON.stringify({
        type: "other",
        disablePersonalization:
          props.personalization === false ? true : undefined,
        userId: !!props.selectedManager ? props.selectedManager : undefined,
        enableLegal: props.enableLegal === true ? true : undefined,
        where: {
          creditorFeedbackRequired: {
            not: true,
          },
          activities: {
            some: {
              ...(claimActionFilter !== "" &&
                claimActionFilter !== "none" && {
                  claimAction: claimActionFilter,
                  activityType: {
                    in: [
                      "OutboundLetter",
                      "OutboundMail",
                      "InternalDecision",
                      "Other",
                    ],
                  },
                }),
              ...(claimActionFilter === "" && {
                claimAction: {
                  not: "FourthDunning",
                },
                activityType: {
                  in: ["Other"],
                },
              }),
              ...(claimActionFilter === "none" && {
                claimAction: null,
                activityType: {
                  in: [
                    "OutboundLetter",
                    "OutboundMail",
                    "InternalDecision",
                    "Other",
                  ],
                },
              }),
              activityDate: {
                lte: TODAY,
              },
              isPlanned: true,
            },
          },
          stage: {
            in: ["Precourt", "Court", "Monitoring"],
          },
          ...(activeSearchTerm && {
            creditor: {
              id: activeSearchTerm,
            },
          }),
        },
        take: limitPerPage,
        skip: claimsToShow,
        orderBy: {
          updatedAt: claimsSortOrder,
        },
      }),
      ...authHeader,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          props.setTotalOther(responseJson.total);

          const sortedClaims = responseJson.results?.sort(
            (a: Claim, b: Claim) =>
              new Date(
                a.activities?.[0]?.activityDate || Date.now()
              ).getTime() -
              new Date(b.activities?.[0]?.activityDate || Date.now()).getTime()
          );
          setClaims(sortedClaims);

          if (replace) {
            setClaims(sortedClaims);
          } else {
            setClaims([...claims, ...sortedClaims]);
          }
          props.setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    // Only fetch when tab is newly activated and no existing data
    if (props.tabValue === 2 && !claims?.length) {
      fetchData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabValue]);

  // Handle pagination separately from filter changes
  useEffect(() => {
    if (props.tabValue === 2 && claimsToShow > 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimsToShow]);

  useEffect(() => {
    // Only refresh when filters change and tab is active
    // This prevents duplicate fetches when tab becomes active
    if (props.tabValue === 2) {
      // Reset pagination when filters change
      if (
        activeSearchTerm ||
        props.personalization === false ||
        props.selectedManager ||
        props.enableLegal === true ||
        claimActionFilter !== ""
      ) {
        setClaimsToShow(0);
        fetchData(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.personalization,
    props.selectedManager,
    props.enableLegal,
    claimsSortOrder,
    activeSearchTerm,
    claimActionFilter,
  ]);

  return (
    <div
      role="tabpanel"
      className="notranslate"
      hidden={props.tabValue !== 2}
      id={`vertical-tabpanel-${2}`}
    >
      <div ref={ref} style={{ flexDirection: "row" }}>
        <div className="flex-row" id="filter-group">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <SimpleForm toolbar={false} style={{ display: "flex" }}>
                <NonInput>
                  <div className="tab-row">
                    <div style={{ width: 310, marginRight: 20 }}>
                      <ReferenceInput
                        perPage={5}
                        filterToQuery={(searchText) =>
                          !!searchText && searchText.trim().length > 2
                            ? {
                                businessName: { contains: searchText },
                                roles: { array_contains: "creditor" },
                              }
                            : { roles: { array_contains: "creditor" } }
                        }
                        isRequired
                        allowEmpty
                        emptyText="-"
                        source={"creditor.businessName"}
                        reference={"User"}
                        label={"Creditor"}
                        onChange={(searchTerm) => {
                          setClaimsToShow(0);
                          setActiveSearchTerm(searchTerm);
                        }}
                      >
                        <AutocompleteInput
                          fullWidth
                          suggestionLimit={5}
                          shouldRenderSuggestions={(value) => {
                            return value && value.trim().length > 2;
                          }}
                          optionText={"businessName"}
                        />
                      </ReferenceInput>
                    </div>
                    <div style={{ width: 340 }}>
                      <SelectInput
                        style={{ width: 310 }}
                        source="claimAction"
                        label="Claim Action"
                        choices={[
                          {
                            label: "-",
                            value: "",
                          },
                          {
                            label: "(leer)",
                            value: "none",
                          },
                          {
                            label: getReadableSubjectByClaimAction(
                              "FollowUpCreditorGeneral"
                            ),
                            value: "FollowUpCreditorGeneral",
                          },
                          {
                            label: getReadableSubjectByClaimAction(
                              "FollowUpCreditorInsolvencyFiling"
                            ),
                            value: "FollowUpCreditorInsolvencyFiling",
                          },
                          {
                            label: getReadableSubjectByClaimAction(
                              "FollowUpCreditorLawsuit"
                            ),
                            value: "FollowUpCreditorLawsuit",
                          },
                          {
                            label: getReadableSubjectByClaimAction(
                              "FollowUpCreditorPaymentOrder"
                            ),
                            value: "FollowUpCreditorPaymentOrder",
                          },
                          {
                            label: getReadableSubjectByClaimAction(
                              "FollowUpDebtorInsolvencyAdvice"
                            ),
                            value: "FollowUpDebtorInsolvencyAdvice",
                          },
                          {
                            label: getReadableSubjectByClaimAction(
                              "FollowUpDebtorLawyer"
                            ),
                            value: "FollowUpDebtorLawyer",
                          },
                          {
                            label:
                              getReadableSubjectByClaimAction(
                                "FollowUpPartner"
                              ),
                            value: "FollowUpPartner",
                          },
                          {
                            label:
                              getReadableSubjectByClaimAction(
                                "FollowUpDeadline"
                              ),
                            value: "FollowUpDeadline",
                          },
                          {
                            label:
                              getReadableSubjectByClaimAction(
                                "FollowUpInternal"
                              ),
                            value: "FollowUpInternal",
                          },
                        ]}
                        optionText="label"
                        allowEmpty
                        optionValue="value"
                        onChange={(e) => {
                          setClaimsToShow(0); // Reset pagination on filter change
                          setClaimActionFilter(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </NonInput>
              </SimpleForm>
            </Grid>
            <Grid item xs={12} md={4} className="filter-container">
              <Tooltip title={"Reload"}>
                <RotateLeftIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                  }}
                  onClick={() => fetchData(true)}
                />
              </Tooltip>
              <Tooltip
                title={
                  claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"
                }
              >
                <SortByAlphaOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    height: 20,
                    color:
                      claimsSortOrder === "asc"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleClaimsSortOrder();
                  }}
                >
                  {claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"}
                </SortByAlphaOutlined>
              </Tooltip>
            </Grid>
          </Grid>
        </div>

        <GridHeader />

        {claims && claims.length > 0
          ? claims.map((claim: Claim) => {
              return (
                <ClaimSummary
                  claim={claim}
                  type="other"
                  key={claim.id}
                  refresh={() => {
                    fetchData(true);
                  }}
                />
              );
            })
          : "-"}

        <Button
          style={{
            marginTop: 10,
            maxWidth: 200,
            marginLeft: 0,
          }}
          onClick={() => {
            setClaimsToShow(claimsToShow + limitPerPage);
          }}
          label="Load more"
        />
      </div>
    </div>
  );
});

export default OtherToDoTab;
