import { Grid } from "@material-ui/core";
import * as React from "react";
import { memo, useCallback, useEffect, useState } from "react";
import {
  LoadingIndicator,
  Show,
  ShowProps,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import { Address } from "../api/address/Address";
import { ClaimHeaderBar } from "../Components/ClaimHeaderBar";
import { ClaimTabs } from "../Components/tabs/claimShow/ClaimTabs";
import { useClaimShowLogic } from "../context/hook/useClaimShowLogic";

export const ClaimShow = (props: ShowProps): React.ReactElement => {
  // Handle keypress for navigation
  const handleKeyPress = useCallback(
    (event) => {
      if (
        event.target === document.body &&
        (event.key === "e" || event.key === "x")
      ) {
        window.open(`/#/Claim/${props?.id}/`, "_self");
      }
    },
    [props?.id]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props} title="Claim" hasEdit={false} className="claim-show-page">
      <div style={{ width: "100%" }}>
        <ClaimShowContent />
      </div>
    </Show>
  );
};

// Cache for address country data to avoid redundant API calls
const addressCountryCache = new Map<string, string>();

// Custom hooks
export const useDebtorAddress = (
  addressId: string | undefined,
  onCountryChange?: (country: string) => void
) => {
  const dataProvider = useDataProvider();
  const [country, setCountry] = React.useState<string>();

  React.useEffect(() => {
    if (!addressId) return;

    // Check cache first to avoid unnecessary API calls
    if (addressCountryCache.has(addressId)) {
      const cachedCountry = addressCountryCache.get(addressId)!;
      setCountry(cachedCountry);
      if (onCountryChange) {
        onCountryChange(cachedCountry);
      }
      return;
    }

    let isMounted = true;
    dataProvider
      .getOne<Address>("Address", { id: addressId })
      .then(({ data }) => {
        if (isMounted && data?.country) {
          const countryValue = data.languageCountry || data.country;
          setCountry(countryValue);
          // Store in cache for future use
          addressCountryCache.set(addressId, countryValue);
          if (onCountryChange) {
            onCountryChange(countryValue);
          }
        }
      })
      .catch((error) => console.error("Error fetching address:", error));

    return () => {
      isMounted = false;
    };
  }, [addressId, dataProvider, onCountryChange]);

  return country;
};

const ClaimShowContent = memo(() => {
  const recordContext = useRecordContext();
  const claimId = recordContext?.id?.toString();
  const { aside } = useClaimShowLogic(claimId);
  const [isSidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
  };

  if (!claimId) return <LoadingIndicator />;

  return (
    <>
      <ClaimHeaderBar
        claimId={claimId}
        toggleSidebar={toggleSidebar}
        isSidebarVisible={isSidebarVisible}
      />
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={isSidebarVisible ? 9 : 12}
          style={{ backgroundColor: "rgb(249, 251, 255)" }}
        >
          <ClaimTabs claimId={claimId} />
        </Grid>
        {isSidebarVisible && (
          <Grid
            item
            xs={12}
            md={3}
            style={{ backgroundColor: "rgb(249, 251, 255)" }}
          >
            {aside}
          </Grid>
        )}
      </Grid>
    </>
  );
});
