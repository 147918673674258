import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import {
  colors,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  Button,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  SaveButton,
  SimpleForm,
  Toolbar,
  useGetOne,
  useNotify,
} from "react-admin";
import { useHistory } from "react-router-dom";
import UserEditForm from "../Components/form/UserEditForm";
import { useTitle } from "../context/hook/useTitle";

export const UserEdit = (props: EditProps): React.ReactElement => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const { data: record } = useGetOne("User", props.id);

  const isMobile = useMediaQuery("(max-width: 600px)");
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/User/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  const steps = [
    { label: "Basic Information" },
    { label: "Operational Preferences" },
    { label: "Accounting Preferences" },
    { label: "Upload Files" },
    { label: "Additional Information" },
  ];

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const notify = useNotify();

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const history = useHistory();

  useTitle(`Update User: ${record?.name}`);

  return (
    <Edit
      {...props}
      onSuccess={
        !props.options?.inline
          ? () => {
              history.go(-2);
            }
          : () => {
              notify("Success", "info", null, true);
              props.options?.onSuccess();
            }
      }
      mutationMode="pessimistic"
      className="margin-bottom-5"
    >
      <SimpleForm
        toolbar={
          <Toolbar
            className={`toolbar-extension ${
              props.options?.onlyShowEmployeePreferences ? "" : "elevated-card"
            }`}
          >
            {!props.options?.onlyShowEmployeePreferences && (
              <DeleteWithConfirmButton
                undoable={false}
                mutationMode="pessimistic"
                label="Delete"
                variant="outlined"
                style={{
                  borderColor: colors.red[400],
                  padding: "6px 16px",
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: 200,
                margin: "0 1rem",
              }}
            >
              {!props.options?.onlyShowEmployeePreferences && (
                <Button onClick={handlePrev} disabled={activeStep === 0}>
                  <span className="label" style={{ fontSize: "0.75rem" }}>
                    {"Previous"}
                  </span>
                </Button>
              )}
              {!props.options?.onlyShowEmployeePreferences && (
                <Button
                  onClick={handleNext}
                  style={{ marginLeft: "1rem" }}
                  disabled={activeStep === steps.length - 1}
                >
                  <span className="label" style={{ fontSize: "0.75rem" }}>
                    {"Next"}
                  </span>
                </Button>
              )}
            </div>

            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <>
          <Grid item xs={12}>
            <Typography
              component={"span"}
              variant="h5"
              style={{ display: "block", margin: "1rem 0" }}
            >
              Update User: {record?.name}
            </Typography>
            <Typography
              component={"span"}
              variant="caption"
              style={{ display: "block", fontSize: 16 }}
            >
              Basic Information
            </Typography>
          </Grid>
          {!props.options?.onlyShowEmployeePreferences && (
            <Stepper
              activeStep={activeStep}
              style={{ width: "95%" }}
              orientation={isMobile ? "vertical" : "horizontal"}
            >
              {steps.map(({ label }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
          <UserEditForm
            activeStep={activeStep}
            onlyShowEmployeePreferences={
              props.options?.onlyShowEmployeePreferences
            }
          />
        </>
      </SimpleForm>
    </Edit>
  );
};
