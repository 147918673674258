import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActionArea,
  Chip,
  Modal,
  Button as MuiButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
  AlternateEmail,
  AttachFile,
  CheckCircleRounded,
  KeyboardArrowDown,
  Mail,
  Phone,
  RemoveCircleRounded,
  ReplyAllOutlined,
  Sms,
  SwapVerticalCircleRounded,
  WhatsApp,
} from "@material-ui/icons";
import React, { Dispatch } from "react";
import {
  DateField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  Activity,
  generateTeaser,
  getReadableActivityType,
  getReadableResult,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { User } from "../api/user/User";
import { theme } from "../theme/theme";
import { getDebtorGreetingByLanguage } from "../util/ClaimUtils";
import { getAuthToken } from "../util/JsonFilter";
import UserPresentation from "./UserPresentation";

const ActivityTypePresentation = () => {
  const record = useRecordContext();
  if (record?.activityType?.startsWith("Outbound")) {
    return (
      <span
        title="Planned letters"
        style={{
          padding: 2,
          color: theme.palette.secondary.main,
        }}
      >
        {record?.activityType === "OutboundLetter" && (
          <Mail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundMail" && (
          <AlternateEmail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundCall" && (
          <Phone
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundSms" && (
          <Sms
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundWhatsapp" && (
          <WhatsApp
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {!record?.claimAction && (
          <span style={{ fontWeight: "bold" }}>
            {getReadableActivityType(record?.activityType)}{" "}
          </span>
        )}
      </span>
    );
  } else {
    return (
      <span
        style={{
          fontWeight: "bold",
          color:
            record?.activityType === "Response"
              ? theme.palette.error.dark
              : record?.activityType === "CreditorFeedback" ||
                record?.activityType === "CreditorDecision"
              ? theme.palette.success.dark
              : theme.palette.grey[800],
        }}
      >
        {getReadableActivityType(record?.activityType)}{" "}
      </span>
    );
  }
};

const ActivityClaimActionPresentation = () => {
  const record: Activity = useRecordContext();
  if (!!record?.claimAction) {
    return (
      <>
        <b style={{ color: theme.palette.secondary.main }}>
          {getReadableSubjectByClaimAction(record?.claimAction)}
        </b>
      </>
    );
  }

  return null;
};

const ActivityResultPresentation = (props: {
  setExternalId?: Dispatch<string>;
  setShowActivityAdd?: Dispatch<boolean>;
  activityPlaceholderText?: string;
  setActivityPlaceholderText?: Dispatch<string>;
  debtorLanguage?: string;
  debtor?: User;
}) => {
  const {
    setExternalId,
    setShowActivityAdd,
    activityPlaceholderText,
    setActivityPlaceholderText,
    debtorLanguage,
    debtor,
  } = props;
  const record: Activity = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const [showRejectModal, setShowRejectModal] = React.useState(false);

  if (!!record?.result) {
    return (
      <div style={{ marginTop: 2 }}>
        <Chip
          size="small"
          label={getReadableResult(record?.result)}
          style={{
            fontSize: 11,
            color: "#fff",
            fontWeight: "bold",
            background:
              record.result === "NotReached" || record.result === "Negative"
                ? theme.palette.error.main
                : record.result === "Positive"
                ? theme.palette.success.main
                : theme.palette.primary.main,
          }}
        />
      </div>
    );
  }

  const RejectModal = () => {
    const [selectedErrorCategory, setSelectedErrorCategory] =
      React.useState("");
    const [rejectionExplanation, setRejectionExplanation] = React.useState("");

    return (
      <Modal
        onClick={(e) => e.stopPropagation()}
        open={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        aria-labelledby="rejection-modal-title"
        aria-describedby="rejection-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
            padding: 24,
            borderRadius: 8,
          }}
        >
          <Typography
            id="rejection-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Grund wählen
          </Typography>

          <Typography variant="body2" style={{ marginBottom: 16 }}>
            Bitte wähle einen Grund für die Ablehnung
          </Typography>

          <select
            value={selectedErrorCategory}
            onChange={(e) => setSelectedErrorCategory(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "16px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">Grund wählen</option>
            <option value="AI_WRONG_ACTIVITY">Falsche Aktion</option>
            <option value="AI_WRONG_TONALITY">Falsche Tonalität</option>
            <option value="AI_WRONG_FACTS">Falsche Fakten</option>
            <option value="AI_WRONG_LANGUAGE">Falsche Sprache</option>
            <option value="AI_TOO_GENERIC">Zu Allgemein</option>
          </select>

          <TextField
            label="Erklärung (optional)"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={rejectionExplanation}
            onChange={(e) => setRejectionExplanation(e.target.value)}
            style={{ marginBottom: 16 }}
          />

          <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
            <MuiButton
              onClick={() => setShowRejectModal(false)}
              style={{ color: theme.palette.grey[800] }}
            >
              Abbrechen
            </MuiButton>
            <MuiButton
              variant="contained"
              style={{
                backgroundColor: selectedErrorCategory
                  ? theme.palette.error.main
                  : theme.palette.grey[300],
                color: "white",
              }}
              onClick={async () => {
                if (selectedErrorCategory) {
                  await dataProvider.update("Activity", {
                    id: record?.id,
                    data: {
                      isPlanned: false,
                      result: "Negative",
                      ...(!!rejectionExplanation && {
                        aiErrorCategoryText: rejectionExplanation,
                      }),
                      aiErrorCategory: selectedErrorCategory,
                    },
                    previousData: record,
                  });
                  setShowRejectModal(false);
                  refresh();
                }
              }}
              disabled={!selectedErrorCategory}
            >
              Verwerfen
            </MuiButton>
          </div>
        </Box>
      </Modal>
    );
  };

  if (
    (record?.activityType?.startsWith("Recommended") ||
      record?.activityType?.startsWith("Ai")) &&
    record?.isPlanned
  ) {
    return (
      <div style={{ marginTop: 4 }}>
        <RejectModal />
        <CardActionArea
          style={{
            display: "inline",
            padding: 5,
            width: "auto",
            textAlign: "center",
            borderRadius: 10,
            marginRight: 5,
          }}
          onClick={async (e) => {
            e.stopPropagation();
            if (window.confirm("Confirm approval?")) {
              try {
                const result = await dataProvider.create("Activity", {
                  data: {
                    activityType:
                      record?.activityType === "RecommendedOutboundMail"
                        ? "OutboundMail"
                        : "InternalFeedback",
                    claim: {
                      id: record?.claim?.id,
                    },
                    isPlanned: false,
                    activityDate: new Date(),
                    comment: record?.comment,
                    fileUrls: record?.fileUrls,
                  },
                });
                if (result?.data?.id) {
                  await dataProvider.update("Activity", {
                    id: record?.id,
                    data: { isPlanned: false, result: "Positive" },
                    previousData: record,
                  });
                }
              } catch (e) {
                notify(
                  "Error during automatic handling. Please perform the action manually.",
                  "error",
                  null,
                  false
                );
              }
              refresh();
            }
          }}
        >
          <Chip
            size="medium"
            label={
              <>
                <CheckCircleRounded
                  fontSize={"small"}
                  style={{
                    width: 12,
                    height: 12,
                    color: "#fff",
                    top: 2,
                    left: -4,
                    position: "relative",
                  }}
                />
                {"Senden"}
              </>
            }
            style={{
              fontSize: 11,
              paddingLeft: 12,
              paddingRight: 12,
              paddingBottom: 6,
              paddingTop: 6,
              color: "#fff",
              fontWeight: "bold",
              background: theme.palette.success.main,
              cursor: "pointer",
            }}
          />
        </CardActionArea>
        <CardActionArea
          style={{
            marginTop: 2,
            display: "inline",
            padding: 5,
            width: "auto",
            textAlign: "center",
            borderRadius: 10,
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!showRejectModal) {
              setShowRejectModal(true);
            }
          }}
        >
          <Chip
            size="medium"
            label={
              <>
                <RemoveCircleRounded
                  fontSize={"small"}
                  style={{
                    width: 12,
                    height: 12,
                    color: "#fff",
                    top: 2,
                    left: -4,
                    position: "relative",
                  }}
                />
                <span>{"Verwerfen"}</span>
              </>
            }
            style={{
              fontSize: 11,
              paddingLeft: 12,
              paddingRight: 12,
              paddingBottom: 6,
              paddingTop: 6,
              color: "#fff",
              fontWeight: "bold",
              background: theme.palette.error.main,
              cursor: "pointer",
            }}
          />
        </CardActionArea>
        <CardActionArea
          style={{
            marginTop: 2,
            display: "block",
            padding: 5,
            width: "auto",
            textAlign: "center",
            borderRadius: 10,
          }}
        >
          <Chip
            size="medium"
            label={
              <>
                <SwapVerticalCircleRounded
                  fontSize={"small"}
                  style={{
                    width: 12,
                    height: 12,
                    color: "#fff",
                    top: 2,
                    left: -4,
                    position: "relative",
                  }}
                />
                {"Bearbeiten"}
              </>
            }
            style={{
              fontSize: 11,
              paddingLeft: 12,
              paddingRight: 12,
              paddingBottom: 6,
              paddingTop: 6,
              color: "#fff",
              fontWeight: "bold",
              background: theme.palette.info.main,
              cursor: "pointer",
            }}
          />
        </CardActionArea>
      </div>
    );
  }

  if (record?.activityType === "Response" && record?.externalId) {
    return (
      <div style={{ marginTop: 4 }}>
        <CardActionArea
          style={{
            display: "inline",
            padding: 5,
            width: "auto",
            textAlign: "center",
            borderRadius: 10,
            marginRight: 5,
          }}
          onClick={async (e) => {
            e.stopPropagation();
            if (!activityPlaceholderText && debtor) {
              setActivityPlaceholderText(
                getDebtorGreetingByLanguage(
                  debtor,
                  debtorLanguage as EnumAddressCountry
                )
              );
            }

            setExternalId(record?.externalId);
            setShowActivityAdd(true);
          }}
        >
          <Chip
            size="medium"
            label={
              <>
                <ReplyAllOutlined
                  fontSize={"small"}
                  style={{
                    width: 12,
                    height: 12,
                    color: "#fff",
                    top: 2,
                    left: -4,
                    position: "relative",
                  }}
                />
                {"Antworten"}
              </>
            }
            style={{
              fontSize: 11,
              paddingLeft: 12,
              paddingRight: 12,
              paddingBottom: 6,
              paddingTop: 6,
              color: "#fff",
              fontWeight: "bold",
              background: theme.palette.secondary.main,
              cursor: "pointer",
              display: "block",
            }}
          />
        </CardActionArea>
      </div>
    );
  }

  return null;
};

// Regex to match the @[string](string) format
export const commentWithMentions = /@\[([^\]]+)]\([^)]*\)/g;

// Helper function to sanitize HTML while allowing safe tags
export const sanitizeString = (input: string) => {
  if (!input) return input;

  // First handle mentions
  const mentionRegex = /@\[([^\]]+)]\([^)]*\)/g;
  let processed = input.replace(mentionRegex, "<b class='mention'>@$1</b>");

  // Remove all attributes from HTML tags
  processed = processed.replace(/<([a-z][a-z0-9]*)[^>]*>/gi, "<$1>");

  // Define allowed tags
  const allowedTags = [
    "b",
    "i",
    "u",
    "em",
    "strong",
    "div",
    "span",
    "font",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "br",
    "ul",
    "ol",
    "li",
    "blockquote",
    "pre",
    "code",
    "a",
  ];

  // Convert all HTML tags to lowercase for consistency
  processed = processed.replace(
    /<\/?([a-zA-Z0-9]+)((\s+.*?)?>)/g,
    (match, _tag) => match.toLowerCase()
  );

  // Escape all HTML tags
  processed = processed.replace(/</g, "&lt;").replace(/>/g, "&gt;");

  // Restore allowed tags
  allowedTags.forEach((tag) => {
    const openRegex = new RegExp(`&lt;${tag}&gt;`, "g");
    const closeRegex = new RegExp(`&lt;/${tag}&gt;`, "g");
    processed = processed
      .replace(openRegex, `<${tag}>`)
      .replace(closeRegex, `</${tag}>`);
  });

  return processed;
};

export const ActivityComment = (props: any) => {
  const record: Activity = useRecordContext();
  const [showDetails, setShowDetails] = React.useState<boolean>(false);

  if (!record?.comment && !record?.fileUrl) {
    return null;
  }

  // Replace the matched string with bold HTML tags
  const comment = sanitizeString(
    (record.comment || "")?.replace(
      commentWithMentions,
      "<b class='mention'>@$1</b>"
    )
  );
  const { teaser, fullComment } = generateTeaser(comment);

  if (
    ![
      "RecommendedOutboundMail",
      "RecommendedInternalFeedback",
      "AiCreditorLetterSummary",
      "AiProposeLetterResponseToDebtorMail",
      "AiProposePaymentPlan",
    ].includes(record.activityType) &&
    fullComment !== teaser &&
    fullComment &&
    !props.fullText
  ) {
    return (
      <Accordion
        variant="outlined"
        square
        style={{
          border: 0,
          background: "transparent",
          padding: 0,
          margin: 0,
        }}
        expanded={showDetails}
        onClick={(e) => {
          if (!showDetails) {
            e.stopPropagation();
          }
        }}
        onChange={(e) => {
          setShowDetails(!showDetails);
        }}
      >
        <AccordionSummary
          style={{
            padding: 0,
            margin: 0,
            userSelect: "text",
            wordBreak: "break-word",
            minWidth: 350,
            width: "100%",
            display: "inline-block",
            whiteSpace: "pre-line",
          }}
          onClick={(e) => {
            if (!showDetails) {
              e.stopPropagation();
            }
          }}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography component={"span"} variant="body2">
            <span dangerouslySetInnerHTML={{ __html: teaser }} />
            <br />
            {!showDetails && (
              <>
                <Typography
                  component={"span"}
                  variant="button"
                  color="secondary"
                >
                  Mehr anzeigen
                </Typography>
                <KeyboardArrowDown
                  style={{
                    float: "left",
                    color: theme.palette.secondary.main,
                  }}
                />
                {renderFileIndicator(record)}
              </>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            wordBreak: "break-word",
            minWidth: 350,
            width: "100%",
            display: "inline-block",
            whiteSpace: "pre-line",
            padding: 0,
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: fullComment }} />
          {renderFileIndicator(record)}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div
      style={{
        wordBreak: "break-word",
        minWidth: 350,
        width: "100%",
        display: "inline-block",
        whiteSpace: "pre-line",
        position: "relative",
      }}
    >
      <span
        className="comment-innerhtml"
        dangerouslySetInnerHTML={{ __html: comment }}
      />
      {renderFileIndicator(record)}
    </div>
  );
};

const renderFileIndicator = (record: Activity) => {
  if (record && (record.fileUrl || record.fileUrls)) {
    const fileNames = [];
    if (record.fileUrl) {
      fileNames.push(record.fileUrl.split("/").pop());
    }
    if (record.fileUrls) {
      try {
        if (Array.isArray(record.fileUrls) && record.fileUrls.length) {
          for (const parsedFileUrl of record.fileUrls) {
            if (!!parsedFileUrl) {
              fileNames.push(parsedFileUrl.toString().split("/").pop());
            }
          }
        } else if (typeof record.fileUrls === "string") {
          const parsedFileUrls = JSON.parse(record.fileUrls.toString());
          for (const parsedFileUrl of parsedFileUrls) {
            if (!!parsedFileUrl) {
              fileNames.push(parsedFileUrl.split("/").pop());
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          paddingTop: "1rem",
          maxWidth: 300,
          overflowY: "scroll",
        }}
      >
        {fileNames.map((fileName: string, index: number) => {
          return (
            <Tooltip arrow title={fileName} key={fileName}>
              <span
                onClick={() => {
                  handleFileClick(record.claim.reference, record.fileUrl);
                }}
                style={{
                  cursor: "pointer",
                  margin: `0 ${index === 0 ? "1rem 0 0" : "1rem"}`,
                  padding: 5,
                  borderRadius: 8,
                  background: theme.palette.grey[200],
                  color: theme.palette.secondary.dark,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <AttachFile
                  style={{
                    height: 18,
                    width: 18,
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    borderRadius: 50,
                    right: -5,
                    width: 12,
                    height: 12,
                    top: -5,
                    background: theme.palette.secondary.dark,
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 8,
                  }}
                >
                  {index + 1}
                </span>
              </span>
            </Tooltip>
          );
        })}
      </div>
    );
  }
  return null;
};

function handleFileClick(claimReference: string, url: string): void {
  const authHeader = {
    headers: {
      Authorization: getAuthToken(),
    },
  };
  const cleanClaimReference = claimReference?.replaceAll("/", "-");
  let urlToFetch = url.split(cleanClaimReference + "/").pop();
  urlToFetch = encodeURIComponent(urlToFetch || "");

  if (urlToFetch) {
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/" +
        cleanClaimReference +
        "/file/" +
        urlToFetch,
      authHeader
    ).then((response) => {
      if (response.ok) {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        response.blob().then((blobby) => {
          const objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = claimReference + "-" + urlToFetch;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        });
      }
    });
  }
}

const ActivityEventItemView = (props: {
  setExternalId?: Dispatch<string>;
  setShowActivityAdd?: Dispatch<boolean>;
  activityPlaceholderText?: string;
  setActivityPlaceholderText?: Dispatch<string>;
  debtorLanguage?: string;
  debtor?: User;
}) => {
  const {
    setExternalId,
    setShowActivityAdd,
    activityPlaceholderText,
    setActivityPlaceholderText,
    debtorLanguage,
    debtor,
  } = props;

  return (
    <ListItem style={{ padding: 2 }}>
      <ListItemAvatar>
        <UserPresentation />
      </ListItemAvatar>
      <ListItemText
        primary={
          <div
            style={{
              minWidth: 200,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <ActivityTypePresentation />
            <ActivityClaimActionPresentation />
          </div>
        }
        secondary={
          <>
            <DateField
              showTime
              source="activityDate"
              label="Activity Date"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Europe/Berlin",
              }}
              variant="body2"
            />
            <ActivityResultPresentation
              setExternalId={setExternalId}
              setShowActivityAdd={setShowActivityAdd}
              activityPlaceholderText={activityPlaceholderText}
              setActivityPlaceholderText={setActivityPlaceholderText}
              debtorLanguage={debtorLanguage}
              debtor={debtor}
            />
          </>
        }
      />
    </ListItem>
  );
};

export default ActivityEventItemView;
