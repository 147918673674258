import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  ArrowDownwardOutlined,
  CloseRounded,
  FileCopyOutlined,
  FolderOpenOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import { format } from "date-fns";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import React, { useEffect, useState } from "react";
import {
  Button,
  DataProvider,
  DataProviderProxy,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { defaultStyles, FileIcon } from "react-file-icon";
import { Document, Page, pdfjs } from "react-pdf";
import { theme } from "../../theme/theme";
import { DATE_REGEX } from "../../util/ClaimUtils";
import { parseDate } from "../../util/DateUtils";
import { getAuthToken } from "../../util/JsonFilter";

// Use packaged worker instead of CDN to avoid CORS and availability issues
pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const PreviewModal = ({ open, onClose, fileUrl, fileType }) => {
  const [, setNumPages] = React.useState(null);
  const [pageNumber] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
    setError(null);
  };

  const onDocumentLoadError = (error) => {
    console.error("Error loading document:", error);
    setError("Failed to load preview. Try downloading the file instead.");
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="body2" align="center">
          File Preview
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {error && (
          <Typography color="error" align="center" style={{ padding: "20px" }}>
            {error}
          </Typography>
        )}

        {fileType === "pdf" && !error && (
          <Document
            file={fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            loading={<CircularProgress />}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        )}
        {fileType === "image" && !error && (
          <img
            src={fileUrl}
            alt="Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "70vh",
              objectFit: "contain",
            }}
            onLoad={() => setLoading(false)}
            onError={() => {
              setError(
                "Failed to load image preview. Try downloading the file instead."
              );
              setLoading(false);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

interface UserFileUploaderProps {
  record: any;
}

const UserFileUploader = ({ record }: UserFileUploaderProps) => {
  const [previewFile, setPreviewFile] = useState<{
    url: string;
    blob: string;
  } | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [internalFiles, setInternalFiles] = useState<string[]>([]);
  const [externalFiles, setExternalFiles] = useState<string[]>([]);
  const [allFiles, setAllFiles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider: DataProviderProxy<DataProvider> = useDataProvider();

  useEffect(() => {
    if (record?.fileUrls) {
      try {
        const urls = JSON.parse(record.fileUrls);
        const matchingUrls = [];
        const nonMatchingUrls = [];

        const conditions = (url: string) =>
          !url.toLowerCase().includes("dunning") &&
          !url.toLowerCase().includes("courtprocessintro") &&
          !url.toLowerCase().includes("monitoring") &&
          !url.toLowerCase().includes("paymentplan");

        urls.forEach((url: string) => {
          if (conditions(url)) {
            nonMatchingUrls.push(url);
          } else {
            matchingUrls.push(url);
          }
        });

        setInternalFiles(matchingUrls);
        setExternalFiles(nonMatchingUrls);
        setAllFiles(urls);
      } catch (e) {
        notify("error");
      }
    }
  }, [record?.fileUrls, notify]);

  const uploadProps = {
    name: "file",
    onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
      const authHeader = {
        headers: { Authorization: getAuthToken() },
      };

      const files = event.target.files;

      if (files && files.length > 0) {
        try {
          for (const file of Array.from(files)) {
            const formData = new FormData();
            formData.append("file", file);

            await fetch(
              `${process.env.REACT_APP_SERVER_URL}/api/users/${record?.id}/files`,
              { method: "POST", body: formData, ...authHeader }
            );

            await new Promise((resolve) => setTimeout(resolve, 250));
          }

          notify("Uploaded successfully", "success", null, false);
          refresh();
        } catch (error) {
          notify("Upload failed", "error", null, false);
        }
      }
    },
  };

  function handleFileClick(
    url: string,
    saveFile = false,
    setIsLoading: (loading: boolean) => void
  ): void {
    const authHeader = { headers: { Authorization: getAuthToken() } };
    setIsLoading(true);
    const userId = record?.id;
    let urlToFetch = encodeURIComponent(url.split(`${userId}/`).pop() || "");

    if (urlToFetch) {
      fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}/files/${urlToFetch}`,
        authHeader
      ).then((response) => {
        if (response.ok) {
          const contentType = response.headers.get("Content-Type");
          let mimeType = contentType || "application/octet-stream";
          let fileTitle = url.split("/").pop() || url;

          const splitFileTitle = fileTitle.split(".");
          const fileExtension = splitFileTitle.pop();

          if (fileExtension === "pdf") mimeType = "application/pdf";

          fileTitle = splitFileTitle.shift();
          const dateMatch = fileTitle?.match(DATE_REGEX);
          if (dateMatch) {
            fileTitle = fileTitle
              .replace(DATE_REGEX, "")
              .replace(/^-/, "")
              .replace("--", "-")
              .replace(/^-/, "")
              .replace("--", "-")
              .replace(/^[\w]{2}-/, "")
              .replace(/-[\w]{2}$/, "")
              .trim()
              .replace(/ -$/, "")
              .replace(/-$/, "");
          }

          response.blob().then((blobby) => {
            const objectUrl = window.URL.createObjectURL(
              new Blob([blobby], { type: mimeType })
            );

            const anchor = document.createElement("a");
            document.body.appendChild(anchor);
            anchor.href = objectUrl;
            anchor.download = fileTitle
              ? `${userId}-${fileTitle}.${fileExtension}`
              : url;
            anchor.target = "_blank";

            if (saveFile) anchor.click();
            else window.open(objectUrl, "_blank");

            document.body.removeChild(anchor);
            setTimeout(() => window.URL.revokeObjectURL(objectUrl), 2000);
            notify("File downloaded", "info", null, false);
          });
        }
        setIsLoading(false);
      });
    }
  }

  const useStyles = makeStyles((theme) => ({
    fileInput: {
      margin: "10px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px dashed #ccc",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "border-color 0.3s",
      "&:hover": { borderColor: "#999" },
    },
  }));

  const classes: ClassNameMap<"fileInput"> = useStyles();

  const renderFiles = (
    files: string[],
    allFiles: string[],
    notify: any,
    dataProvider: DataProvider,
    handleFileClick: any,
    setIsLoading: any,
    isMainFile: boolean
  ) =>
    files.map((url: string) => {
      let fileTitle = url.split("/").pop() || url;
      const splitFileTitle = fileTitle.split(".");
      let fileDate;
      const fileExtension = splitFileTitle.pop();
      fileTitle = splitFileTitle.shift();

      const dateMatch = fileTitle?.match(DATE_REGEX);
      if (dateMatch) {
        fileDate = parseDate(dateMatch[1]);
        fileTitle = fileTitle
          .replace(DATE_REGEX, "")
          .replace(/^-/, "")
          .replace("--", "-")
          .replace(/^-/, "")
          .replace("--", "-")
          .replace(/^[\w]{2}-/, "")
          .replace(/-[\w]{2}$/, "")
          .trim()
          .replace(/ -$/, "")
          .replace(/-$/, "");
      }

      const allowPreview =
        fileExtension === "pdf" || /^(jpg|jpeg|png|gif)$/i.test(fileExtension);

      return (
        <ListItem
          key={url}
          style={{ paddingBottom: 5, paddingTop: 5, width: "100%" }}
        >
          <ListItemIcon style={{ height: 30, width: 30 }}>
            <FileIcon
              labelUppercase
              color={
                isMainFile
                  ? theme.palette.grey[300]
                  : theme.palette.secondary.main
              }
              extension={fileExtension}
              {...defaultStyles[fileExtension]}
            />
          </ListItemIcon>
          <ListItemText
            id={url}
            primary={fileTitle || url}
            style={{ width: "50%", padding: 8, cursor: "pointer" }}
            className="click-indicator"
            onClick={() => handleFileClick(url, false, setIsLoading)}
          />
          <ListItemText
            style={{ marginLeft: 10, color: theme.palette.grey[500] }}
          >
            {fileDate && format(fileDate, "dd.MM.yyyy")}
          </ListItemText>
          <ListItemIcon style={{ cursor: "pointer" }} className="mobile-hide">
            <Tooltip title="Preview File">
              <div
                style={
                  allowPreview
                    ? {
                        background: theme.palette.secondary.main,
                        padding: 5,
                        color: "#fff",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }
                    : { display: "none" }
                }
              >
                <VisibilityOutlined
                  style={{ width: 20, height: 20 }}
                  onClick={async () => {
                    if (allowPreview) {
                      const authHeader = {
                        headers: { Authorization: getAuthToken() },
                      };
                      const userId = record?.id;
                      const urlToFetch = encodeURIComponent(
                        url.split(`${userId}/`).pop() || ""
                      );
                      const previewUrl = `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}/files/${urlToFetch}`;
                      try {
                        setIsLoading(true);
                        const response = await fetch(previewUrl, authHeader);
                        if (!response.ok)
                          throw new Error(
                            `Failed to fetch file: ${response.status} ${response.statusText}`
                          );

                        const contentType =
                          response.headers.get("Content-Type") ||
                          "application/octet-stream";
                        const blob = await response.blob();

                        // Create a proper typed blob with the correct content type
                        const typedBlob = new Blob([blob], {
                          type: contentType,
                        });
                        const blobUrl = window.URL.createObjectURL(typedBlob);

                        // Log success info to help debug
                        console.log("Preview file loaded successfully:", {
                          contentType,
                          size: blob.size,
                          url: previewUrl,
                          fileExtension,
                        });

                        setPreviewFile({ blob: blobUrl, url: previewUrl });
                        setShowPreview(true);
                      } catch (error) {
                        console.error(
                          "Error fetching file for preview:",
                          error
                        );
                        notify(
                          "Failed to load preview. Try downloading instead.",
                          "error"
                        );
                      } finally {
                        setIsLoading(false);
                      }
                    }
                  }}
                />
              </div>
            </Tooltip>
          </ListItemIcon>
          <ListItemIcon style={{ cursor: "pointer" }} className="mobile-hide">
            <Tooltip title="Copy path">
              <FolderOpenOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(url);
                  notify("Copied to clipboard", "info", null, false);
                }}
              />
            </Tooltip>
          </ListItemIcon>
          <ListItemIcon style={{ cursor: "pointer" }} className="mobile-hide">
            <Tooltip title="Copy file name">
              <FileCopyOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(fileTitle);
                  notify("Copied to clipboard", "info", null, false);
                }}
              />
            </Tooltip>
          </ListItemIcon>
          <ListItemSecondaryAction
            className="mobile-hide"
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm("Do you really want to remove this file?")) {
                dataProvider.update("User", {
                  id: record.id,
                  data: {
                    fileUrls: JSON.stringify(
                      allFiles.filter((parsedUrl) => parsedUrl !== url)
                    ),
                  },
                  previousData: record,
                });
                notify(`Successfully Deleted ${record.id} File`);
              }
            }}
          >
            <IconButton>
              <CloseRounded style={{ color: theme.palette.error.main }} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });

  return (
    <Grid
      container
      style={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
      className="clearListDot"
    >
      <Grid xs={12}>
        <Typography component="span" variant="h5" style={{ marginTop: 20 }}>
          Files {isLoading && <CircularProgress size={24} />}
        </Typography>
      </Grid>

      <Grid xs={12} lg={7}>
        <label htmlFor="file" className={classes.fileInput}>
          <Input
            id="file"
            name="file"
            type="file"
            style={{ width: "100%", padding: 25 }}
            inputProps={{ multiple: true }}
            {...uploadProps}
          />
        </label>
      </Grid>
      <Grid xs={12} lg={5}>
        <div className="download-btns">
          <Button
            variant="contained"
            size="medium"
            color="default"
            disabled={!allFiles || allFiles.length === 0}
            style={{ marginBottom: 10, width: "100%" }}
            startIcon={<ArrowDownwardOutlined />}
            onClick={() => {
              if (allFiles.length > 0) {
                allFiles.forEach((url) =>
                  handleFileClick(url, true, setIsLoading)
                );
              }
            }}
          >
            <span style={{ fontSize: "0.8125rem" }}>Download all</span>
          </Button>
        </div>
      </Grid>

      <Grid xs={12} style={{ marginTop: 20 }}>
        {externalFiles && externalFiles.length > 0 && (
          <>
            <Typography
              component="span"
              variant="subtitle1"
              style={{ fontWeight: 600 }}
            >
              Main Files
            </Typography>
            <List
              style={{
                width: "100%",
                backgroundColor: "background.paper",
                maxWidth: "100%",
              }}
            >
              {renderFiles(
                externalFiles,
                allFiles,
                notify,
                dataProvider,
                handleFileClick,
                setIsLoading,
                true
              )}
            </List>
          </>
        )}

        <Divider style={{ margin: "1rem 0" }} />

        {internalFiles && internalFiles.length > 0 && (
          <>
            <Typography
              component="span"
              variant="subtitle1"
              style={{ fontWeight: 600 }}
            >
              Generated Files
            </Typography>
            <List
              style={{
                width: "100%",
                backgroundColor: "background.paper",
                maxWidth: "100%",
              }}
            >
              {renderFiles(
                internalFiles,
                allFiles,
                notify,
                dataProvider,
                handleFileClick,
                setIsLoading,
                false
              )}
            </List>
          </>
        )}
      </Grid>

      {previewFile && (
        <PreviewModal
          open={showPreview}
          onClose={() => {
            setShowPreview(false);
            setPreviewFile(null);
          }}
          fileUrl={previewFile?.blob}
          fileType={
            previewFile?.url.toLowerCase().endsWith(".pdf") ? "pdf" : "image"
          }
        />
      )}
    </Grid>
  );
};

export default UserFileUploader;
