import { JsonValue } from "type-fest";
import { Activity } from "../activity/Activity";
import { Payment } from "../payment/Payment";
import { PaymentPlan } from "../paymentPlan/PaymentPlan";
import { User } from "../user/User";

export type Claim = {
  activeUsers?: Array<User>;
  assignee?: User | null;
  activities?: Array<Activity>;
  claimType?:
    | "NewspaperAds"
    | "MedicalServices"
    | "GuaranteeClaim"
    | "Loan"
    | "Service"
    | "FreightCosts"
    | "IndependentServices"
    | "CraftsmanServices"
    | "HomeCare"
    | "HotelCosts"
    | "PurchaseContract"
    | "CurrentAccountStatement"
    | "HospitalCosts"
    | "StorageCosts"
    | "LeasingContract"
    | "CourseFees"
    | "CommercialRent"
    | "CarRent"
    | "ResidentialRent"
    | "AncillaryRentCosts"
    | "OtherRent"
    | "MembershipFee"
    | "Lease"
    | "LegalFees"
    | "PensionArrears"
    | "RepairServices"
    | "ContractualDamages"
    | "AccidentDamages"
    | "AcknowledgmentOfDebt"
    | "ForwardingCosts"
    | "AmortizationInterest"
    | "Overdraft"
    | "MaintenanceArrears"
    | "OutOfCourtSettlement"
    | "InsurancePremium"
    | "UtilityServices"
    | "Goods"
    | "ContractForWork"
    | "InterestArrears"
    | "OptionalServicesHospital"
    | "KindergartenFee"
    | "TelecommunicationServices"
    | "MedicalTransportationCosts"
    | "VeterinaryServices"
    | "CateringCosts"
    | "RecourseGuarantee"
    | "HousingAllowance"
    | "PrivateCareInsurance"
    | null;
  comment: string | null;
  contacts?: Array<User>;
  createdAt: Date;
  creditor?: User;
  holder?: User | null;
  creditorFeedbackRequired: boolean | null;
  writeOffRecommendation: boolean | null;
  currency?:
    | "EUR"
    | "USD"
    | "GBP"
    | "CHF"
    | "PLN"
    | "DKK"
    | "NOK"
    | "SEK"
    | "JPY"
    | "AUD"
    | "CAD"
    | "MXN"
    | null;
  debtor?: User;
  dueDate: Date | null;
  externalId: string | null;
  fileUrls: JsonValue;
  followUpDate: Date | null;
  id: string;
  interestRate: number | null;
  autoSettlementInPercent: number | null;
  autoSettlementExpiry: Date | null;
  invoiceCreatedAt: Date | null;
  manager?: User | null;
  originalAmountDue: number | null;
  paymentPlans?: Array<PaymentPlan>;
  payments?: Array<Payment>;
  reference: string | null;
  status?:
    | "Open"
    | "Paid"
    | "Closed"
    | "ReminderLevel"
    | "EncashmentLevel"
    | "JudicialLevel"
    | "Cancelled"
    | "Paused";
  stage: "Reminder" | "Precourt" | "Court" | "Monitoring";
  taxRate: number;
  title: string | null;
  totalPaid: number | null;
  totalPending: number | null;
  updatedAt: Date;
  externalFileNumberOwnLawyer?: string | null;
  externalFileNumberOpposingLawyer?: string | null;
  externalFileNumberInsolvency?: string | null;
  externalFileNumberCourtOrder?: string | null;
  externalFileNumberCourtVerdict?: string | null;
  titleDate?: Date | null;
  customClauses: string | null;
};

export const CLAIM_TYPES = [
  { title: "Warenlieferung", key: "Goods", number: 43 },
  { title: "Dienstleistungsvertrag", key: "Service", number: 5 },
  { title: "Anzeigen in Zeitungen", key: "NewspaperAds", number: 1 },
  {
    title: "Ärztliche oder zahnärztliche Leistung",
    key: "MedicalServices",
    number: 2,
  },
  { title: "Bürgschaft", key: "GuaranteeClaim", number: 3 },
  { title: "Darlehensrückzahlung", key: "Loan", number: 4 },
  { title: "Frachtkosten", key: "FreightCosts", number: 6 },
  {
    title: "Geschäftsbesorgung durch Selbständige",
    key: "IndependentServices",
    number: 7,
  },
  { title: "Handwerkerleistung", key: "CraftsmanServices", number: 8 },
  { title: "Heimunterbringung", key: "HomeCare", number: 9 },
  { title: "Hotelkosten", key: "HotelCosts", number: 10 },
  { title: "Kaufvertrag", key: "PurchaseContract", number: 11 },
  {
    title: "Kontokorrentabrechnung",
    key: "CurrentAccountStatement",
    number: 12,
  },
  { title: "Krankenhauskosten", key: "HospitalCosts", number: 13 },
  { title: "Lagerkosten", key: "StorageCosts", number: 14 },
  { title: "Leasing/Mietkauf", key: "LeasingContract", number: 15 },
  { title: "Lehrgangs-/Unterrichtskosten", key: "CourseFees", number: 16 },
  { title: "Miete für Geschäftsraum", key: "CommercialRent", number: 17 },
  { title: "Miete für Kraftfahrzeug", key: "CarRent", number: 18 },
  { title: "Miete für Wohnraum", key: "ResidentialRent", number: 19 },
  { title: "Mietnebenkosten", key: "AncillaryRentCosts", number: 20 },
  { title: "Miete (sonstige)", key: "OtherRent", number: 21 },
  { title: "Mitgliedsbeitrag", key: "MembershipFee", number: 22 },
  { title: "Pacht", key: "Lease", number: 23 },
  {
    title: "Rechtsanwalts-/Rechtsbeistandshonorar",
    key: "LegalFees",
    number: 24,
  },
  { title: "Rentenrückstände", key: "PensionArrears", number: 25 },
  { title: "Reparaturleistung", key: "RepairServices", number: 26 },
  {
    title: "Schadenersatz aus Vertrag",
    key: "ContractualDamages",
    number: 28,
  },
  { title: "Schadenersatz aus Unfall", key: "AccidentDamages", number: 29 },
  { title: "Schuldanerkenntnis", key: "AcknowledgmentOfDebt", number: 33 },
  { title: "Speditionskosten", key: "ForwardingCosts", number: 34 },
  { title: "Tilgungs-/Zinsraten", key: "AmortizationInterest", number: 35 },
  { title: "Überziehung des Bankkontos", key: "Overdraft", number: 36 },
  { title: "Unterhaltsrückstände", key: "MaintenanceArrears", number: 38 },
  {
    title: "Vergleich, außergerichtlicher",
    key: "OutOfCourtSettlement",
    number: 39,
  },
  {
    title: "Versicherungsprämie/-beitrag",
    key: "InsurancePremium",
    number: 41,
  },
  {
    title: "Versorgungsleistung – Strom, Wasser, Gas, Wärme",
    key: "UtilityServices",
    number: 42,
  },
  {
    title: "Werkvertrag/Werklieferungsvertrag",
    key: "ContractForWork",
    number: 44,
  },
  {
    title: "Zinsrückstände/Verzugszinsen",
    key: "InterestArrears",
    number: 46,
  },
  {
    title: "Wahlleistungen bei stationärer Behandlung",
    key: "OptionalServicesHospital",
    number: 61,
  },
  { title: "Kindertagesstättenbeitrag", key: "KindergartenFee", number: 70 },
  {
    title: "Telekommunikationsleistungen",
    key: "TelecommunicationServices",
    number: 76,
  },
  {
    title: "Krankentransportkosten",
    key: "MedicalTransportationCosts",
    number: 77,
  },
  { title: "Tierärztliche Leistung", key: "VeterinaryServices", number: 78 },
  { title: "Verpflegungskosten", key: "CateringCosts", number: 79 },
  {
    title: "Rückgriff aus Bürgschaft oder Garantie",
    key: "RecourseGuarantee",
    number: 80,
  },
  {
    title: "Wohngeld/Hausgeld für Wohnungseigentümergemeinschaft",
    key: "HousingAllowance",
    number: 90,
  },
  {
    title: "Beiträge zur privaten Pflegeversicherung",
    key: "PrivateCareInsurance",
    number: 95,
  },
];
