import {
  Box,
  Drawer,
  Fab,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import Face from "@material-ui/icons/Face";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import Send from "@material-ui/icons/Send";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Button, useNotify, useRecordContext } from "react-admin";
import ReactMarkdown from "react-markdown";
import { theme } from "../theme/theme";
import { getAuthToken } from "../util/JsonFilter";

const PreRenderer = ({ children }: any) => (
  <pre
    style={{
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      margin: "10px 0",
      padding: "8px",
      background: "#f5f5f5",
      borderRadius: "4px",
      fontFamily: "monospace",
      fontSize: "0.9em",
      overflowX: "auto",
    }}
  >
    {children}
  </pre>
);

const CustomCodeRenderer = ({
  node,
  inline,
  className,
  children,
  ...props
}: any) => {
  if (inline) {
    return (
      <div
        style={{
          background: "#eee",
          padding: "1px 3px",
          borderRadius: "3px",
          fontFamily: "monospace",
          fontSize: "0.9em",
        }}
        {...props}
      >
        {children}
      </div>
    );
  }
  return (
    <code className={className} {...props}>
      {children}
    </code>
  );
};

const renderers = {
  p: ({ children }: any) => (
    <Typography variant="body2" paragraph style={{ marginBottom: "8px" }}>
      {children}
    </Typography>
  ),
  h1: ({ children }: any) => (
    <Typography
      variant="h5"
      gutterBottom
      style={{ marginTop: "16px", marginBottom: "8px", fontWeight: "bold" }}
    >
      {children}
    </Typography>
  ),
  h2: ({ children }: any) => (
    <Typography
      variant="h6"
      gutterBottom
      style={{ marginTop: "14px", marginBottom: "7px", fontWeight: "bold" }}
    >
      {children}
    </Typography>
  ),
  h3: ({ children }: any) => (
    <Typography
      variant="subtitle1"
      gutterBottom
      style={{ marginTop: "12px", marginBottom: "6px", fontWeight: "bold" }}
    >
      {children}
    </Typography>
  ),
  h4: ({ children }: any) => (
    <Typography
      variant="subtitle2"
      gutterBottom
      style={{ marginTop: "10px", marginBottom: "5px", fontWeight: "bold" }}
    >
      {children}
    </Typography>
  ),
  h5: ({ children }: any) => (
    <Typography
      variant="caption"
      display="block"
      gutterBottom
      style={{ marginTop: "8px", marginBottom: "4px", fontWeight: "bold" }}
    >
      {children}
    </Typography>
  ),
  h6: ({ children }: any) => (
    <Typography
      variant="overline"
      display="block"
      gutterBottom
      style={{ marginTop: "6px", marginBottom: "3px", fontWeight: "bold" }}
    >
      {children}
    </Typography>
  ),
  ul: ({ children }: any) => (
    <ul style={{ paddingLeft: "20px", marginBottom: "8px" }}>{children}</ul>
  ),
  ol: ({ children }: any) => (
    <ol style={{ paddingLeft: "20px", marginBottom: "8px" }}>{children}</ol>
  ),
  li: ({ children }: any) => (
    <li style={{ marginBottom: "4px" }}>
      <Typography variant="body2">{children}</Typography>
    </li>
  ),
  a: ({ href, children }: any) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: theme.palette.primary.main, textDecoration: "underline" }}
    >
      {children}
    </a>
  ),
  strong: ({ children }: any) => (
    <strong style={{ fontWeight: "bold" }}>{children}</strong>
  ),
  em: ({ children }: any) => (
    <em style={{ fontStyle: "italic" }}>{children}</em>
  ),
  pre: PreRenderer,
  code: CustomCodeRenderer, // Use the new code renderer
};

// --- End Custom Markdown Renderers ---

export const ClaimDrawer = (): React.ReactElement => {
  const record = useRecordContext();

  const [isLoading, setIsLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [showAIDrawer, setShowAIDrawer] = useState(false);
  const [thinkingDots, setThinkingDots] = useState("."); // State for animated dots
  const [chatMessages, setChatMessages] = useState<
    {
      sender: "ai" | "user";
      header?: string; // Optional for user messages
      timestamp?: string; // Optional for user messages
      content: string;
    }[]
  >([]);
  const [userInput, setUserInput] = useState("");
  const chatContainerRef = useRef<HTMLDivElement>(null); // Ref for the scrollable chat container

  // Function to handle submission via button or Enter key
  const handleSubmit = () => {
    const trimmedInput = userInput.trim();
    if (trimmedInput && !isLoading) {
      // Add user message to chat
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", content: trimmedInput },
      ]);
      handleAISolveButton("Individual", trimmedInput);
      setUserInput(""); // Clear input after sending
    }
  };

  // Function to handle Enter key press (submit) and Shift+Enter (new line)
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Changed type to HTMLDivElement
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent default Enter behavior (new line)
      handleSubmit();
    }
    // Allow Shift+Enter for new lines (default behavior)
  };

  function handleAISolveButton(
    action: "Summary" | "Individual" | "DebtorResponse" | "CreditorResponse",
    additionalContext?: string
  ): void {
    const authHeader = {
      headers: {
        Authorization: getAuthToken(),
        "Content-Type": "application/json",
      },
    };
    setIsLoading(true);
    setShowAIDrawer(true); // Ensure drawer is shown when action is triggered

    setCurrentMessage("");

    const decoder = new TextDecoder();
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/" +
        record?.id +
        "/autoSolve?action=" +
        action,
      {
        method: "POST",
        body: JSON.stringify({
          additionalContext: additionalContext,
        }),
        ...authHeader,
      }
    )
      // Retrieve its body as ReadableStream
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        if (!response.body) {
          throw new Error("Response body is null");
        }
        const reader = response.body.getReader();
        let incompleteData = ""; // Store incomplete data chunks here

        return new ReadableStream({
          start(controller) {
            return pump();

            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  handleRealTimeData(incompleteData, true);
                  controller.close();
                  return;
                }

                const readable = decoder.decode(value, { stream: true });
                let data = incompleteData + readable;
                let lastNewlineIndex = data.lastIndexOf("\n\n");

                if (lastNewlineIndex !== -1) {
                  // Process complete messages
                  let completeData = data.substring(0, lastNewlineIndex + 1);
                  handleRealTimeData(completeData);
                  // Save incomplete chunk for next round
                  incompleteData = data.substring(lastNewlineIndex + 2);
                } else {
                  // No complete messages, save all as incomplete
                  incompleteData += readable; // Accumulate incomplete data
                }

                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .catch((err) => {
        console.error("Fetch error:", err);
        setChatMessages((prev) => [
          ...prev,
          { sender: "ai", content: `Error: ${err.message}` },
        ]);
        setIsLoading(false);
      });
  }

  function handleRealTimeData(dataString: string, isDone: boolean = false) {
    let newMessage = ""; // Accumulate new messages here
    let lines = dataString.split("\n");

    for (const line of lines) {
      if (line.startsWith("data: ")) {
        let actualData = line.substring(6);
        if (actualData) {
          try {
            // Attempt to parse as JSON string first
            const parsedData = JSON.parse(actualData).toString();
            if (typeof parsedData === "string") {
              newMessage += parsedData;
            } else {
              // If parsed but not a string, ignore it as it's not part of the message content
            }
          } catch (e) {
            // If JSON parsing fails, treat as plain text
            // Keep the newline and quote replacements as they might still be needed for plain text streams
            newMessage += actualData
              .replace(/^"|"$/g, "") // Remove surrounding quotes if any
              .replace(/\\n/g, "\n")
              .replace(/\\"/g, '"'); // Replace escaped quotes with actual quotes
          }
        }
      }
    }

    // Update state with the accumulated message chunk
    if (newMessage) {
      setCurrentMessage((prevMessage) => prevMessage + newMessage);
    }

    if (isDone) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // Add the complete AI message once loading is finished
    if (!isLoading && currentMessage) {
      setChatMessages((prevMessages) => {
        const date = new Date();
        // Avoid adding duplicate final message if already present
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (
          lastMessage?.sender === "ai" &&
          lastMessage?.content === currentMessage
        ) {
          return prevMessages;
        }
        return [
          ...prevMessages,
          {
            sender: "ai",
            header: `Robert`,
            timestamp: format(date, "HH:mm"),
            content: currentMessage || "Error generating response",
          },
        ];
      });
      setCurrentMessage(""); // Clear the streaming message buffer
    }
  }, [isLoading, currentMessage]); // Rerun when isLoading changes or currentMessage updates

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    if (isLoading) {
      intervalId = setInterval(() => {
        setThinkingDots((dots) => {
          if (dots === "...") return ".";
          if (dots === "..") return "...";
          return "..";
        });
      }, 500);
    } else {
      setThinkingDots(".");
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoading]);

  // Effect to scroll to bottom when messages change
  useEffect(() => {
    // Use setTimeout to ensure scroll happens after DOM update
    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, 0);
  }, [chatMessages]); // Dependency on chatMessages

  const notify = useNotify();
  return (
    <>
      {/* Improved FAB Trigger */}
      <Fab
        color="primary" // Use theme primary color
        aria-label="ai assistant"
        onClick={() => {
          if (showAIDrawer) {
            setShowAIDrawer(false);
          } else {
            setShowAIDrawer(true);
            if (chatMessages.length === 0) {
              handleAISolveButton("Summary");
            }
          }
        }}
        style={{
          position: "fixed",
          background:
            "linear-gradient(211.49deg,#0f82e0 15.89%,#5229c5 48.97%)",
          color: "#fff",
          bottom: theme.spacing(3),
          transition: "right 0.3s ease-in-out",
          right: showAIDrawer ? 375 : theme.spacing(3),
          zIndex: 999, // Ensure it's above drawer scrim if any
        }}
      >
        {showAIDrawer ? (
          <CloseRounded />
        ) : (
          <>
            <Face />✨
          </>
        )}
      </Fab>

      <Drawer
        anchor={"right"}
        open={showAIDrawer}
        variant="persistent"
        PaperProps={{
          style: {
            background: "#fff",
            paddingTop: 40,
            height: "100%",
            boxSizing: "border-box",
            boxShadow: "0 5px 4px 0 rgba(0,0,0,.15)",
            width: 350, // Ensure width is set here too
            display: "flex", // Use flexbox for layout
            flexDirection: "column", // Stack header, content, footer
          },
        }}
        onClose={() => setShowAIDrawer(false)}
        ModalProps={{
          BackdropProps: {
            invisible: true,
          },
        }}
      >
        <Box
          // @ts-ignore
          ref={chatContainerRef}
          component="div"
          style={{
            padding: theme.spacing(2),
            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {chatMessages?.length === 0 && !isLoading && !currentMessage ? ( // Also check currentMessage
            <Box
              style={{
                alignSelf: "flex-start",
                background: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius * 2,
                padding: theme.spacing(1, 1.5),
                marginTop: theme.spacing(1),
                boxShadow: theme.shadows[1],
                display: "flex",
                alignItems: "center",
              }}
            >
              <Face
                style={{
                  background:
                    "linear-gradient(211.49deg,#0f82e0 15.89%,#5229c5 48.97%)",
                  color: "#fff",
                  height: 24,
                  width: 24,
                  borderRadius: "50%",
                  padding: theme.spacing(0.5),
                  marginRight: theme.spacing(1),
                }}
              />
              <Typography variant="body1">How can I help?</Typography>
            </Box>
          ) : null}
          {chatMessages.map((message, index) => (
            <Box
              key={index}
              style={{
                alignSelf: message.sender === "ai" ? "flex-start" : "flex-end",
                background:
                  message.sender === "ai"
                    ? theme.palette.background.paper
                    : theme.palette.grey[100],
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.palette.grey[100],
                color:
                  message.sender === "ai"
                    ? theme.palette.text.primary
                    : theme.palette.primary.contrastText,
                borderRadius: theme.shape.borderRadius * 2,
                padding: theme.spacing(1, 1.5),
                marginTop: theme.spacing(1.5),
                wordBreak: "break-word",
              }}
            >
              {message.sender === "ai" && message.header && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom={theme.spacing(0.25)}
                >
                  <Box display="flex" alignItems="center">
                    <Face
                      style={{
                        background:
                          "linear-gradient(211.49deg,#0f82e0 15.89%,#5229c5 48.97%)",
                        color: "#fff",
                        height: 16,
                        width: 16,
                        borderRadius: "50%",
                        padding: 3,
                        marginRight: theme.spacing(0.5),
                      }}
                    />
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "bold" }}
                    >
                      {message.header}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {message.timestamp && (
                      <Typography
                        variant="caption"
                        style={{
                          color:
                            message.sender === "ai"
                              ? theme.palette.text.secondary
                              : theme.palette.primary.contrastText,
                          fontSize: "0.7rem",
                          marginRight: theme.spacing(0.5),
                        }}
                      >
                        {message.timestamp}
                      </Typography>
                    )}
                    <Tooltip title="Copy message">
                      <IconButton
                        size="small"
                        style={{
                          color:
                            message.sender === "ai"
                              ? theme.palette.text.secondary
                              : theme.palette.primary.contrastText, // Adjust color
                          padding: 2, // Adjust padding
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(message.content);
                          notify("Message copied to clipboard", {
                            type: "info",
                          });
                        }}
                      >
                        <FileCopyOutlined style={{ fontSize: "0.9rem" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}
              <ReactMarkdown components={renderers}>
                {message.content
                  .replaceAll("markdown", "")
                  .replaceAll("```", "")}
              </ReactMarkdown>
            </Box>
          ))}
          {isLoading && (
            <Box
              style={{
                alignSelf: "flex-start",
                background: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius * 2,
                padding: theme.spacing(1, 1.5),
                marginTop: theme.spacing(1.5),
                boxShadow: theme.shadows[1],
                display: "flex",
                alignItems: "center",
              }}
            >
              <Face
                style={{
                  background:
                    "linear-gradient(211.49deg,#0f82e0 15.89%,#5229c5 48.97%)",
                  color: "#fff",
                  height: 16,
                  width: 16,
                  borderRadius: "50%",
                  padding: 3,
                  marginRight: theme.spacing(0.5),
                }}
              />
              <Typography variant="body2">{thinkingDots}</Typography>
            </Box>
          )}
        </Box>
        <Box
          style={{
            padding: theme.spacing(1, 2),
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            marginTop: "auto",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Ask Robert..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyDown}
            multiline
            maxRows={4}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleSubmit}
                  disabled={isLoading || !userInput.trim()}
                  size="small"
                >
                  <Send fontSize="small" />
                </IconButton>
              ),
            }}
            style={{ marginBottom: theme.spacing(1) }}
          />
          <Box display="flex" justifyContent="space-around" flexWrap="wrap">
            <Button
              onClick={() => {
                const content =
                  "Give me a summary of the debtors reasons for not paying and all of their arguments.";
                setChatMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    sender: "user",
                    content: content,
                  },
                ]);
                handleAISolveButton("Individual", content);
              }}
              disabled={isLoading}
              size="small"
              variant="outlined"
              label="Debtor reasons"
              style={{ margin: theme.spacing(0.5) }}
            />
            <Button
              onClick={() => {
                setChatMessages((prevMessages) => [
                  ...prevMessages,
                  { sender: "user", content: "Give me a summary of the claim" },
                ]);
                handleAISolveButton("Summary");
              }}
              disabled={isLoading}
              size="small"
              label="Summary"
              variant="outlined"
              style={{ margin: theme.spacing(0.5) }}
            />
            <Button
              onClick={() => {
                const content = "How would you proceed with this claim?";
                setChatMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    sender: "user",
                    content: content,
                  },
                ]);
                handleAISolveButton("Individual", content);
              }}
              disabled={isLoading}
              size="small"
              variant="outlined"
              label="Next steps"
              style={{ margin: theme.spacing(0.5) }}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
