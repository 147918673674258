import { Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import {
  ArrayInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useGetIdentity,
} from "react-admin";
import { useFormState } from "react-final-form";
import { OPS_MANAGERS } from "../../../config/AppConfig";

const OperationFlagsForm = (): JSX.Element => {
  const { identity } = useGetIdentity();
  const isOpsManager = OPS_MANAGERS.includes(identity?.username.toString());
  const { values } = useFormState();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <Typography
          component={"span"}
          variant="caption"
          style={{ display: "block" }}
        >
          Legal Preferences
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Tooltip title={"Recommended"}>
            <Chip
              label={"Yes"}
              style={{
                padding: 2,
                marginTop: 4,
                marginBottom: -6,
                background: "var(--success)",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Tooltip>
          <SelectInput
            source="courtOrder"
            label="Court Order (Mahnbescheid)"
            choices={[
              { id: "YES", name: "Yes (with minimum)" },
              { id: "ASK", name: "Ask" },
              { id: "NO", name: "No" },
            ]}
            defaultValue={"YES"}
          />
        </div>

        {values.courtOrder === "YES" && (
          <>
            <NumberInput
              step={1}
              label="Mahnbescheid Min.-Betrag B2C"
              source="courtOrderMinimumB2C"
              defaultValue={200}
            />
            <NumberInput
              step={1}
              label="Mahnbescheid Min.-Betrag B2B"
              source="courtOrderMinimumB2B"
              defaultValue={200}
            />
          </>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Tooltip title={"Recommended"}>
            <Chip
              label={"Yes"}
              style={{
                padding: 2,
                marginTop: 4,
                marginBottom: -6,
                background: "var(--success)",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Tooltip>
          <SelectInput
            source="litigation"
            label="Litigation (Streitverfahren)"
            choices={[
              { id: "YES", name: "Yes (with minimum)" },
              { id: "ASK", name: "Ask" },
              { id: "NO", name: "No" },
            ]}
            defaultValue={"YES"}
          />
        </div>

        {values.litigation === "YES" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <NumberInput
              source="litigationMinimum"
              label="Litigation Min Amount"
              defaultValue={100}
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <NumberInput
            source="insolvencyFilingsMinimum"
            label="Insolvency Min Amount"
            defaultValue={100}
          />
        </div>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          Communication Approach & Research Preferences
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Tooltip title={"Recommended Value"}>
            <Chip
              label={"Balanced"}
              style={{
                padding: 2,
                marginTop: 4,
                marginBottom: -6,
                background: "var(--success)",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Tooltip>
          <SelectInput
            source="communicationStyle"
            label="Style"
            choices={[
              { id: "reputation", name: "Reputation-driven" },
              { id: "balanced", name: "Balanced (Recommended)" },
              { id: "maximum", name: "Maximum Realization" },
            ]}
            defaultValue="balanced"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Tooltip title={"Recommended"}>
            <Chip
              label={"Yes"}
              style={{
                padding: 2,
                marginTop: 4,
                marginBottom: -6,
                background: "var(--success)",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Tooltip>
          <SelectInput
            source="research"
            label="Research"
            choices={[
              { id: "YES", name: "Yes (With minimum)" },
              { id: "ASK", name: "Ask" },
              { id: "NO", name: "No" },
            ]}
            defaultValue="YES"
          />
        </div>
        {values.research === "YES" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <NumberInput
              source="researchMinimum"
              label="Research Min Amount"
              defaultValue={100}
            />
          </div>
        )}
      </Grid>

      <Grid item xs={6} md={4}>
        <Typography component={"span"} variant="caption">
          Operational Flags
        </Typography>
        <br />
        <BooleanInput
          label="Keine Einwohnermeldeamt-Anfrage (EMA)"
          source="disableRegistrationOfficeEnquiry"
          defaultValue={false}
          style={{ display: "inline" }}
        />
        <BooleanInput
          label="Anwaltliches Mahnschreiben erlaubt"
          source="allowPartnerLawyerInteraction"
          defaultValue={false}
          style={{ display: "inline" }}
        />
        <BooleanInput
          label="Kein gerichtliches Verfahren"
          source="disableCourtOrder"
          defaultValue={false}
          style={{ display: "inline" }}
        />
        <BooleanInput
          label="Kein Streitverfahren"
          source="disableCourtLitigation"
          defaultValue={false}
          style={{ display: "inline" }}
        />
        <BooleanInput
          label="Keine Vollstreckung"
          source="disableEnforcement"
          defaultValue={false}
          style={{ display: "inline" }}
        />
        <BooleanInput
          label="Kein Monitoring"
          source="disableMonitoring"
          defaultValue={false}
          style={{ display: "inline" }}
        />
        <BooleanInput
          label="Keine Insolvenzanmeldung"
          source="disableInsolvencyFiling"
          defaultValue={false}
          style={{ display: "inline" }}
        />
        {isOpsManager && (
          <BooleanInput
            label="VIP"
            source="isVip"
            defaultValue={false}
            style={{ display: "inline" }}
          />
        )}
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography
          component={"span"}
          variant="caption"
          style={{ marginBottom: 25, display: "block" }}
        >
          Payment Plans
        </Typography>

        <NumberInput
          step={1}
          label="Min. Zahlungsplan Rate"
          source="minimumPaymentPlanRate"
          defaultValue={0}
          helperText="Recommended: 0 EUR"
        />
        <NumberInput
          step={1}
          label="Max. Zahlungsplan Monate"
          source="maximumPaymentPlanMonths"
          defaultValue={9}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          Operator Category
        </Typography>
        <TextInput
          label="Operator Category"
          multiline
          fullWidth
          source="operatorCategory"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          Custom Preferences
        </Typography>
        <ArrayInput source="specificAutoSettlementInPercent">
          <SimpleFormIterator>
            <NumberInput source="key" label="Max. Range" step={1} />
            <NumberInput source="value" label="Min. Betrag in %" step={1} />
          </SimpleFormIterator>
        </ArrayInput>
        <Typography component={"span"} variant="caption">
          Recipients
        </Typography>
        <ArrayInput
          label="Operational Recipients"
          source="operationalRecipients"
        >
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput label="Financial Recipients" source="financialRecipients">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput label="Analytical Recipients" source="analyticalRecipients">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};

export default OperationFlagsForm;
