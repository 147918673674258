import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  EditRounded,
  FileCopyOutlined,
  KeyboardArrowUp,
  RemoveCircleRounded,
  SaveOutlined,
  UndoRounded,
  Warning,
} from "@material-ui/icons";
import currency from "currency.js";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { Button, Record, useDataProvider, useNotify } from "react-admin";
import { EnumPaymentPaymentType } from "../../../api/payment/EnumPaymentPaymentType";
import { Payment } from "../../../api/payment/Payment";
import AppConfig from "../../../config/AppConfig";
import {
  EURO_CURRENCY_FORMAT,
  PaymentCreate,
  SIMPLE_EURO_CURRENCY_FORMAT,
} from "../../../payment/PaymentCreate";
import { theme } from "../../../theme/theme";
import {
  SIDE_CLAIM_POSITIONS,
  calculateTaxValue,
} from "../../../util/ClaimUtils";
import { numberFormatEUR } from "../../../util/CurrencyUtil";

// this componnent is true insanity

type BalanceCardProps = {
  payments: Payment[];
  record: Record;
  sliderFactorCreditor: number;
  sliderFactorDebtCollector: number;
  absoluteTotal: number;
  incomingPaymentsTotal: number;
  existingPaymentsTotal: number;
  writeOffsTotal: number;
  latestCreditorInterest: Payment;
  paymentDataLoaded: boolean;
  payoutsTotal: number;
  debtCollectorFeesTotal: number;
  latestDebtCollectorTax: Payment;
  debtistTaxTotal: number;
  latestDunningCostFee: Payment;
  latestDunningCostExpense: Payment;
  latestDefaultInterest: Payment;
  latestCreditorExtra: Payment;
  refresh: any;
};

const formatDateSafely = (dateString): string => {
  if (!dateString) return "";
  try {
    return format(new Date(dateString), "dd.MM.yy");
  } catch (e) {
    console.warn("Invalid date:", dateString);
    return "";
  }
};

const BalanceCard = ({
  payments,
  record,
  sliderFactorCreditor,
  sliderFactorDebtCollector,
  absoluteTotal,
  incomingPaymentsTotal,
  existingPaymentsTotal,
  writeOffsTotal,
  latestCreditorInterest,
  paymentDataLoaded,
  payoutsTotal,
  debtCollectorFeesTotal,
  latestDebtCollectorTax,
  debtistTaxTotal,
  latestDunningCostFee,
  latestDunningCostExpense,
  latestDefaultInterest,
  latestCreditorExtra,
  refresh,
}: BalanceCardProps) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const numberFormat = useMemo(
    () =>
      Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: record?.currency || "EUR",
      }),
    [record?.currency]
  );
  const [showAmountDetails, setShowAmountDetails] = useState<boolean>(false);
  const [showSideAmountDetails, setSideShowAmountDetails] =
    useState<boolean>(false);
  const [positionAmountToSave, setPositionAmountToSave] = useState("");
  const [positionIdToSave, setPositionIdToSave] = useState("");
  const [showDebtCollectorFeeAndExpenses, setShowDebtCollectorFeeAndExpenses] =
    useState<boolean>(false);

  let originalAmountDue = record?.originalAmountDue;

  const claimPositions = payments.filter(
    (payment) =>
      payment.paymentType &&
      payment.paymentType === EnumPaymentPaymentType.ClaimPosition &&
      !SIDE_CLAIM_POSITIONS.some((prefix) =>
        payment.reference?.includes(prefix)
      )
  );
  let claimPositionsTotal: number = parseFloat(
    claimPositions
      .filter((payment) => !payment.isPlanned)
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );
  const sideClaimPositions = payments.filter(
    (payment) =>
      payment.paymentType &&
      payment.paymentType === EnumPaymentPaymentType.ClaimPosition &&
      SIDE_CLAIM_POSITIONS.some((prefix) => payment.reference?.includes(prefix))
  );
  let sideClaimPositionsTotal: number = parseFloat(
    sideClaimPositions
      .filter((payment) => !payment.isPlanned)
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );
  if (!!sideClaimPositionsTotal) {
    originalAmountDue = parseFloat(
      (originalAmountDue - sideClaimPositionsTotal).toFixed(2)
    );
  }

  let currentTotal: number = parseFloat(
    (absoluteTotal + incomingPaymentsTotal)?.toFixed(2)
  );

  let potentiallyDiscountedCurrentTotal = (currentTotal = parseFloat(
    (currentTotal * ((record?.autoSettlementInPercent || 100) / 100)).toFixed(2)
  ));

  const totalPending = useMemo(
    () =>
      parseFloat(
        (
          record?.totalPending *
          ((record?.autoSettlementInPercent || 100) / 100)
        ).toFixed(2)
      ),
    [record]
  );

  const currentCreditorAmountDue: number =
    (originalAmountDue || 0) -
    Math.abs(existingPaymentsTotal) -
    Math.abs(writeOffsTotal);
  const currentCreditorAmountDueInclInterest: number =
    currentCreditorAmountDue + (latestCreditorInterest?.amount || 0);

  const isPaid: Boolean =
    paymentDataLoaded &&
    (record?.currency && record?.currency !== "EUR"
      ? record?.totalPending <= 0
      : currentTotal <= 0);

  const totalPaid = record?.totalPaid ?? 0;

  const additionalCosts = Math.max(
    0,
    record?.totalPending + totalPaid - originalAmountDue
  );

  return (
    <Card
      style={{
        marginBottom: 20,
      }}
    >
      <CardContent>
        <Typography
          component={"span"}
          variant="h6"
          style={{ marginBottom: 10, display: "block" }}
        >
          Balance
        </Typography>
        {!!originalAmountDue &&
          currentCreditorAmountDueInclInterest !== originalAmountDue && (
            <div className="flex-between">
              <Typography component={"span"} variant="body2">
                Ursprungssumme:
              </Typography>
              <Typography component={"span"} variant="body2">
                {numberFormat.format(originalAmountDue)}
              </Typography>
            </div>
          )}
        {existingPaymentsTotal !== 0 && (
          <div className="flex-between">
            <Typography
              component={"span"}
              variant="body2"
              style={{ color: "grey" }}
            >
              Gläubiger Zahlungen:
            </Typography>
            <Typography
              component={"span"}
              variant="body2"
              style={{ color: "grey" }}
            >
              {numberFormat.format(existingPaymentsTotal)}
            </Typography>
          </div>
        )}
        {writeOffsTotal !== 0 && (
          <div className="flex-between">
            <Typography
              component={"span"}
              variant="body2"
              style={{ color: "grey" }}
            >
              Abschreibungen:
            </Typography>
            <Typography
              component={"span"}
              variant="body2"
              style={{ color: "grey" }}
            >
              {numberFormat.format(writeOffsTotal)}
            </Typography>
          </div>
        )}
        {currentCreditorAmountDue !== originalAmountDue &&
          currentCreditorAmountDue !== currentCreditorAmountDueInclInterest && (
            <div className="flex-between">
              <Typography
                component={"span"}
                variant="body2"
                style={{ fontWeight: 600, color: "grey" }}
              >
                Zwischensumme:
              </Typography>
              <Typography
                component={"span"}
                variant="body2"
                style={{ fontWeight: 600, color: "grey" }}
              >
                {numberFormat.format(currentCreditorAmountDue)}
              </Typography>
            </div>
          )}
        <Accordion
          variant="outlined"
          square
          style={{
            border: 0,
            background: "transparent",
            padding: 0,
            margin: 0,
          }}
          expanded={showAmountDetails}
          onChange={() => {
            setShowAmountDetails(!showAmountDetails);
          }}
        >
          <AccordionSummary
            style={{
              padding: 0,
              paddingTop: 0,
              paddingBottom: 10,
              margin: 0,
              userSelect: "text",
              width: "100%",
            }}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <div className="flex-between">
              <Typography
                component={"span"}
                variant="body2"
                style={{
                  fontWeight: 600,
                  position: "relative",
                }}
              >
                Hauptf. gesamt:
                <Tooltip
                  title={
                    claimPositionsTotal &&
                    originalAmountDue !== claimPositionsTotal
                      ? `Summen inkorrekt: ${originalAmountDue} / ${claimPositionsTotal}`
                      : ""
                  }
                >
                  <KeyboardArrowUp
                    className={`forderung-arrow ${
                      showAmountDetails ? "-open" : ""
                    }`}
                    style={{
                      color:
                        claimPositionsTotal &&
                        originalAmountDue !== claimPositionsTotal
                          ? theme.palette.error.main
                          : theme.palette.success.main,
                    }}
                  />
                </Tooltip>
                <br />
              </Typography>
              <Typography
                component={"span"}
                variant="body2"
                style={{
                  fontWeight: 600,
                }}
              >
                {`${numberFormat.format(
                  parseFloat(
                    (
                      currentCreditorAmountDueInclInterest *
                      (sliderFactorCreditor / 100)
                    ).toFixed(2)
                  )
                )}`}
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails
            style={{
              padding: 0,
              margin: 0,
              width: "100%",
              display: "inline-block",
              wordBreak: "break-word",
              whiteSpace: "pre-line",
            }}
          >
            <Typography component={"span"} variant="body2" align="right">
              <TableContainer>
                <Table size="small" style={{ marginBottom: 8 }}>
                  <TableBody>
                    {claimPositions.length > 0 ? (
                      claimPositions.map((position) => {
                        return (
                          <TableRow
                            style={{
                              ...(position.isPlanned && {
                                textDecoration: "line-through",
                              }),
                            }}
                          >
                            <TableCell
                              component="th"
                              variant="footer"
                              size="small"
                              style={{
                                color: theme.palette.grey[600],
                                lineHeight: "18px",
                                padding: "6px 2px",
                                maxWidth: 130,
                                minWidth: 130,
                                fontSize: 13,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: 600,
                                  color: theme.palette.grey[900],
                                }}
                              >
                                {position.reference?.replace(" (initial)", "")}
                              </div>
                              <div>
                                {formatDateSafely(position.paymentDate)}
                              </div>
                              <div
                                style={{
                                  color: theme.palette.secondary.main,
                                  minWidth: 80,
                                  maxWidth: 110,
                                  fontWeight: 600,
                                  paddingRight: 3,
                                }}
                              >
                                {numberFormat.format(position.amount)}
                              </div>
                            </TableCell>

                            {position.id === positionIdToSave && (
                              <TableCell
                                padding="none"
                                align="right"
                                variant="footer"
                                size="small"
                                style={{
                                  color: theme.palette.primary.main,
                                  minWidth: 80,
                                  maxWidth: 110,
                                  paddingRight: 3,
                                }}
                              >
                                <div>
                                  <Input
                                    value={positionAmountToSave?.toString()}
                                    onChange={(e) => {
                                      const newValue =
                                        e.target.value.replaceAll(
                                          /[^\d.]/g,
                                          ""
                                        );
                                      setPositionAmountToSave(newValue);
                                    }}
                                    type="number"
                                    style={{
                                      fontSize: 15,
                                      marginTop: 2,
                                    }}
                                    onPaste={async (e) => {
                                      e.preventDefault();
                                      let pasteContent =
                                        e.clipboardData.getData("text");
                                      if (pasteContent) {
                                        const parsedInput =
                                          EURO_CURRENCY_FORMAT.test(
                                            pasteContent
                                          ) ||
                                          SIMPLE_EURO_CURRENCY_FORMAT.test(
                                            pasteContent
                                          )
                                            ? currency(
                                                pasteContent
                                                  .replace(".", "")
                                                  .replace(",", "."),
                                                {
                                                  separator: "",
                                                  decimal: ".",
                                                  symbol: "€",
                                                }
                                              ).value
                                            : currency(pasteContent, {
                                                separator: ",",
                                                decimal: ".",
                                                symbol: "$",
                                              }).value;

                                        if (!isNaN(parsedInput)) {
                                          setPositionAmountToSave(
                                            parsedInput.toString()
                                          );
                                        }
                                      }
                                    }}
                                    defaultValue={position.amount?.toString()}
                                  />
                                  <Tooltip title="Save">
                                    <Button
                                      onClick={async (e) => {
                                        e.stopPropagation();

                                        await dataProvider.update("Claim", {
                                          id: record?.id,
                                          data: {
                                            originalAmountDue: parseFloat(
                                              (
                                                originalAmountDue -
                                                position.amount +
                                                Number(positionAmountToSave)
                                              ).toFixed(2)
                                            ),
                                          },
                                          previousData: record,
                                        });
                                        await dataProvider.update("Payment", {
                                          id: position.id,
                                          data: {
                                            amount:
                                              Number(positionAmountToSave),
                                          },
                                          previousData: position,
                                        });
                                        refresh();
                                        setPositionIdToSave("");
                                      }}
                                      startIcon={<SaveOutlined />}
                                      label={"Save"}
                                      style={{
                                        backgroundColor:
                                          theme.palette.success.dark,
                                        minWidth: "100%",
                                        marginTop: 3,
                                        marginBottom: 5,
                                      }}
                                      size={"small"}
                                      variant={"contained"}
                                    />
                                  </Tooltip>
                                </div>
                              </TableCell>
                            )}
                            <TableCell
                              padding="none"
                              align="right"
                              variant="footer"
                              size="small"
                            >
                              {!position.isPlanned ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "self-end",
                                  }}
                                >
                                  {position.id !== positionIdToSave && (
                                    <EditRounded
                                      style={{
                                        height: 16,
                                        cursor: "pointer",
                                        background: theme.palette.warning.dark,
                                        color: "#fff",
                                        padding: "5px 1px",
                                        borderRadius: 5,
                                        marginBottom: 5,
                                        verticalAlign: "middle",
                                        zIndex: 3,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        setPositionAmountToSave(
                                          position.amount?.toString()
                                        );
                                        setPositionIdToSave(position.id);
                                      }}
                                    />
                                  )}
                                  {position.id !== positionIdToSave && (
                                    <Tooltip title="Remove">
                                      <RemoveCircleRounded
                                        style={{
                                          height: 16,
                                          cursor: "pointer",
                                          background: theme.palette.error.dark,
                                          color: "#fff",
                                          padding: "5px 1px",
                                          borderRadius: 5,
                                          marginLeft: -5,
                                          verticalAlign: "middle",
                                          zIndex: 3,
                                        }}
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                          if (
                                            window.confirm(
                                              "Remove this position?"
                                            )
                                          ) {
                                            await dataProvider.update(
                                              "Payment",
                                              {
                                                id: position.id,
                                                data: {
                                                  isPlanned: true,
                                                },
                                                previousData: position,
                                              }
                                            );
                                            refresh();
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              ) : (
                                position.id !== positionIdToSave && (
                                  <Tooltip title="Undo">
                                    <UndoRounded
                                      style={{
                                        height: 16,
                                        cursor: "pointer",
                                        background: theme.palette.primary.dark,
                                        color: "#fff",
                                        padding: "5px 1px",
                                        borderRadius: 5,
                                        marginLeft: -5,
                                        verticalAlign: "middle",
                                        zIndex: 3,
                                      }}
                                      onClick={async (e) => {
                                        e.stopPropagation();
                                        if (window.confirm("Undo deletion?")) {
                                          await dataProvider.update("Claim", {
                                            id: record?.id,
                                            data: {
                                              originalAmountDue: parseFloat(
                                                (
                                                  originalAmountDue +
                                                  position.amount
                                                ).toFixed(2)
                                              ),
                                              totalPending: parseFloat(
                                                (
                                                  record?.totalPending +
                                                  position.amount
                                                ).toFixed(2)
                                              ),
                                            },
                                            previousData: record,
                                          });
                                          await dataProvider.update("Payment", {
                                            id: position.id,
                                            data: {
                                              isPlanned: false,
                                            },
                                            previousData: position,
                                          });
                                          position.isPlanned = false;
                                          refresh();
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                )
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow style={{}}>
                        <TableCell
                          component="th"
                          variant="footer"
                          size="small"
                          style={{
                            color: theme.palette.grey[600],
                            lineHeight: "18px",
                          }}
                        >
                          <div
                            style={{
                              color: theme.palette.grey[900],
                            }}
                          >
                            {record?.title}
                          </div>
                          <div>
                            {formatDateSafely(record?.invoiceCreatedAt)}
                          </div>
                        </TableCell>

                        <TableCell
                          padding="none"
                          align="right"
                          variant="footer"
                          size="small"
                          style={{
                            color: theme.palette.primary.main,
                            minWidth: 80,
                            maxWidth: 110,
                            paddingRight: 3,
                          }}
                        >
                          {record?.id === positionIdToSave ? (
                            <div>
                              <Input
                                value={positionAmountToSave?.toString()}
                                onChange={(e) => {
                                  const newValue = e.target.value.replaceAll(
                                    /[^\d.]/g,
                                    ""
                                  );
                                  setPositionAmountToSave(newValue);
                                }}
                                defaultValue={record.originalAmountDue?.toString()}
                              />
                              <Button
                                onClick={async (e) => {
                                  e.stopPropagation();

                                  await dataProvider.update("Claim", {
                                    id: record?.id,
                                    data: {
                                      originalAmountDue: parseFloat(
                                        Number(positionAmountToSave).toFixed(2)
                                      ),
                                      totalPending: parseFloat(
                                        (
                                          record?.totalPending -
                                          originalAmountDue +
                                          Number(positionAmountToSave)
                                        ).toFixed(2)
                                      ),
                                    },
                                    previousData: record,
                                  });
                                  refresh();
                                  setPositionIdToSave("");
                                }}
                                startIcon={<SaveOutlined />}
                                label={"Save"}
                                style={{
                                  backgroundColor: theme.palette.success.dark,
                                  minWidth: "100%",
                                  marginTop: 3,
                                  marginBottom: 5,
                                }}
                                size={"small"}
                                variant={"contained"}
                              />
                            </div>
                          ) : (
                            numberFormat.format(record.originalAmountDue || 0)
                          )}
                        </TableCell>

                        <TableCell
                          padding="none"
                          align="right"
                          variant="footer"
                          size="small"
                        >
                          {record?.id !== positionIdToSave && (
                            <EditRounded
                              style={{
                                height: 16,
                                cursor: "pointer",
                                background: theme.palette.warning.dark,
                                color: "#fff",
                                padding: "5px 1px",
                                borderRadius: 5,
                                marginBottom: 5,
                                verticalAlign: "middle",
                                zIndex: 3,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();

                                setPositionAmountToSave(
                                  record.originalAmountDue?.toString()
                                );
                                setPositionIdToSave(record.id.toString());
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {showAmountDetails && (
                <>
                  <hr />
                  <div
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography
                      component={"span"}
                      variant="body2"
                      style={{
                        display: "block",
                        fontWeight: 600,
                        textAlign: "left",
                        marginBottom: 0,
                        marginTop: 10,
                        color: theme.palette.grey[800],
                      }}
                    >
                      New position:
                    </Typography>
                    <PaymentCreate
                      basePath="/Payment"
                      resource="Payment"
                      options={{
                        inline: true,
                        claimId: record?.id,
                        paymentType: "ClaimPosition",
                      }}
                    />
                  </div>
                </>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {!!sideClaimPositionsTotal && (
          <Accordion
            variant="outlined"
            square
            style={{
              border: 0,
              background: "transparent",
              padding: 0,
              margin: 0,
            }}
            expanded={showSideAmountDetails}
            onChange={() => {
              setSideShowAmountDetails(!showSideAmountDetails);
            }}
          >
            <AccordionSummary
              style={{
                padding: 0,
                paddingTop: 0,
                paddingBottom: 10,
                margin: 0,
                userSelect: "text",
                width: "100%",
              }}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div className="flex-between">
                <Typography
                  component={"span"}
                  variant="body2"
                  style={{
                    fontWeight: 600,
                    position: "relative",
                  }}
                >
                  Nebenf. gesamt:
                  <KeyboardArrowUp
                    className={`forderung-arrow ${
                      showSideAmountDetails ? "-open" : ""
                    }`}
                    style={{
                      color: theme.palette.success.main,
                    }}
                  />
                  <br />
                </Typography>
                <Typography
                  component={"span"}
                  variant="body2"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {`${numberFormat.format(sideClaimPositionsTotal)}`}
                </Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{
                padding: 0,
                margin: 0,
                width: "100%",
                display: "inline-block",
                wordBreak: "break-word",
                whiteSpace: "pre-line",
              }}
            >
              <Typography component={"span"} variant="body2" align="right">
                <TableContainer>
                  <Table size="small" style={{ marginBottom: 8 }}>
                    <TableBody>
                      {sideClaimPositions.length > 0 &&
                        sideClaimPositions.map((position) => {
                          return (
                            <TableRow
                              style={{
                                ...(position.isPlanned && {
                                  textDecoration: "line-through",
                                }),
                              }}
                            >
                              <TableCell
                                component="th"
                                variant="footer"
                                size="small"
                                style={{
                                  color: theme.palette.grey[600],
                                  lineHeight: "18px",
                                  padding: "6px 2px",
                                  maxWidth: 130,
                                  minWidth: 130,
                                  fontSize: 13,
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: 600,
                                    color: theme.palette.grey[900],
                                  }}
                                >
                                  {position.reference}
                                </div>
                                <div>
                                  {formatDateSafely(position.paymentDate)}
                                </div>
                                <div
                                  style={{
                                    color: theme.palette.secondary.main,
                                    minWidth: 80,
                                    maxWidth: 110,
                                    fontWeight: 600,
                                    paddingRight: 3,
                                  }}
                                >
                                  {numberFormat.format(position.amount)}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
        {latestCreditorInterest && (
          <div className="flex-between">
            <Typography component={"span"} variant="body2">
              Gläubigerzinsen:{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {numberFormat.format(
                parseFloat(
                  (
                    (latestCreditorInterest.amount * sliderFactorCreditor) /
                    100
                  ).toFixed(2)
                )
              )}
            </Typography>
          </div>
        )}
        {payoutsTotal > 0 && (
          <>
            {(!record?.currency || record?.currency === "EUR") && (
              <div className="flex-between">
                <Typography
                  component={"span"}
                  variant="body2"
                  style={{ fontWeight: 600 }}
                >
                  Hauptf. offen:{" "}
                </Typography>
                <Typography
                  component={"span"}
                  variant="body2"
                  style={{ fontWeight: 600 }}
                >
                  <Tooltip title="Copy amount">
                    <IconButton
                      size="small"
                      style={{ padding: "4px", marginLeft: "6px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(
                          numberFormat.format(
                            Math.max(
                              parseFloat(
                                (
                                  (currentCreditorAmountDueInclInterest -
                                    payoutsTotal -
                                    (latestDebtCollectorTax?.amount
                                      ? 0
                                      : debtistTaxTotal)) *
                                  (sliderFactorCreditor / 100)
                                ).toFixed(2)
                              ),
                              0
                            )
                          )
                        );
                        notify("Copied to clipboard", "info", null, false);
                      }}
                      aria-label="Copy amount"
                    >
                      <FileCopyOutlined style={{ fontSize: "0.9em" }} />{" "}
                    </IconButton>
                  </Tooltip>
                  {numberFormat.format(
                    Math.max(
                      parseFloat(
                        (
                          (currentCreditorAmountDueInclInterest -
                            payoutsTotal -
                            debtCollectorFeesTotal -
                            (debtCollectorFeesTotal > 0
                              ? calculateTaxValue(
                                  debtCollectorFeesTotal,
                                  AppConfig.taxRate["DEU"] / 100
                                )
                              : 0) -
                            (latestDebtCollectorTax?.amount
                              ? 0
                              : debtistTaxTotal)) *
                          (sliderFactorCreditor / 100)
                        ).toFixed(2)
                      ),
                      0
                    )
                  )}
                </Typography>
              </div>
            )}
            <hr />
          </>
        )}

        {latestDunningCostFee &&
        latestDunningCostFee.amount &&
        latestDunningCostExpense &&
        latestDunningCostExpense.amount ? (
          <Accordion
            variant="outlined"
            square
            style={{
              border: 0,
              background: "transparent",
              padding: 0,
              margin: 0,
            }}
            expanded={showDebtCollectorFeeAndExpenses}
            onClick={(e) => {
              if (!showDebtCollectorFeeAndExpenses) {
                e.stopPropagation();
              }
            }}
            onChange={(e) => {
              setShowDebtCollectorFeeAndExpenses(
                !showDebtCollectorFeeAndExpenses
              );
            }}
          >
            <AccordionSummary
              style={{
                padding: 0,
                margin: 0,
                userSelect: "text",
                wordBreak: "break-word",
                display: "inline-block",
                whiteSpace: "pre-line",
                width: "100%",
              }}
              onClick={(e) => {
                if (!showDebtCollectorFeeAndExpenses) {
                  e.stopPropagation();
                }
              }}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div className="flex-between">
                <Typography component={"span"} variant="body2">
                  RVG + Auslagen:{" "}
                </Typography>
                <Typography component={"span"} variant="body2">
                  {numberFormatEUR.format(
                    parseFloat(
                      (
                        (latestDunningCostFee.amount +
                          latestDunningCostExpense.amount) *
                        (sliderFactorDebtCollector / 100)
                      ).toFixed(2)
                    )
                  )}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: 0,
                margin: 0,
                width: "100%",
                display: "inline-block",
                wordBreak: "break-word",
                whiteSpace: "pre-line",
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="flex-between">
                  <Typography component={"span"} variant="body2">
                    - RVG Gebühr:{" "}
                  </Typography>
                  <Typography component={"span"} variant="body2">
                    {numberFormatEUR.format(
                      parseFloat(
                        (
                          latestDunningCostFee.amount *
                          (sliderFactorDebtCollector / 100)
                        ).toFixed(2)
                      )
                    )}
                  </Typography>
                </div>
                <div className="flex-between">
                  <Typography component={"span"} variant="body2">
                    - RVG Auslagen:{" "}
                  </Typography>
                  <Typography component={"span"} variant="body2">
                    {numberFormatEUR.format(
                      parseFloat(
                        (
                          latestDunningCostExpense.amount *
                          (sliderFactorDebtCollector / 100)
                        ).toFixed(2)
                      )
                    )}
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ) : (
          <>
            {latestDunningCostFee && (
              <div className="flex-between">
                <Typography component={"span"} variant="body2">
                  RVG Gebühr:
                </Typography>
                <Typography component={"span"} variant="body2">
                  {numberFormatEUR.format(
                    parseFloat(
                      (
                        latestDunningCostFee.amount *
                        (sliderFactorDebtCollector / 100)
                      ).toFixed(2)
                    )
                  )}
                </Typography>
              </div>
            )}
            {latestDunningCostExpense && (
              <div className="flex-between">
                <Typography component={"span"} variant="body2">
                  RVG Auslagen:{" "}
                </Typography>
                <Typography component={"span"} variant="body2">
                  {numberFormatEUR.format(
                    parseFloat(
                      (
                        latestDunningCostExpense.amount *
                        (sliderFactorDebtCollector / 100)
                      ).toFixed(2)
                    )
                  )}
                </Typography>
              </div>
            )}
          </>
        )}
        {latestDefaultInterest && (
          <div className="flex-between">
            <Typography component={"span"} variant="body2">
              Zinsen{" "}
              <span style={{ color: theme.palette.grey[500] }}>
                ab {formatDateSafely(record.dueDate)}
              </span>
              :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {numberFormatEUR.format(
                parseFloat(
                  (
                    latestDefaultInterest.amount *
                    (sliderFactorDebtCollector / 100)
                  ).toFixed(2)
                )
              )}
            </Typography>
          </div>
        )}
        {latestCreditorExtra && (
          <div className="flex-between">
            <Typography component={"span"} variant="body2">
              Mahnkosten Mandant:{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {numberFormatEUR.format(
                parseFloat(
                  (
                    latestCreditorExtra.amount *
                    (sliderFactorDebtCollector / 100)
                  ).toFixed(2)
                )
              )}
            </Typography>
          </div>
        )}

        {paymentDataLoaded &&
          sliderFactorDebtCollector === 100 &&
          record?.currency &&
          record?.currency !== "EUR" && (
            <>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <Typography component={"span"} variant="body2">
                = {numberFormat.format(Math.max(additionalCosts))}{" "}
                Nebenforderung
              </Typography>
            </>
          )}
        {latestDebtCollectorTax && (
          <div className="flex-between">
            <Typography component={"span"} variant="body2">
              Steuer, Debtist:
            </Typography>
            <Typography component={"span"} variant="body2">
              {numberFormatEUR.format(
                parseFloat(
                  (
                    latestDebtCollectorTax.amount *
                    (sliderFactorDebtCollector / 100)
                  ).toFixed(2)
                )
              )}
            </Typography>
          </div>
        )}
        {incomingPaymentsTotal !== 0 && (
          <div className="flex-between">
            <Typography
              component={"span"}
              variant="body2"
              style={{ fontWeight: 600 }}
            >
              Zahlungen:
            </Typography>

            <Typography
              component={"span"}
              variant="body2"
              style={{ color: theme.palette.success.main, fontWeight: 600 }}
            >
              {numberFormatEUR.format(incomingPaymentsTotal)}
            </Typography>
          </div>
        )}
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />

        <div className="flex-between">
          <Typography
            component={"span"}
            variant="body2"
            style={{
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Gesamt:{" "}
          </Typography>
          <Typography
            component={"span"}
            variant="body2"
            style={{
              fontWeight: 600,
              fontSize: 16,
              ...(isPaid && {
                color: theme.palette.success.main,
              }),
            }}
          >
            <Tooltip title="Copy amount">
              <IconButton
                size="small"
                style={{ padding: "4px", marginLeft: "6px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(
                    paymentDataLoaded &&
                      (record?.currency && record?.currency !== "EUR"
                        ? numberFormat.format(totalPending)
                        : numberFormat.format(
                            potentiallyDiscountedCurrentTotal
                          ))
                  );
                  notify("Copied to clipboard", "info", null, false);
                }}
                aria-label="Copy amount"
              >
                <FileCopyOutlined style={{ fontSize: "0.9em" }} />{" "}
              </IconButton>
            </Tooltip>
            {paymentDataLoaded &&
              (record?.currency && record?.currency !== "EUR"
                ? numberFormat.format(totalPending)
                : numberFormat.format(potentiallyDiscountedCurrentTotal))}
            {paymentDataLoaded &&
              potentiallyDiscountedCurrentTotal !==
                parseFloat(totalPending?.toFixed(2)) &&
              potentiallyDiscountedCurrentTotal >= 0 &&
              sliderFactorCreditor === 100 &&
              sliderFactorDebtCollector === 100 &&
              (!record?.currency || record?.currency === "EUR") && (
                <Warning
                  titleAccess="Betrag evtl. inkonsistent"
                  style={{
                    position: "relative",
                    top: 3,
                    height: 16,
                    color: theme.palette.error.main,
                  }}
                />
              )}
          </Typography>
        </div>
        {record?.autoSettlementInPercent && (
          <Tooltip
            title={
              "Betrag ohne Vergleich: " +
              (record?.currency && record?.currency !== "EUR"
                ? numberFormat.format(record?.totalPending)
                : numberFormat.format(currentTotal))
            }
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: 16,
                color: theme.palette.secondary.main,
                textAlign: "right",
              }}
            >
              {`${parseFloat(
                (100 - record?.autoSettlementInPercent).toFixed(2)
              )}% Nachlass bis ${formatDateSafely(
                record?.autoSettlementExpiry
              )}`}
            </div>
          </Tooltip>
        )}
      </CardContent>
    </Card>
  );
};

export default BalanceCard;
