import { CircularProgress, Grid, Input, Typography } from "@material-ui/core";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  Create,
  CreateProps,
  DateInput,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  Toolbar,
  useDataProvider,
  useNotify,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import { Address } from "../api/address/Address";
import {
  EnumAddressCountry,
  GERMAN_SPEAKING_COUNTRIES,
} from "../api/address/EnumAddressCountry";
import { Claim } from "../api/claim/Claim";
import { User } from "../api/user/User";
import { ClaimTitle } from "../claim/ClaimTitle";
import { UserTitle } from "../user/UserTitle";
import { getAuthToken } from "../util/JsonFilter";

const headers = {
  Authorization: getAuthToken(),
  "Content-Type": "application/json",
};

const today = new Date();

const PaymentPlanForm = (props: any) => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const dataProvider = useDataProvider();
  const { values } = useFormState();
  const [defaultHasCourtTitleNote, setDefaultHasCourtTitleNote] =
    useState(false);
  const [newTotal, setNewTotal] = useState(0);
  const [useCustomRates, setUseCustomRates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [maximumPaymentPlanMonths, setMaximumPaymentPlanMonths] =
    useState<Number>();
  const [claim, setClaim] = useState<Claim>();
  const [customRateDelta, setCustomRateDelta] = useState(0);
  const [minimumRate, setMinimumRate] = useState(0);
  const [defaultCustomPaymentRates, setDefaultCustomPaymentRates] = useState(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      if (parsedLocation.claimId) {
        const claimResponse = await dataProvider.getOne<Claim>("Claim", {
          id: parsedLocation.claimId.toString(),
        });
        if (claimResponse?.data) {
          setClaim(claimResponse?.data as Claim);
        }

        if (
          claimResponse?.data?.totalPending !== null &&
          claimResponse?.data?.totalPending > 800
        ) {
          setDefaultHasCourtTitleNote(true);
        }

        if (claimResponse?.data?.creditor) {
          const debtorResponse = await dataProvider.getOne<User>("User", {
            id: claimResponse?.data?.debtor?.id,
          });
          if (debtorResponse.data?.address?.id) {
            const debtorAddressResponse = await dataProvider.getOne<Address>(
              "Address",
              {
                id: debtorResponse.data?.address?.id,
              }
            );
            if (
              debtorAddressResponse.data?.country &&
              !GERMAN_SPEAKING_COUNTRIES.includes(
                debtorAddressResponse.data?.country as EnumAddressCountry
              )
            ) {
              setDefaultHasCourtTitleNote(true);
            }
          }
        }

        if (claimResponse?.data?.creditor) {
          const creditorResponse = await dataProvider.getOne<User>("User", {
            id: claimResponse?.data?.creditor?.id,
          });
          if (creditorResponse.data?.maximumPaymentPlanMonths) {
            setMaximumPaymentPlanMonths(
              creditorResponse.data?.maximumPaymentPlanMonths
            );
          }
        }
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedLocation.claimId]);

  useEffect(() => {
    if (newTotal > 0 && useCustomRates) {
      const defaultRate = parseFloat(
        (newTotal / values.numberOfPayments).toFixed(2)
      );
      const deltaDueToRounding = parseFloat(
        (newTotal - defaultRate * values.numberOfPayments).toFixed(2)
      );

      const arrayOfRates = Array.from(
        { length: values.numberOfPayments },
        () => defaultRate
      );

      if (deltaDueToRounding > 0 || deltaDueToRounding < 0) {
        arrayOfRates[0] = parseFloat(
          (deltaDueToRounding + defaultRate).toFixed(2)
        );
      }
      setDefaultCustomPaymentRates(arrayOfRates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTotal, useCustomRates]);

  useEffect(() => {
    if (values.numberOfPayments > 3 && !values.hasCourtTitleNote) {
      values.hasCourtTitleNote = defaultHasCourtTitleNote;
    }

    if (useCustomRates && newTotal === 0) {
      const arrayOfRates = Array.from(
        { length: values.numberOfPayments },
        () => 1
      );
      setDefaultCustomPaymentRates(arrayOfRates);
      props.setIsDisabled(false);
    } else if (useCustomRates && defaultCustomPaymentRates?.length > 0) {
      setDefaultCustomPaymentRates([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.numberOfPayments]);

  useEffect(() => {
    const newDelta = parseFloat(
      (
        newTotal -
        values.customPaymentRates?.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        )
      ).toFixed(2)
    );
    setCustomRateDelta(newDelta);
    if (
      !isNaN(newDelta) &&
      newDelta !== 0 &&
      values.customPaymentRates?.length
    ) {
      props.setIsDisabled(true);
    } else {
      props.setIsDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.customPaymentRates, newTotal]);

  useEffect(() => {
    if (
      !isNaN(customRateDelta) &&
      values.customPaymentRates &&
      values.customPaymentRates.length
    ) {
      props.setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.hasCourtTitleNote]);

  const handlePreview = useCallback(() => {
    if (values.claim?.id) {
      setIsLoading(true);
      const requestBody = {
        nextPaymentDueDate: values.nextPaymentDueDate,
        hasCourtTitleNote: values.hasCourtTitleNote || false,
        numberOfPayments: values.numberOfPayments || 1,
        frequency: values.frequency,
        claim: {
          id: values.claim?.id,
        },
      };

      fetch(process.env.REACT_APP_SERVER_URL + "/api/paymentplans/preview", {
        method: "POST",
        headers: {
          ...headers,
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setNewTotal(0);
          if (responseJson) {
            let currentTotal = responseJson.claim?.totalPending;
            if (currentTotal) {
              // creditor interest
              currentTotal =
                currentTotal -
                responseJson.previousCreditorInterest +
                responseJson.totalNewCreditorInterest;
              // interest
              currentTotal =
                currentTotal -
                responseJson.previousDefaultInterest +
                responseJson.newDefaultInterest;
              // dunning fee
              currentTotal =
                currentTotal -
                responseJson.previousDunningCostsFee +
                responseJson.totalNewDunningFee;

              setNewTotal(currentTotal);
              setIsLoading(false);
            }
          }
        });
    }
  }, [
    values?.claim?.id,
    values?.frequency,
    values?.hasCourtTitleNote,
    values?.nextPaymentDueDate,
    values?.numberOfPayments,
  ]);

  useEffect(() => {
    if (values?.claim?.id) {
      handlePreview();
    }
  }, [handlePreview, values?.claim?.id]);

  return (
    <div
      style={{ width: "100%", padding: "4rem 2rem 2rem", position: "relative" }}
    >
      <Grid container spacing={4}>
        {maximumPaymentPlanMonths !== undefined && (
          <div className="paymentPlan-widget">
            Creditor max: {maximumPaymentPlanMonths} months
          </div>
        )}

        <Grid xs={12} md={6} lg={4} item>
          <ReferenceInput
            perPage={1}
            className="full-width-input"
            filterToQuery={(searchText) => ({
              reference: { startsWith: searchText },
            })}
            isRequired
            source="claim.id"
            reference="Claim"
            disabled
            defaultValue={parsedLocation.claimId}
            label="Claim"
          >
            <AutocompleteInput suggestionLimit={1} optionText={ClaimTitle} />
          </ReferenceInput>
        </Grid>

        <Grid xs={12} md={6} lg={4} item>
          <ReferenceInput
            perPage={1}
            filterToQuery={(searchText) => ({
              name: { startsWith: searchText },
            })}
            isRequired
            source="debtor.id"
            reference="User"
            disabled
            defaultValue={parsedLocation.debtorId}
            label="Debtor"
          >
            <AutocompleteInput suggestionLimit={1} optionText={UserTitle} />
          </ReferenceInput>
        </Grid>

        <Grid xs={12} md={6} lg={4} item>
          <NumberInput
            isRequired
            step={1}
            label="Number Of Payments"
            source="numberOfPayments"
          />
        </Grid>

        <Grid xs={12} md={6} lg={4} item>
          <SelectInput
            isRequired
            source="frequency"
            label="Frequency"
            disabled
            choices={[
              { label: "Weeks", value: "Weekly" },
              { label: "Months", value: "Monthly" },
              { label: "Months (Bi-monthly)", value: "Bimonthly" },
              { label: "Quarters", value: "Quarterly" },
              { label: "Half years (Semianually)", value: "Semiannually" },
              { label: "Years", value: "Annually" },
            ]}
            defaultValue={"Monthly"}
            optionText="label"
            optionValue="value"
          />
        </Grid>

        {!!defaultCustomPaymentRates?.length && newTotal !== 0 && (
          <Grid xs={12} md={6} lg={4} item>
            <span style={{ fontWeight: "600", lineHeight: 4 }}>
              {!isNaN(customRateDelta) ? (
                <span
                  style={
                    customRateDelta !== 0
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  ({customRateDelta <= 0 ? "+" : "-"}
                  {Math.abs(customRateDelta)})
                </span>
              ) : (
                <>0</>
              )}
            </span>
          </Grid>
        )}

        <Grid xs={12} md={6} lg={4} item>
          <div className="paymentPlan-rate">
            <Typography component={"span"} variant="body2">
              Max. rate debtor can pay:
            </Typography>
            <Input
              style={{ display: "block" }}
              value={minimumRate || ""}
              type="number"
              onChange={(e) => {
                setMinimumRate(Number(e.target.value));
              }}
            />
            {!!minimumRate && newTotal && (
              <div>
                {newTotal > minimumRate
                  ? " = " +
                    Math.max(newTotal / minimumRate, 2).toFixed(0) +
                    " Rates"
                  : "No payment plan needed"}
              </div>
            )}
          </div>
        </Grid>

        <Grid xs={12} md={6} lg={4} item>
          <DateInput
            required
            label="Next Payment Due Date"
            source="nextPaymentDueDate"
            defaultValue={today}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4} item>
          <BooleanInput
            label="Has Court Title Note"
            source="hasCourtTitleNote"
            style={{ display: "inline" }}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4} item>
          <BooleanInput
            label="Send Email?"
            source="sendEmail"
            defaultValue={true}
            style={{ display: "inline" }}
          />
        </Grid>

        {newTotal !== 0 &&
          useCustomRates &&
          !!defaultCustomPaymentRates?.length && (
            <Grid xs={12} item>
              <CustomPaymentRates
                setDefaultCustomPaymentRates={setDefaultCustomPaymentRates}
                customRateDelta={customRateDelta}
                setUseCustomRates={setUseCustomRates}
                defaultCustomPaymentRates={defaultCustomPaymentRates}
              />
            </Grid>
          )}

        <div className="paymentPlan-rates">
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <CustomRateButton
              handlePreview={handlePreview}
              claim={claim}
              newTotal={newTotal}
              setNewTotal={setNewTotal}
              setDefaultCustomPaymentRates={setDefaultCustomPaymentRates}
              setUseCustomRates={setUseCustomRates}
            />
          )}
        </div>

        {newTotal !== 0 && (
          <div className="payment-plan-total">
            <span>New total:</span>
            <span>
              {Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: claim?.currency || "EUR",
              }).format(newTotal)}
            </span>
          </div>
        )}
      </Grid>
    </div>
  );
};

const CustomPaymentRates = (props: any): React.ReactElement => {
  const { values } = useFormState();
  return (
    <>
      <ArrayInput
        label="Custom Payment Rates"
        source="customPaymentRates"
        defaultValue={props.defaultCustomPaymentRates}
      >
        <SimpleFormIterator disableAdd disableRemove>
          <NumberInput required source="" />
        </SimpleFormIterator>
      </ArrayInput>
      {(values.customPaymentRates || props.defaultCustomPaymentRates) && (
        <Button
          variant="outlined"
          size="medium"
          style={{ marginTop: 20, marginBottom: 20 }}
          onClick={() => {
            values.customPaymentRates = undefined;
            props.setUseCustomRates(false);
            props.setDefaultCustomPaymentRates([]);
          }}
          label="Remove custom rates"
        />
      )}
    </>
  );
};

const CustomRateButton = (props: any): React.ReactElement => {
  const { values } = useFormState();

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        disabled={!values.numberOfPayments}
        style={{ marginTop: 20 }}
        onClick={() => {
          props.setUseCustomRates(true);
          props.handlePreview();
        }}
        label="Use custom rates"
      />
    </>
  );
};

export const PaymentPlanCreate = (props: CreateProps): React.ReactElement => {
  const history = useHistory();
  const notify = useNotify();
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <Create
      {...props}
      onSuccess={() => {
        notify("Success", "info", null, false);
        history.goBack();
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SaveButton disabled={isDisabled} label="Save" />
          </Toolbar>
        }
      >
        <Grid container spacing={2} style={{ width: "100%" }}>
          <PaymentPlanForm
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
        </Grid>
      </SimpleForm>
    </Create>
  );
};
