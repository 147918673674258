import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  ConfirmationNumber,
  LocalOfferOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Address } from "../../api/address/Address";
import {
  EnumAddressCountry,
  GERMAN_SPEAKING_COUNTRIES,
} from "../../api/address/EnumAddressCountry";
import { Claim } from "../../api/claim/Claim";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
  THIRD_PARTY_PAYMENT_TYPES,
} from "../../api/payment/EnumPaymentPaymentType";
import { Payment } from "../../api/payment/Payment";
import { SpecificSuccessFeeItem, User } from "../../api/user/User";
import { calculateTaxValue } from "../../util/ClaimUtils";
import AdditionalCostCard from "../cards/sidebarCards/AdditionalCosts";
import PaymentDataCard from "../cards/sidebarCards/PaymentDataCard";

import AppConfig from "../../config/AppConfig";
import { useCreditor } from "../../context/hook/useCreditor";
import { theme } from "../../theme/theme";
import BalanceCard from "./balance/BalanceCard";
import SidebarClaimCalculator from "./calculator/SidebarClaimCalculator";

const useStyles = makeStyles((theme) => ({
  hideOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export const ClaimShowSidebar = (props: any) => {
  const record: Claim = useRecordContext();
  const dataProvider = useDataProvider();
  const { creditor, creditorAddress } = useCreditor();
  const refresh = useRefresh();
  const classes = useStyles();

  const [debtor, setDebtor] = useState<User | null>(null);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [paymentDataLoaded, setPaymentDataLoaded] =
    React.useState<boolean>(false);

  const [sliderFactorCreditor, setSliderFactorCreditor] = useState(100);
  const [sliderFactorDebtCollector, setSliderFactorDebtCollector] =
    useState(100);
  const [showFactorCalculator, setShowFactorCalculator] = useState(false);

  // Memoize the fetchData function since it only depends on dataProvider
  const fetchData = useCallback(
    async (
      entityName: string,
      id: string,
      setter: React.Dispatch<React.SetStateAction<any>>
    ) => {
      try {
        const { data } = await dataProvider.getOne(entityName, { id });
        setter(data);
      } catch (error) {
        console.log(error);
      }
    },
    [dataProvider]
  );

  useEffect(() => {
    if (record?.debtor?.id) {
      fetchData("User", record.debtor.id, setDebtor);
    }
  }, [record?.debtor?.id, fetchData]);

  const [debtorAddress, setDebtorAddress] = useState<Address | null>(null);

  useEffect(() => {
    if (debtor?.address?.id) {
      fetchData("Address", debtor.address.id, setDebtorAddress);
    }
  }, [debtor?.address?.id, fetchData]);

  React.useEffect(() => {
    if (props.claimId) {
      dataProvider
        .getList<Payment>("Payment", {
          filter: { claim: { id: props.claimId } },
          pagination: {
            page: 1,
            perPage: 500,
          },
          sort: { field: "paymentDate", order: "DESC" },
        })
        .then(({ data }) => {
          setPayments(data as Payment[]);
          setPaymentDataLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setPaymentDataLoaded(true);
        });
    }
  }, [props.claimId, record, dataProvider, refresh]);

  // Memoize payment calculations to prevent recalculation on every render
  const {
    latestDunningCostExpense,
    latestDunningCostFee,
    latestDefaultInterest,
    latestCreditorExtra,
    latestCreditorInterest,
    latestDebtCollectorTax,
    incomingPaymentsTotal,
    writeOffsTotal,
    existingPaymentsTotal,
    existingPaymentsAfterStart,
    payoutsTotal,
    debtCollectorFeesTotal,
    latestPayout,
    paymentsAfterLastPayout,
    paymentsBeforeLastPayoutTotal,
    negativePayouts,
  } = useMemo(() => {
    const latestDunningCostExpense = payments.find(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.DunningCostExpense
    );
    const latestDunningCostFee = payments.find(
      (payment) => payment.paymentType === EnumPaymentPaymentType.DunningCostFee
    );
    const latestDefaultInterest = payments.find(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.DefaultInterest
    );
    const latestCreditorExtra = payments.find(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.ExistingCreditorExtras
    );
    const latestCreditorInterest = payments.find(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.CreditorInterest
    );
    const latestDebtCollectorTax = payments.find(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.DebtCollectorTax
    );

    const incomingPayments = payments.filter(
      (payment) =>
        payment.paymentType &&
        NEGATIVE_PAYMENT_TYPES.includes(
          payment.paymentType as EnumPaymentPaymentType
        ) &&
        payment.paymentType !== EnumPaymentPaymentType.WriteOffDiscount &&
        payment.paymentType !== EnumPaymentPaymentType.ExistingPayments &&
        !THIRD_PARTY_PAYMENT_TYPES.includes(
          payment.paymentType as EnumPaymentPaymentType
        )
    );

    const incomingPaymentsTotal = parseFloat(
      incomingPayments.reduce((a, b) => a + b.amount, 0)?.toFixed(2)
    );

    const writeOffs = payments.filter(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.WriteOffDiscount
    );
    const writeOffsTotal = parseFloat(
      writeOffs.reduce((a, b) => a + b.amount, 0)?.toFixed(2)
    );

    const existingPayments = payments.filter(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.ExistingPayments
    );
    const existingPaymentsTotal = parseFloat(
      existingPayments.reduce((a, b) => a + b.amount, 0)?.toFixed(2)
    );

    const existingPaymentsAfterStart = payments.filter(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.ExistingPayments &&
        payment.paymentDate &&
        record?.createdAt &&
        new Date(payment.paymentDate) >= new Date(record.createdAt)
    );

    const payouts = payments.filter(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.Payout &&
        payment.amount >= 0
    );
    const payoutsTotal = parseFloat(
      payouts.reduce((a, b) => a + b.amount, 0)?.toFixed(2)
    );

    const debtCollectorFees = payments.filter(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.DebtCollectorFee &&
        payment.amount >= 0
    );
    const debtCollectorFeesTotal = parseFloat(
      debtCollectorFees.reduce((a, b) => a + b.amount, 0)?.toFixed(2)
    );

    const latestPayout = payments.find(
      (payment) => payment.paymentType === EnumPaymentPaymentType.Payout
    );

    const latestActualPayout = payments.find(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.Payout &&
        payment.amount > 0
    );

    const today = Date.now();
    let latestPayoutDate = latestPayout?.paymentDate
      ? new Date(latestPayout.paymentDate).getTime()
      : today;

    const paymentsAfterLastPayout = latestActualPayout?.paymentDate
      ? payments.filter(
          (payment) =>
            (payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
              payment.paymentType === EnumPaymentPaymentType.PaymentRate) &&
            payment.paymentDate &&
            new Date(payment.paymentDate).getTime() > latestPayoutDate
        )
      : payments.filter(
          (payment) =>
            payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
            payment.paymentType === EnumPaymentPaymentType.PaymentRate
        );

    const paymentsBeforeLastPayout = payments.filter(
      (payment) =>
        (payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
          payment.paymentType === EnumPaymentPaymentType.PaymentRate) &&
        payment.paymentDate &&
        Date.parse(payment.paymentDate?.toString()) < latestPayoutDate
    );

    const paymentsBeforeLastPayoutTotal = parseFloat(
      Math.abs(
        paymentsBeforeLastPayout.reduce((a, b) => a + b.amount, 0)
      )?.toFixed(2)
    );

    const negativePayouts = payments.filter(
      (payment) =>
        payment.paymentType === EnumPaymentPaymentType.Payout &&
        payment.amount < 0
    );

    return {
      latestDunningCostExpense,
      latestDunningCostFee,
      latestDefaultInterest,
      latestCreditorExtra,
      latestCreditorInterest,
      latestDebtCollectorTax,
      incomingPaymentsTotal,
      writeOffsTotal,
      existingPaymentsTotal,
      existingPaymentsAfterStart,
      payoutsTotal,
      debtCollectorFeesTotal,
      latestPayout,
      latestActualPayout,
      paymentsAfterLastPayout,
      paymentsBeforeLastPayoutTotal,
      negativePayouts,
    };
  }, [payments, record?.createdAt]);

  // Memoize absolute total calculation
  const absoluteTotal = useMemo(() => {
    return parseFloat(
      (
        (record?.originalAmountDue || 0) * (sliderFactorCreditor / 100) +
        (latestDunningCostFee?.amount || 0) *
          (sliderFactorDebtCollector / 100) +
        (latestDunningCostExpense?.amount || 0) *
          (sliderFactorDebtCollector / 100) +
        (latestDefaultInterest?.amount || 0) *
          (sliderFactorDebtCollector / 100) +
        (latestCreditorExtra?.amount || 0) * (sliderFactorDebtCollector / 100) +
        (latestCreditorInterest?.amount || 0) * (sliderFactorCreditor / 100) +
        (latestDebtCollectorTax?.amount || 0) *
          (sliderFactorDebtCollector / 100) +
        existingPaymentsTotal +
        writeOffsTotal
      )?.toFixed(2)
    );
  }, [
    record?.originalAmountDue,
    sliderFactorCreditor,
    latestDunningCostFee?.amount,
    sliderFactorDebtCollector,
    latestDunningCostExpense?.amount,
    latestDefaultInterest?.amount,
    latestCreditorExtra?.amount,
    latestCreditorInterest?.amount,
    latestDebtCollectorTax?.amount,
    existingPaymentsTotal,
    writeOffsTotal,
  ]);

  // Update parent component with absolute total
  useEffect(() => {
    props.setAbsoluteTotal(absoluteTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setAbsoluteTotal, absoluteTotal]);

  if (!record) {
    return null;
  }

  const paymentsAfterLastPayoutTotal = parseFloat(
    Math.abs(
      paymentsAfterLastPayout.reduce(function (a, b) {
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  let currentClaimSumMinusCosts = parseFloat(
    (Math.abs(incomingPaymentsTotal) - payoutsTotal)?.toFixed(2)
  );

  let deductDunningCostExpenseFromPendingPayout = false;
  let deductDunningCostFeeFromPendingPayout = false;

  let debtistCostsTotal: number = parseFloat(
    (
      (latestCreditorExtra?.amount || 0) +
      (latestDefaultInterest?.amount || 0) +
      (latestDunningCostFee?.amount || 0) +
      (latestDunningCostExpense?.amount || 0)
    )?.toFixed(2)
  );

  let debtistTaxTotal: number = 0;

  if (debtistCostsTotal > 0 && creditorAddress?.country === "DEU") {
    debtistTaxTotal = calculateTaxValue(
      debtistCostsTotal,
      AppConfig.taxRate["DEU"] / 100
    );

    debtistCostsTotal = debtistCostsTotal + debtistTaxTotal;
  }

  currentClaimSumMinusCosts = parseFloat(
    (
      parseFloat(
        (
          (currentClaimSumMinusCosts -
            (latestCreditorExtra?.amount || 0) -
            (latestDefaultInterest?.amount || 0) -
            (latestDunningCostFee?.amount || 0) -
            (latestDunningCostExpense?.amount || 0) -
            (latestDebtCollectorTax?.amount || 0)) *
          100
        )?.toFixed(0)
      ) / 100
    )?.toFixed(2)
  );

  let toBePaidOutTotal: number =
    record?.stage !== "Reminder" ? currentClaimSumMinusCosts : 0;
  if (
    toBePaidOutTotal > 0 &&
    !latestDebtCollectorTax?.amount &&
    creditorAddress?.country === "DEU"
  ) {
    toBePaidOutTotal = parseFloat(
      (
        parseFloat(((toBePaidOutTotal - debtistTaxTotal) * 100)?.toFixed(0)) /
        100
      )?.toFixed(2)
    );
  }

  let relevantSuccessFee: number = 0;
  let successFeeTax: number = 0;
  let isAvailableForSuccessFee: boolean = false;
  let deductDefaultInterestFromPendingPayout: boolean = false;
  let deductExistingCreditorExtrasFromPendingPayout: boolean = false;
  let debtistCostsPending: number = 0;
  let debtistTaxPending: number = 0;

  if (!latestPayout || debtistCostsTotal > paymentsBeforeLastPayoutTotal) {
    // no payouts yet, always deduct
    if (!latestPayout) {
      deductExistingCreditorExtrasFromPendingPayout = true;
      deductDunningCostExpenseFromPendingPayout = true;
      deductDunningCostFeeFromPendingPayout = true;
      deductDefaultInterestFromPendingPayout = true;

      debtistCostsPending = debtistCostsTotal;
    } else {
      // fees not yet paid in total, maybe partly
      if (
        (latestDefaultInterest?.amount || 0) > paymentsBeforeLastPayoutTotal
      ) {
        deductDefaultInterestFromPendingPayout = true;
      }
      if (
        (latestDefaultInterest?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductDunningCostExpenseFromPendingPayout = true;
      }
      if (
        (latestDefaultInterest?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) +
          (latestDunningCostFee?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductDunningCostFeeFromPendingPayout = true;
      }
      if (
        (latestCreditorExtra?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) +
          (latestDunningCostFee?.amount || 0) +
          (latestCreditorExtra?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductExistingCreditorExtrasFromPendingPayout = true;
      }

      debtistCostsPending = Math.max(
        0,
        debtistCostsTotal - paymentsBeforeLastPayoutTotal
      );
      if (creditorAddress?.country === "DEU") {
        debtistTaxPending = calculateTaxValue(
          debtistCostsPending,
          AppConfig.taxRate["DEU"] / 100
        );
      }
    }
  }

  const customSuccessFee =
    Array.isArray(creditor?.specificSuccessFeeInPercent) &&
    creditor?.specificSuccessFeeInPercent?.length &&
    (
      creditor?.specificSuccessFeeInPercent as unknown as SpecificSuccessFeeItem[]
    )?.find((item) => item.key === debtorAddress?.country)?.value;

  let successFeePercentage =
    customSuccessFee ||
    (debtorAddress?.country &&
    !GERMAN_SPEAKING_COUNTRIES.includes(
      debtorAddress.country as EnumAddressCountry
    )
      ? creditor?.successFeeInPercent
      : 0) ||
    0;

  let relevantTotalForSuccessFee = Math.min(
    currentClaimSumMinusCosts,
    paymentsAfterLastPayoutTotal
  );

  if (successFeePercentage) {
    isAvailableForSuccessFee = true;
    if (paymentsAfterLastPayoutTotal > 0) {
      relevantSuccessFee = parseFloat(
        (
          relevantTotalForSuccessFee *
          parseFloat((successFeePercentage / 100).toFixed(2))
        ).toFixed(2)
      );
    } else {
      relevantSuccessFee = debtCollectorFeesTotal;
    }
  }

  if (relevantSuccessFee > 0) {
    if (creditorAddress?.country === "DEU" && relevantSuccessFee) {
      successFeeTax = calculateTaxValue(
        relevantSuccessFee,
        AppConfig.taxRate["DEU"] / 100
      );
    }
  }

  if (toBePaidOutTotal > 0) {
    toBePaidOutTotal = parseFloat(
      (
        parseFloat(
          (
            (toBePaidOutTotal -
              debtCollectorFeesTotal -
              (creditorAddress?.country === "DEU"
                ? calculateTaxValue(
                    debtCollectorFeesTotal,
                    AppConfig.taxRate["DEU"] / 100
                  )
                : 0) -
              relevantSuccessFee -
              successFeeTax) *
            100
          )?.toFixed(0)
        ) / 100
      )?.toFixed(2)
    );
  }

  return (
    <Box
      className={classes.hideOnMobile}
      style={{
        maxWidth: "91%",
        width: "100%",
        marginTop: "1rem",
        margin: "0 0 0 1.5em",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <ClaimEditActions
        setShowFactorCalculator={setShowFactorCalculator}
        showFactorCalculator={showFactorCalculator}
      />
      {showFactorCalculator && (
        <SidebarClaimCalculator
          setSliderFactorCreditor={setSliderFactorCreditor}
          setSliderFactorDebtCollector={setSliderFactorDebtCollector}
          sliderFactorCreditor={sliderFactorCreditor}
          sliderFactorDebtCollector={sliderFactorDebtCollector}
        />
      )}
      {paymentDataLoaded && (
        <BalanceCard
          payments={payments}
          record={record}
          latestCreditorInterest={latestCreditorInterest}
          sliderFactorCreditor={sliderFactorCreditor}
          sliderFactorDebtCollector={sliderFactorDebtCollector}
          absoluteTotal={absoluteTotal}
          incomingPaymentsTotal={incomingPaymentsTotal}
          existingPaymentsTotal={existingPaymentsTotal}
          writeOffsTotal={writeOffsTotal}
          paymentDataLoaded={paymentDataLoaded}
          payoutsTotal={payoutsTotal}
          debtCollectorFeesTotal={debtCollectorFeesTotal}
          latestDebtCollectorTax={latestDebtCollectorTax}
          debtistTaxTotal={debtistTaxTotal}
          latestDunningCostFee={latestDunningCostFee}
          latestDunningCostExpense={latestDunningCostExpense}
          latestDefaultInterest={latestDefaultInterest}
          latestCreditorExtra={latestCreditorExtra}
          refresh={refresh}
        />
      )}

      {creditor && (
        <PaymentDataCard
          record={record}
          payments={payments}
          paymentDataLoaded={paymentDataLoaded}
          // common props
          isAvailableForSuccessFee={isAvailableForSuccessFee}
          payoutsTotal={payoutsTotal}
          incomingPaymentsTotal={incomingPaymentsTotal}
          latestDunningCostExpense={latestDunningCostExpense}
          deductDunningCostFeeFromPendingPayout={
            deductDunningCostFeeFromPendingPayout
          }
          deductDunningCostExpenseFromPendingPayout={
            deductDunningCostExpenseFromPendingPayout
          }
          successFeePercentage={successFeePercentage}
          latestDefaultInterest={latestDefaultInterest}
          debtistCostsTotal={debtistCostsTotal}
          deductDefaultInterestFromPendingPayout={
            deductDefaultInterestFromPendingPayout
          }
          deductExistingCreditorExtrasFromPendingPayout={
            deductExistingCreditorExtrasFromPendingPayout
          }
          existingPaymentsAfterStart={existingPaymentsAfterStart}
          toBePaidOutTotal={toBePaidOutTotal}
          creditor={creditor}
          creditorAddress={creditorAddress}
          negativePayouts={negativePayouts}
          latestCreditorExtra={latestCreditorExtra}
          debtistCostsPending={debtistCostsPending}
          paymentsBeforeLastPayoutTotal={paymentsBeforeLastPayoutTotal}
          debtistTaxTotal={debtistTaxTotal}
          debtistTaxPending={debtistTaxPending}
          debtCollectorFeesTotal={debtCollectorFeesTotal}
          successFeeTax={successFeeTax}
          relevantSuccessFee={relevantSuccessFee}
        />
      )}
      <AdditionalCostCard
        record={record}
        payments={payments}
        paymentDataLoaded={paymentDataLoaded}
        // common props
        creditorAddress={creditorAddress}
        creditor={creditor}
      />
    </Box>
  );
};

const ClaimEditActions = (props: any) => {
  const record = useRecordContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [customAmount, setCustomAmount] = useState("");

  const getPaymentPageUrl = (amount?: string) => {
    const baseUrl = `${process.env.REACT_APP_DASHBOARD_PAGE_URL}/pay/${record?.id}/${record?.reference}`;
    return amount ? `${baseUrl}?customAmount=${amount}` : baseUrl;
  };

  const handleCustomAmountSubmit = () => {
    window.open(getPaymentPageUrl(customAmount), "_blank");
    setOpenDialog(false);
    setCustomAmount("");
  };

  return (
    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title="View payment page" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant="outlined"
            size="medium"
            onClick={() => setOpenDialog(true)}
            startIcon={<VisibilityOutlined />}
          />
        </Tooltip>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Payment Page Options</DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                minWidth: "300px",
                padding: "10px 0",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  window.open(getPaymentPageUrl(), "_blank");
                  setOpenDialog(false);
                }}
                style={{ color: theme.palette.common.white }}
                label={"Open Regular Payment Page"}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <TextField
                  label="Custom Amount"
                  type="number"
                  value={customAmount}
                  onChange={(e) => setCustomAmount(e.target.value)}
                  style={{ color: theme.palette.common.white }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {record.currency}
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  disabled={!customAmount}
                  onClick={handleCustomAmountSubmit}
                  label={"Open With Custom Amount"}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Tooltip title="View Tickets" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant={"outlined"}
            size={"medium"}
            onClick={() => {
              window.open(
                "https://debtistgmbh.freshdesk.com/a/search/tickets?filter[]=group_id%3A%22103000276855%2C103000415785%2C103000423843%22&sortBy=relevance&term=" +
                  record?.reference,
                "_blank"
              );
            }}
            startIcon={<ConfirmationNumber />}
            label=""
          />
        </Tooltip>

        <Tooltip title="Show Settlement Calculator" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant={"outlined"}
            size={"medium"}
            onClick={() => {
              props.setShowFactorCalculator(!props.showFactorCalculator);
            }}
            startIcon={<LocalOfferOutlined />}
            label=""
          />
        </Tooltip>
      </div>
    </div>
  );
};
