import { Grid, Tooltip, Typography } from "@material-ui/core";
import {
  AlternateEmail,
  MailOutlined,
  PhoneIphoneOutlined,
  PriorityHigh,
  SaveRounded,
  Send,
  SendRounded,
  SmsOutlined,
  VisibilityOff,
  WhatsApp,
} from "@material-ui/icons";
import { debounce } from "lodash";
import qs from "qs";
import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  DateTimeInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import { useForm, useFormState } from "react-final-form";
import { Mention, MentionsInput } from "react-mentions";
import { useHistory, useLocation } from "react-router-dom";
import {
  getReadableActivityType,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import { ADDRESS_COUNTRY_SHORT } from "../api/address/Address";
import { ClaimTitle } from "../claim/ClaimTitle";
import { NonInput } from "../Components/NonInputComponent";
import { useClaim } from "../context/hook/useClaim";
import { theme } from "../theme/theme";
import {
  ActivityResultsForDecisions,
  ActivityResultsForOutbound,
} from "../util/ClaimUtils";
import { searchMapByKey } from "../util/MapUtils";
import { getInitials } from "../util/StyleUtils";
import FileManager from "./FileManager";

let INITIAL_DATE: Date | undefined;

const MAIN_CLAIM_ACTIONS = [
  {
    label: getReadableSubjectByClaimAction("FirstDunning"),
    value: "FirstDunning",
  },
  {
    label: getReadableSubjectByClaimAction("SecondDunning"),
    value: "SecondDunning",
  },
  {
    label: getReadableSubjectByClaimAction("ThirdDunning"),
    value: "ThirdDunning",
  },
  {
    label: getReadableSubjectByClaimAction("FourthDunning"),
    value: "FourthDunning",
  },
  {
    label: getReadableSubjectByClaimAction("FifthDunning"),
    value: "FifthDunning",
  },
  {
    label: getReadableSubjectByClaimAction("SixthDunning"),
    value: "SixthDunning",
  },
  {
    label: getReadableSubjectByClaimAction("DiscountOffer"),
    value: "DiscountOffer",
  },
  {
    label: getReadableSubjectByClaimAction("FirstReminder"),
    value: "FirstReminder",
  },
  {
    label: getReadableSubjectByClaimAction("SecondReminder"),
    value: "SecondReminder",
  },
  {
    label: getReadableSubjectByClaimAction("ThirdReminder"),
    value: "ThirdReminder",
  },
  {
    label: getReadableSubjectByClaimAction("PhoneResearch"),
    value: "PhoneResearch",
  },
  {
    label: getReadableSubjectByClaimAction("EmailResearch"),
    value: "EmailResearch",
  },
  {
    label: getReadableSubjectByClaimAction("OnlineResearch"),
    value: "OnlineResearch",
  },
  {
    label: getReadableSubjectByClaimAction("PublicAuthorityRequest"),
    value: "PublicAuthorityRequest",
  },
  {
    label: getReadableSubjectByClaimAction("DefaultSummon"),
    value: "DefaultSummon",
  },
  {
    label: getReadableSubjectByClaimAction("Lawsuit"),
    value: "Lawsuit",
  },
  {
    label: getReadableSubjectByClaimAction("DebtCollectionTransfer"),
    value: "DebtCollectionTransfer",
  },
];

const OTHER_CLAIM_ACTIONS = [
  {
    label: getReadableSubjectByClaimAction("FollowUpCreditorGeneral"),
    value: "FollowUpCreditorGeneral",
  },
  {
    label: getReadableSubjectByClaimAction("FollowUpCreditorInsolvencyFiling"),
    value: "FollowUpCreditorInsolvencyFiling",
  },
  {
    label: getReadableSubjectByClaimAction("FollowUpCreditorLawsuit"),
    value: "FollowUpCreditorLawsuit",
  },
  {
    label: getReadableSubjectByClaimAction("FollowUpCreditorPaymentOrder"),
    value: "FollowUpCreditorPaymentOrder",
  },
  {
    label: getReadableSubjectByClaimAction("FollowUpDebtorInsolvencyAdvice"),
    value: "FollowUpDebtorInsolvencyAdvice",
  },
  {
    label: getReadableSubjectByClaimAction("FollowUpDebtorLawyer"),
    value: "FollowUpDebtorLawyer",
  },
  {
    label: getReadableSubjectByClaimAction("FollowUpPartner"),
    value: "FollowUpPartner",
  },
  {
    label: getReadableSubjectByClaimAction("FollowUpDeadline"),
    value: "FollowUpDeadline",
  },
  {
    label: getReadableSubjectByClaimAction("FollowUpInternal"),
    value: "FollowUpInternal",
  },
  {
    label: getReadableSubjectByClaimAction("FourthDunning"),
    value: "FourthDunning",
  },
];

const ClaimActionInput = (): React.ReactElement => {
  const { values } = useFormState();
  const claimActionInputRef = useRef<HTMLInputElement>(null); // Ref for the input element
  let choices = MAIN_CLAIM_ACTIONS;

  // Effect to focus and attempt to open the Claim Action input when activityType is "Other"
  useEffect(() => {
    if (values.activityType === "Other") {
      // Delay slightly to ensure the input is enabled and ready
      const focusTimer = setTimeout(() => {
        const inputElement = claimActionInputRef.current;
        if (inputElement) {
          inputElement.focus();
        }
      }, 150);
      // Cleanup function for the outer timer
      return () => clearTimeout(focusTimer);
    }
  }, [values.activityType]);

  if (values.activityType) {
    if (values.activityType === "OutboundWhatsapp") {
      // whatsapp: only has 2nd and 3rd option
      choices = choices.filter(
        (choice) =>
          choice.value === "SecondDunning" || choice.value === "ThirdDunning"
      );
    } else if (values.activityType.startsWith("Outbound")) {
      choices = choices.filter(
        (choice) =>
          choice.value.endsWith("Dunning") ||
          choice.value.endsWith("Reminder") ||
          choice.value === "DiscountOffer"
      );
    } else if (values.activityType === "Research") {
      choices = choices.filter(
        (choice) =>
          choice.value.endsWith("Research") ||
          choice.value === "PublicAuthorityRequest"
      );
    } else if (
      values.activityType === "JudicialActivity" ||
      values.activityType === "InternalDecision"
    ) {
      choices = choices.filter(
        (choice) =>
          choice.value === "DefaultSummon" || choice.value === "Lawsuit"
      );
    } else if (values.activityType === "InsolvencyActivity") {
      choices = choices.filter(
        (choice) => choice.value === "" // todo
      );
    } else if (values.activityType === "CreditorDecision") {
      choices = choices.filter((choice) =>
        [
          "DebtCollectionTransfer",
          "PublicAuthorityRequest",
          "DefaultSummon",
        ].includes(choice.value)
      );
    } else if (values.activityType === "Other") {
      choices = OTHER_CLAIM_ACTIONS;
    }
  }

  return (
    <SelectInput
      disabled={
        !values.activityType ||
        (!values.activityType?.startsWith("Outbound") &&
          values.activityType !== "Other" &&
          values.activityType !== "CreditorDecision" &&
          values.activityType !== "Research" &&
          values.activityType !== "InternalDecision" &&
          !values.activityType?.endsWith("Activity"))
      }
      source="claimAction"
      label="Claim Action"
      choices={choices}
      optionText="label"
      allowEmpty
      optionValue="value"
      inputRef={claimActionInputRef}
    />
  );
};

const ActivityDateInput = () => {
  const { values } = useFormState();
  const today = new Date();

  if (!values.activityDate) {
    values.activityDate = today;
  } else if (values.activityDate && new Date(values.activityDate) > today) {
    values.isPlanned = true;
  }

  return <DateTimeInput required label="Activity Date" source="activityDate" />;
};

export const MessageInput = (props: any) => {
  const { notify, creditorData, claimId } = props;
  const { values } = useFormState();
  const form = useForm();

  useEffect(() => {
    if (props?.placeholderComment && !values.comment) {
      form.change("comment", props.placeholderComment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.placeholderComment]);

  if (["OutboundMail", "Response"].includes(values.activityType)) {
    return (
      <>
        <div style={{ minHeight: 200 }}>
          <RichTextInput
            required
            label="Comment"
            source="comment"
            onChange={(e) => {
              if (props?.setPlaceholderComment) {
                props.setPlaceholderComment(e);
              }
              form.change("isPlanned", false);
            }}
            multiline
            fullWidth
          />
          <div
            style={{
              width: "98%",
              padding: 10,
            }}
          >
            <Typography
              component={"span"}
              variant="subtitle2"
              style={{
                color: theme.palette.grey[700],
                fontSize: 12,
              }}
            >
              Signatur (ohne Grußform und Name) wird autom. angehängt.
            </Typography>
          </div>
        </div>
        <FileManagerInput id={claimId} />
      </>
    );
  }

  return (
    <Mentions
      values={values}
      notify={notify}
      placeholderComment={props?.placeholderComment}
      setPlaceholderComment={props?.setPlaceholderComment}
      creditorData={creditorData}
    />
  );
};

const FileManagerInput = ({ id }) => {
  const { values } = useFormState();
  const [claimId, setClaimId] = useState(null);
  const [record, setRecord] = useState(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
    setClaimId(values.claim.id);

    if (claimId) {
      const getRecord = async () => {
        try {
          const { data } = await dataProvider.getOne("Claim", { id: claimId });
          setRecord(data);
        } catch (error) {
          console.error("Error fetching record:", error);
        }
      };

      getRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.claim.id, claimId, id]);

  return <FileManager source="fileUrls" record={record} />;
};

export const Mentions = (props: any) => {
  const { values } = props;
  const form = useForm();
  const dataProvider = useDataProvider();
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const { data } = await dataProvider.getList("User", {
        filter: {
          businessName: { equals: "Debtist GmbH" },
          roles: { array_contains: "manager" },
        },
        pagination: { page: 1, perPage: 100 },
        sort: { field: "contactName", order: "ASC" },
      });
      setEmployees(
        data.map((employee) => ({
          id: employee.id,
          display: employee.contactName || employee.name,
        }))
      );
    };
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAssignee = async (assigneeId: string) => {
    const user = await dataProvider.getOne("User", { id: assigneeId });

    if (user.data) {
      return user.data.contactName || user.data.name;
    }
  };

  const getLanguageByAddress = async (addressId: string) => {
    if (!addressId) {
      return "";
    }

    const address = await dataProvider.getOne("Address", { id: addressId });

    if (address.data) {
      return address.data.languageCountry;
    }
  };

  useEffect(() => {
    if (props.preAssignee) {
      const assignee = getAssignee(props.preAssignee);
      handleAddAssignee(props.claimId, props.preAssignee);
      form.change("assignee", assignee);
      form.change("isPlanned", false);
      form.change("activityType", "InternalDecision");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, form, props.claimId, props.preAssignee]);

  const handleCommentChange = useCallback(
    (_event, newValue, _newPlainTextValue, mentions) => {
      form.change("comment", newValue);
      if (mentions.length > 0) {
        form.change("comment", newValue);
        form.change("isPlanned", false);
        form.change("activityType", "InternalDecision");
      }

      if (props?.setPlaceholderComment) {
        props.setPlaceholderComment(newValue);
      }
    },
    [form, props]
  );

  const handleAddAssignee = async (claimId: string, assigneeId: string) => {
    try {
      const updateClaim = await dataProvider.update("Claim", {
        id: claimId,
        data: { assignee: assigneeId ? { id: assigneeId } : null },
        previousData: { id: claimId },
      });
      if (updateClaim) {
        props.notify("User assigned successfully", { type: "success" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = debounce((query, callback) => {
    if (!query) return;

    const filteredEmployees = employees.filter((employee) =>
      employee.display.toLowerCase().includes(query.toLowerCase())
    );
    callback(filteredEmployees);
  }, 300);

  const [iso2CountryCode, setIso2CountryCode] = useState<string | undefined>();
  useEffect(() => {
    const fetchIso2CountryCode = async () => {
      const language = await getLanguageByAddress(
        props.creditorData?.address?.id
      );
      if (language) {
        setIso2CountryCode(
          searchMapByKey(ADDRESS_COUNTRY_SHORT, language || "")
        );
      }
    };

    fetchIso2CountryCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.creditorData?.address?.id]);

  return (
    <div className="topGInput">
      <MentionsInput
        value={values.comment || ""}
        onChange={handleCommentChange}
        className="topG"
        placeholder="Add a comment"
      >
        <Mention
          appendSpaceOnAdd
          trigger="@"
          data={fetchUsers}
          renderSuggestion={(suggestion) => {
            return (
              <div className="suggestion">
                <div className="active-user">
                  {getInitials(suggestion.display)}
                </div>
                {suggestion.display}
              </div>
            );
          }}
          onAdd={(mention) => {
            handleAddAssignee(values.claim?.id, mention.toString());
          }}
        />
      </MentionsInput>

      {values.activityType === "InternalFeedback" && (
        <Typography
          component={"span"}
          variant="subtitle2"
          style={{
            color: theme.palette.error.dark,
          }}
        >
          <ul>
            <li>Situation, Komplikation und Frage sehr kurz formulieren!</li>
            <li>Müssen wir zwingend den Gläubiger involvieren?</li>
            <li>
              Ist eine klare Frage und genug Kontext inkludiert? (Gläubiger sind
              keine Experten)
            </li>
          </ul>
        </Typography>
      )}

      {["InternalFeedback", "CreditorDecision"].includes(values.activityType) &&
        (props.creditorData?.disableCourtOrder ||
          props.creditorData?.courtOrder === "NO") && (
          <div
            style={{
              background: theme.palette.warning.main,
              borderRadius: 8,
              width: "98%",
              padding: 10,
              marginTop: 15,
            }}
          >
            <Typography
              component={"span"}
              variant="subtitle2"
              style={{
                color: theme.palette.common.white,
              }}
            >
              {"Der Gläubiger wünscht generell keine gerichtliche Durchsetzung"}
            </Typography>
          </div>
        )}

      {["InternalFeedback", "CreditorDecision"].includes(values.activityType) &&
        !!iso2CountryCode &&
        iso2CountryCode !== "DE" && (
          <div
            style={{
              background: theme.palette.warning.main,
              borderRadius: 8,
              width: "98%",
              padding: 10,
              marginTop: 15,
            }}
          >
            <Typography
              component={"span"}
              variant="subtitle2"
              style={{
                color: theme.palette.common.white,
                fontWeight: "bold",
              }}
            >
              <ReactCountryFlag
                countryCode={iso2CountryCode}
                svg
                style={{ marginRight: 4 }}
              />
              Sprache des Gläubigers beachten.
              <ReactCountryFlag
                countryCode={iso2CountryCode}
                svg
                style={{ marginLeft: 4 }}
              />
            </Typography>
          </div>
        )}
    </div>
  );
};

const IsPlannedInput = (): React.ReactElement => {
  const form = useForm();

  return (
    <BooleanInput
      label="Planned"
      source="isPlanned"
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
      }}
      onChange={(value) => {
        if (!value) {
          // update to current date
          form.change("activityDate", new Date());
        } else if (INITIAL_DATE) {
          form.change("activityDate", INITIAL_DATE);
        }
      }}
    />
  );
};

const ResultInput = () => {
  const { values } = useFormState();
  const form = useForm();

  return (
    <SelectInput
      source="result"
      label="Result"
      onChange={(e) => {
        if (e.target.value) {
          form.change("activityDate", new Date());
          form.change("isPlanned", false);
        }
      }}
      choices={
        !values.activityType ||
        values.activityType?.startsWith("Outbound") ||
        values.activityType === "Response"
          ? ActivityResultsForOutbound
          : ActivityResultsForDecisions
      }
      optionText="label"
      allowEmpty
      optionValue="value"
    />
  );
};

const ActivityTypeInput = (props: any) => {
  const form = useForm();
  const { values } = useFormState();

  // todo: do we need this?
  const isUserTagged = useMemo(() => {
    return values.comment && values.comment.includes(" @");
  }, [values.comment]);

  const choices = useMemo(() => {
    if (isUserTagged) {
      return getFormattedActivityTypeChoices().filter(
        (choice: { value: string }) => choice.value === "InternalDecision"
      );
    }
    return getFormattedActivityTypeChoices();
  }, [isUserTagged]);
  // end todo

  return (
    <SelectInput
      required
      onChange={(e) => {
        if (
          e.target?.value?.startsWith("Outbound") ||
          e.target?.value?.endsWith("Decision") ||
          e.target?.value === "Other"
        ) {
          form.change("isPlanned", true);
        } else {
          form.change("isPlanned", false);
        }
      }}
      source="activityType"
      label="Activity Type"
      choices={choices}
      defaultValue={props.options?.externalId ? "OutboundMail" : undefined}
      optionText="label"
      optionValue="value"
    />
  );
};

const CustomSaveButton = (props) => {
  const { values } = useFormState();

  const sendMode =
    ["OutboundMail", "OutboundLetter"].includes(values.activityType) &&
    !values.claimAction &&
    (!!values.externalId || !!values.comment);

  return (
    <SaveButton
      {...props}
      style={{
        display: "flex",
        justifySelf: "flex-end",
        marginRight: 10,
        marginTop: 20,
        ...(sendMode ? { backgroundColor: theme.palette.secondary.main } : {}),
      }}
      label={sendMode ? "Send" : "Save"}
      icon={sendMode ? <SendRounded /> : <SaveRounded />}
    />
  );
};

const AssigneeInline = (props) => {
  const form = useForm();
  const [showComments, setShowComments] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [selectedUser, setSelectedUser] = useState(null);

  const handleAssigneeChange = useCallback(
    async (event) => {
      const userId = event.target.value;

      if (!userId) {
        await dataProvider.update("Claim", {
          id: props.options?.claimId,
          data: { assignee: null },
          previousData: { id: props.options?.claimId },
        });

        notify("User unassigned successfully", { type: "success" });
        props.options?.setShow(false);
        return;
      }

      try {
        const { data: user } = await dataProvider.getOne("User", {
          id: userId,
        });
        const userName = user.contactName || user.name;

        setShowComments(true);
        form.change("isPlanned", false);
        form.change("activityType", "InternalDecision");

        const mentionText = `@[${userName}](${userId})`;
        form.change("comment", mentionText);
        props.setHasFormChanged(true);

        setSelectedUser(user);

        await dataProvider.update("Claim", {
          id: props.options?.claimId,
          data: { assignee: { id: userId } },
          previousData: { id: props.options?.claimId },
        });

        notify("User assigned successfully", { type: "success" });
      } catch (error) {
        console.error("Error assigning user:", error);
        notify("Error assigning user", { type: "error" });
      }
    },
    [dataProvider, props, notify, form]
  );

  return (
    <NonInput>
      <ReferenceInput
        filterToQuery={() => ({
          businessName: {
            equals: "Debtist GmbH",
          },
          roles: {
            array_contains: "manager",
          },
        })}
        sort={{ field: "contactName", order: "ASC" }}
        allowEmpty
        emptyText="(none)"
        source="assignee.id"
        reference="User"
        label="Assign User"
        onChange={handleAssigneeChange}
        defaultValue={props.options?.assigneeId}
      >
        <SelectInput fullWidth optionText={(record) => record?.contactName} />
      </ReferenceInput>
      {showComments && (
        <MessageInput
          notify={notify}
          claimId={props.options?.claimId}
          placeholderComment={props.options?.placeholderComment}
          setPlaceholderComment={props.options?.setPlaceholderComment}
          preSelectedUser={selectedUser}
        />
      )}
    </NonInput>
  );
};

export const ActivityCreate = (props: CreateProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const history = useHistory();
  const notify = useNotify();
  const refresh = useRefresh();
  const [formHasChanged, setHasFormChanged] = useState(false);

  const { creditorData, record } = useClaim(props.options?.claimId);

  const handleSubmit = (data) => {
    if (!Array.isArray(data.fileUrls)) {
      data.fileUrls = [data.fileUrls];
    }

    if (props.options?.assignAndInput) {
      props.options.refresh();
      props.options.setShow(false);
    }

    if (props.options?.setPlaceholderComment) {
      props.options.setPlaceholderComment("");
    }

    notify("Success", "info", null, false);

    if (!props.options?.inline) {
      history.goBack();
    }
  };

  return (
    <Create
      className={`activity-create ${props.options?.inline ? "-inline" : ""}`}
      {...props}
      onSuccess={() => {
        if (props.options?.assignAndInput) {
          props.options.refresh();
          props.options.setShow(false);
        }

        if (props.options?.setPlaceholderComment) {
          props.options.setPlaceholderComment("");
        }

        notify("Success", "info", null, false);
        refresh();

        if (!props.options?.inline) {
          history.goBack();
        }
      }}
    >
      <SimpleForm
        style={{
          padding: 20,
          paddingLeft: props.options?.assignAndInput ? 30 : 20,
        }}
        toolbar={
          props.options?.assignAndInput && !formHasChanged ? (
            <SaveButton
              disabled={true}
              style={{
                display: "flex",
                justifySelf: "flex-end",
                marginRight: 10,
                marginTop: 20,
              }}
            />
          ) : (
            <CustomSaveButton
              disabled={props.options?.assignAndInput && !formHasChanged}
            />
          )
        }
        onSubmit={handleSubmit}
      >
        <NonInput>
          <Grid container spacing={2} style={{ width: "100%" }}>
            {props.options?.assignAndInput && (
              <AssigneeInline
                options={props.options}
                setHasFormChanged={setHasFormChanged}
              />
            )}

            {props.options?.assignAndInput && (
              <div
                style={{
                  display: props.options?.assignAndInput ? "none" : "block",
                  width: "100%",
                }}
              >
                <Grid item xs={12} md={4}>
                  <ActivityDateInput />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ActivityTypeInput options={props.options} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ClaimActionInput />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ResultInput />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  style={
                    (parsedLocation.claimId || props.options?.claimId) && {
                      display: "none",
                    }
                  }
                >
                  <ReferenceInput
                    perPage={1}
                    filterToQuery={(searchText) => ({
                      reference: { startsWith: searchText },
                    })}
                    isRequired
                    source="claim.id"
                    defaultValue={
                      parsedLocation.claimId || props.options?.claimId
                    }
                    reference="Claim"
                    label="Claim"
                    onChange={() => setHasFormChanged(true)}
                  >
                    <AutocompleteInput
                      suggestionLimit={1}
                      optionText={ClaimTitle}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                  <MessageInput
                    notify={notify}
                    placeholderComment={props.options?.placeholderComment}
                    setPlaceholderComment={props.options?.setPlaceholderComment}
                    creditorData={creditorData}
                  />
                </Grid>
                <div
                  style={{
                    display: "inline-block",
                    paddingTop: "0.75rem",
                    paddingLeft: "0.5rem",
                  }}
                >
                  <IsPlannedInput />
                </div>
              </div>
            )}

            {!props.options?.assignAndInput && (
              <>
                <Grid item xs={12} md={4}>
                  <ActivityDateInput />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ActivityTypeInput options={props.options} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ClaimActionInput />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ResultInput />
                </Grid>
                <Grid item xs={12} md={4}>
                  {props.options?.externalId && (
                    <TextInput
                      label="External ID"
                      source="externalId"
                      disabled
                      defaultValue={props.options?.externalId}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  style={
                    (parsedLocation.claimId || props.options?.claimId) && {
                      display: "none",
                    }
                  }
                >
                  <ReferenceInput
                    perPage={1}
                    filterToQuery={(searchText) => ({
                      reference: { startsWith: searchText },
                    })}
                    isRequired
                    source="claim.id"
                    defaultValue={
                      parsedLocation.claimId || props.options?.claimId
                    }
                    reference="Claim"
                    label="Claim"
                  >
                    <AutocompleteInput
                      suggestionLimit={1}
                      optionText={ClaimTitle}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                  <MessageInput
                    notify={notify}
                    placeholderComment={props.options?.placeholderComment}
                    setPlaceholderComment={props.options?.setPlaceholderComment}
                    creditorData={creditorData}
                  />
                </Grid>
                {!props.options?.inline && (
                  <Grid item xs={12}>
                    <FileManager
                      source="fileUrls"
                      record={record}
                      currentActivityFileUrls={[]}
                    />
                  </Grid>
                )}
                <div
                  style={{
                    display: "flex",
                    paddingTop: "0.75rem",
                    paddingLeft: "0.5rem",
                    justifyContent: "flex-end",
                    width: "100%",
                    ...(props.options?.externalId && {
                      display: "none",
                    }),
                  }}
                >
                  <IsPlannedInput />
                </div>
              </>
            )}
          </Grid>
        </NonInput>
      </SimpleForm>
    </Create>
  );
};

export function getFormattedActivityTypeChoices(): object[] {
  return [
    {
      value: "outbound-header",
      label: (
        <span style={{ fontWeight: 600, fontSize: 11 }}>{"Ausgehend"}</span>
      ),
      disabled: true,
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <PhoneIphoneOutlined
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundCall")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundCall",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <AlternateEmail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundMail")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundMail",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <MailOutlined
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundLetter")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundLetter",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <WhatsApp
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundWhatsapp")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundWhatsapp",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <SmsOutlined
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundSms")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundSms",
    },
    {
      value: "creditor-header",
      label: (
        <span style={{ fontWeight: 600, fontSize: 11 }}>
          {"Gläubiger-Kommunikation"}
        </span>
      ),
      disabled: true,
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("InternalFeedback")}
          <Tooltip title='Setzt Akte auf "Feedback Required"'>
            <PriorityHigh
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.error.light,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "InternalFeedback",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("CreditorDecision")}
          <Tooltip title='Setzt Akte auf "Feedback Required"'>
            <PriorityHigh
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.error.light,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "CreditorDecision",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("CreditorInformation")}
        </span>
      ),
      value: "CreditorInformation",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("CreditorFeedback")}
        </span>
      ),
      value: "CreditorFeedback",
    },
    {
      value: "internal-header",
      label: (
        <span style={{ fontWeight: 600, fontSize: 11 }}>{"Internes"}</span>
      ),
      disabled: true,
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("InternalDecision")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "InternalDecision",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("Research")}
        </span>
      ),
      value: "Research",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("JudicialActivity")}
        </span>
      ),
      value: "JudicialActivity",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("InsolvencyActivity")}
        </span>
      ),
      value: "InsolvencyActivity",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("Other")}
        </span>
      ),
      value: "Other",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("Response")}
        </span>
      ),
      value: "Response",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("RecommendedOutboundMail")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "RecommendedOutboundMail",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("RecommendedInternalFeedback")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "RecommendedInternalFeedback",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("AiCreditorLetterSummary")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "AiCreditorLetterSummary",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("AiProposeLetterResponseToDebtorMail")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "AiProposeLetterResponseToDebtorMail",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("AiProposePaymentPlan")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "AiProposePaymentPlan",
    },
  ];
}
