import * as React from "react";

import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
} from "react-admin";

import { Grid } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ClaimTitle } from "../claim/ClaimTitle";
import { NonInput } from "../Components/NonInputComponent";
import { UserTitle } from "../user/UserTitle";

export const PaymentPlanEdit = (props: EditProps): React.ReactElement => {
  const history = useHistory();
  const notify = useNotify();

  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/PaymentPlan/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      onFailure={() => {
        history.go(-1);
      }}
      onSuccess={() => {
        notify("Success", "info", null, false);
        history.go(-1);
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar
            style={{
              justifyContent: "space-between",
              position: "fixed",
              bottom: 0,
              width: 230,
              right: 0,
              left: "auto",
              padding: "10px 20px",
              borderRadius: 8,
              background: "white",
              boxShadow: "rgb(215 211 211) 0px 10px 6px -6px",
            }}
          >
            <DeleteWithConfirmButton
              undoable={false}
              mutationMode="optimistic"
              label="Delete"
              variant={"text"}
              style={{ backgroundColor: "#e93c51" }}
              onFailure={() => {
                history.go(-1);
              }}
              icon={<DeleteOutline style={{ color: "white" }} />}
              onSuccess={() => {
                notify("Success", "info", null, false);
                history.go(-2);
              }}
            />
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <NonInput>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} lg={4}>
              <ReferenceInput
                perPage={1}
                filterToQuery={(searchText) => ({
                  reference: { startsWith: searchText },
                })}
                source="claim.id"
                reference="Claim"
                label="Claim"
                disabled
              >
                <AutocompleteInput
                  suggestionLimit={1}
                  optionText={ClaimTitle}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} lg={4}>
              <ReferenceInput
                perPage={1}
                filterToQuery={(searchText) => ({
                  name: { startsWith: searchText },
                })}
                isRequired
                source="debtor.id"
                reference="User"
                label="Debtor"
                disabled
              >
                <AutocompleteInput suggestionLimit={1} optionText={UserTitle} />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} lg={4}></Grid>
            <Grid item xs={12} lg={4}>
              <NumberInput
                isRequired
                disabled
                step={1}
                label="Number Of Payments"
                source="numberOfPayments"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <SelectInput
                isRequired
                disabled
                source="frequency"
                label="Frequency"
                choices={[
                  { label: "Weeks", value: "Weekly" },
                  { label: "Months", value: "Monthly" },
                  { label: "Months (Bi-monthly)", value: "Bimonthly" },
                  { label: "Quarters", value: "Quarterly" },
                  { label: "Half years (Semianually)", value: "Semiannually" },
                  { label: "Years", value: "Annually" },
                ]}
                defaultValue={"Monthly"}
                optionText="label"
                optionValue="value"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DateInput
                label="Next Payment Due Date"
                source="nextPaymentDueDate"
              />
            </Grid>
          </Grid>
          <BooleanInput
            label="Active"
            source="isActive"
            style={{ display: "block", marginTop: 20 }}
          />
          <BooleanInput
            disabled
            label="Has Court Title Note"
            source="hasCourtTitleNote"
            style={{ display: "inline" }}
          />
        </NonInput>
      </SimpleForm>
    </Edit>
  );
};
