import { useState } from "react";

import { TextInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";

const UsernameInput = (props: any) => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();
  const form = useForm();

  if (!manualMode) {
    if (values.email) {
      values.username = values.email;
    } else if (values.phone) {
      values.username = values.phone;
    } else if (props?.isPartner) {
      values.username =
        Math.random().toString(36).substring(2, 8) +
        Math.random().toString(36).substring(2, 8); // random
    }
  }

  return (
    <TextInput
      label="Username (generated)"
      source="username"
      InputProps={{
        className: !manualMode ? "Mui-disabled" : "",
      }}
      onFocus={() => setManualMode(true)}
      onBlur={(event) => {
        if (!event?.target?.value) {
          setManualMode(false);
        } else if (event?.target?.value.trim() !== event?.target?.value) {
          // Trim username value on blur
          form.change('username', event.target.value.trim());
        }
      }}
      {...props}
    />
  );
};

export default UsernameInput;
