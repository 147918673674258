import * as React from "react";

import { Grid } from "@material-ui/core";
import currency from "currency.js";
import { useCallback, useEffect, useState } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  maxValue,
  minValue,
  useGetIdentity,
  useNotify,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { useHistory } from "react-router-dom";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
} from "../api/payment/EnumPaymentPaymentType";
import { ClaimTitle } from "../claim/ClaimTitle";
import { NonInput } from "../Components/NonInputComponent";
import { DEV_USERS, FINANCE_MANAGERS } from "../config/AppConfig";
import { UserTitle } from "../user/UserTitle";
import {
  EURO_CURRENCY_FORMAT,
  SIMPLE_EURO_CURRENCY_FORMAT,
} from "./PaymentCreate";

const AmountInput = () => {
  const { values } = useFormState();
  const form = useForm();

  if (
    values.amount === undefined ||
    values.amount === "" ||
    values.amount === "-"
  ) {
    if (
      NEGATIVE_PAYMENT_TYPES.includes(
        values.paymentType as EnumPaymentPaymentType
      )
    ) {
      values.amount = "-";
    } else {
      values.amount = "";
    }
  }

  return (
    <>
      <NumberInput
        label="Amount (Use '-' for credits)"
        source="amount"
        required
        // eslint-disable-next-line no-self-assign
        onFocus={(e) => (e.target.value = e.target.value)}
        validate={
          values.paymentType === "Payout"
            ? null
            : NEGATIVE_PAYMENT_TYPES.includes(
                values.paymentType as EnumPaymentPaymentType
              )
            ? maxValue(0)
            : minValue(0)
        }
        onPaste={async (e) => {
          e.preventDefault();
          let pasteContent = e.clipboardData
            .getData("text")
            .replace(/[^\d.,]/g, "");
          if (pasteContent) {
            const parsedInput =
              EURO_CURRENCY_FORMAT.test(pasteContent) ||
              SIMPLE_EURO_CURRENCY_FORMAT.test(pasteContent)
                ? currency(pasteContent.replace(".", "").replace(",", "."), {
                    separator: "",
                    decimal: ".",
                    symbol: "€",
                  }).value
                : currency(pasteContent, {
                    separator: ",",
                    decimal: ".",
                    symbol: "$",
                  }).value;

            if (!isNaN(parsedInput)) {
              form.change("amount", parsedInput);
            }
          }
        }}
      />
      {values.paymentType ===
        EnumPaymentPaymentType.DebtCollectorOutboundPosition && (
        <div
          style={{
            display: "inline-block",
            marginTop: 10,
            marginLeft: 5,
            color: "red",
            width: "100%",
          }}
        >
          Use gross value!
          <ReferenceInput
            perPage={10}
            filterToQuery={(searchText) => ({
              name: { startsWith: searchText },
            })}
            isRequired
            source="recipient.id"
            reference="User"
            label="Recipient"
          >
            <AutocompleteInput
              isRequired
              suggestionLimit={10}
              optionText={UserTitle}
            />
          </ReferenceInput>
          <TextInput
            style={{ marginTop: 20, marginBottom: 20, display: "block" }}
            isRequired
            label="External ID (Reference)"
            source="externalId"
          />
        </div>
      )}
    </>
  );
};

const IsPlannedInput = () => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();

  if (values.fileUrl && !manualMode) {
    values.isPlanned = false;
  }

  return (
    <BooleanInput
      label="planned"
      source="isPlanned"
      onChange={() => {
        setManualMode(true);
      }}
      style={{ display: "inline" }}
    />
  );
};

export const PaymentEdit = (props: EditProps): React.ReactElement => {
  const history = useHistory();
  const notify = useNotify();
  const { identity } = useGetIdentity();

  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Payment/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Edit
      {...props}
      onSuccess={() => {
        notify("Success", "info", null, false);
        history.go(-2);
      }}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <Toolbar
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SaveButton label="Save" />
            {FINANCE_MANAGERS.includes(identity?.username.toString()) && (
              <DeleteWithConfirmButton
                undoable={false}
                style={{ border: 0 }}
                mutationMode="pessimistic"
                label="Delete"
                variant="outlined"
                onSuccess={() => {
                  notify("Success", "info", null, false);
                  history.goBack();
                }}
              />
            )}
          </Toolbar>
        }
      >
        <NonInput>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} md={6}>
              <DateInput label="Date" source="paymentDate" required />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                source="paymentType"
                label="Payment Type"
                disabled
                required
                choices={[
                  { label: "DunningCostExpense", value: "DunningCostExpense" },
                  { label: "DunningCostFee", value: "DunningCostFee" },
                  { label: "DefaultInterest", value: "DefaultInterest" },
                  {
                    label: "ExistingCreditorExtras",
                    value: "ExistingCreditorExtras",
                  },
                  {
                    label: "ExistingPayments",
                    value: "ExistingPayments",
                  },
                  { label: "WriteOffDiscount", value: "WriteOffDiscount" },
                  { label: "DebtClearance", value: "DebtClearance" },
                  { label: "PaymentRate", value: "PaymentRate" },
                  { label: "Payout", value: "Payout" },
                  { label: "CreditorExpenses", value: "CreditorExpenses" },
                  { label: "ClaimPosition", value: "ClaimPosition" },
                  { label: "CreditorInterest", value: "CreditorInterest" },
                  { label: "DebtCollectorTax", value: "DebtCollectorTax" },
                  { label: "DebtCollectorFee", value: "DebtCollectorFee" },
                  {
                    label: "DebtCollectorOutboundPosition",
                    value: "DebtCollectorOutboundPosition",
                  },
                  {
                    label: "DebtCollectorInboundPosition",
                    value: "DebtCollectorInboundPosition",
                  },
                ]}
                optionText="label"
                optionValue="value"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AmountInput />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                label="Reference (Example: Interest rate)"
                source="reference"
              />
            </Grid>
            {DEV_USERS.includes(identity?.username) && (
              <>
                <Grid item xs={12} md={6}>
                  <TextInput label="ExternalId" source="externalId" />
                  <TextInput label="File URL" source="fileUrl" />
                  <TextInput label="Conversion Rate" source="conversionRate" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ReferenceInput
                    perPage={5}
                    filterToQuery={(searchText) => ({
                      reference: { startsWith: searchText },
                    })}
                    isRequired
                    source="claim.id"
                    reference="Claim"
                    label="Claim"
                  >
                    <AutocompleteInput
                      suggestionLimit={5}
                      optionText={ClaimTitle}
                    />
                  </ReferenceInput>
                </Grid>
              </>
            )}
          </Grid>
        </NonInput>

        <IsPlannedInput />
        <BooleanInput
          label="Tax free"
          source="isTaxFree"
          defaultValue={false}
          style={{ display: "inline-block", marginTop: 10 }}
        />
      </SimpleForm>
    </Edit>
  );
};
