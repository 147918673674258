import { IconButton, makeStyles, TextField, Tooltip } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import React, { useState } from "react";
import { PaginationProps, Pagination as RAPagination } from "react-admin";

const PAGINATION_OPTIONS = [10, 25, 50, 100, 200];

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  pageSelector: {
    marginRight: theme.spacing(2),
    width: "120px",
  },
  pageInput: {
    "& input": {
      padding: "6px 8px",
      textAlign: "center",
      fontSize: 11,
    },
  },
  goButton: {
    padding: 6,
    marginLeft: theme.spacing(0.5),
  },
}));

const CustomPagination = (props: PaginationProps) => {
  const classes = useStyles();
  const [pageInput, setPageInput] = useState<string>("");

  // Calculate total pages based on count and perPage
  const totalPages = Math.ceil((props.total || 0) / props.perPage);

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Allow only numbers
    const value = e.target.value.replace(/[^0-9]/g, "");
    setPageInput(value);
  };

  const handleGoToPage = () => {
    if (!pageInput) return;

    const pageNumber = parseInt(pageInput, 10);

    // Validate page number is within range
    if (pageNumber > 0 && pageNumber <= totalPages) {
      props.setPage(pageNumber);
      setPageInput("");
    } else {
      // If invalid, clear the input
      setPageInput("");
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleGoToPage();
    }
  };

  return (
    <div className={classes.paginationContainer}>
      <div className={classes.pageSelector}>
        <Tooltip title={`Go to page (1-${totalPages})`}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Go to ..."
            value={pageInput}
            onChange={handlePageInputChange}
            onKeyPress={handleKeyPress}
            className={classes.pageInput}
            InputProps={{
              endAdornment: (
                <IconButton
                  className={classes.goButton}
                  onClick={handleGoToPage}
                  size="small"
                  disabled={!pageInput}
                >
                  <NavigateNext fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Tooltip>
      </div>
      <RAPagination rowsPerPageOptions={PAGINATION_OPTIONS} {...props} />
    </div>
  );
};

export default CustomPagination;
