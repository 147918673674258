export enum EnumActivityClaimAction {
  FirstReminder = "FirstReminder",
  SecondReminder = "SecondReminder",
  ThirdReminder = "ThirdReminder",
  FirstDunning = "FirstDunning",
  SecondDunning = "SecondDunning",
  ThirdDunning = "ThirdDunning",
  FourthDunning = "FourthDunning",
  FifthDunning = "FifthDunning",
  SixthDunning = "SixthDunning",
  PhoneResearch = "PhoneResearch",
  EmailResearch = "EmailResearch",
  OnlineResearch = "OnlineResearch",
  PublicAuthorityRequest = "PublicAuthorityRequest",
  DefaultSummon = "DefaultSummon",
  Lawsuit = "Lawsuit",
  FollowUpCreditorGeneral = "FollowUpCreditorGeneral",
  FollowUpCreditorInsolvencyFiling = "FollowUpCreditorInsolvencyFiling",
  FollowUpCreditorLawsuit = "FollowUpCreditorLawsuit",
  FollowUpCreditorPaymentOrder = "FollowUpCreditorPaymentOrder",
  FollowUpDebtorInsolvencyAdvice = "FollowUpDebtorInsolvencyAdvice",
  FollowUpDebtorLawyer = "FollowUpDebtorLawyer",
  FollowUpPartner = "FollowUpPartner",
  FollowUpDeadline = "FollowUpDeadline",
  FollowUpInternal = "FollowUpInternal",
  DebtCollectionTransfer = "DebtCollectionTransfer",
  DiscountOffer = "DiscountOffer",
}
