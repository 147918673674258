import { Avatar, CircularProgress, Grid, Typography } from "@material-ui/core";
import qs from "qs";
import { useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateInput,
  PasswordInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { useLocation } from "react-router-dom";
import { AddressTitle } from "../../../address/AddressTitle";
import { CLAIM_TYPES } from "../../../api/claim/Claim";
import {
  EnumUserBusinessContactRelationType,
  getReadableBusinessContactRelationType,
} from "../../../api/user/EnumUserBusinessContactRelationType";
import { ClaimTitle } from "../../../claim/ClaimTitle";
import useFormValidation from "../../../context/hook/useFormValidation";
import { ROLES_OPTIONS } from "../../../user/RolesOptions";
import { UserTitle } from "../../../user/UserTitle";
import { getAuthToken } from "../../../util/JsonFilter";
import EmailInput from "../input/EmailInput";
import NameInput from "../input/NameInput";
import UsernameInput from "../input/UsernameInput";

interface BasicInfoStepProps {
  isAdditionalContact: boolean;
  isLaywerUser: boolean;
  isInsolvencyAdministrator: boolean;
  isDebtor: boolean;
  isPartner: boolean;
  onValidationChange?: (isValid: boolean) => void;
  isEdit?: boolean;
}

const randomPassword =
  Math.random().toString(36).substring(2, 8) +
  Math.random().toString(36).substring(2, 8);

const AddressInput = () => {
  const record = useRecordContext();

  return (
    <ReferenceInput
      perPage={5}
      fullWidth
      filterToQuery={(searchText) => ({
        addressLine1: { startsWith: searchText || "*" },
      })}
      source="address.id"
      reference="Address"
      label="Address"
      create
    >
      <AutocompleteInput
        suggestionLimit={1}
        width="100%"
        createLabel="-- NEW --"
        createItemLabel="-- NEW --"
        createValue="-- NEW --"
        optionText={AddressTitle}
        clearAlwaysVisible
        onSelect={(selectedItem) => {
          if (selectedItem?.id === "-- NEW --") {
            window.open("/#/Address/create?userId=" + record.id, "_blank");
          }
        }}
      />
    </ReferenceInput>
  );
};

const Roles = (props: any) => {
  const { values } = useFormState();

  if (
    ((props.isNormalUser && !props.isDebtor) || props.isPartner) &&
    !values.roles
  ) {
    values.roles = ["creditor"];
  } else if ((props.isDebtor || !props.isNormalUser) && !values.roles) {
    values.roles = ["debtor"];
  }

  return (
    <SelectArrayInput
      style={!props.isNormalUser ? { display: "none" } : {}}
      source="roles"
      choices={ROLES_OPTIONS}
      required
      optionText="label"
      optionValue="value"
    />
  );
};

const ProfileImageUploader = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState(false);

  const uploadProps = {
    name: "file",
    accept: "image/*",
    onChange: async (event) => {
      const files = event.target.files;
      if (!files || files.length === 0) return;

      setIsLoading(true);
      const authHeader = {
        headers: {
          Authorization: getAuthToken(),
        },
      };

      try {
        const file = files[0];
        const formData = new FormData();
        formData.append("file", file);
        // need to get the full file as this needs to be uploaded once user is created

        if (!record?.id) {
          formData.append("fileUrl", file);
          notify("Profile image uploaded successfully", "success");
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/users/${
            record?.id ?? formData.get("id")
          }/image`,
          {
            method: "POST",
            body: formData,
            ...authHeader,
          }
        );

        if (!response.ok) {
          throw new Error("Upload failed");
        }

        notify("Profile image uploaded successfully", "success");
        refresh();
      } catch (error) {
        notify("Upload failed", "error");
      } finally {
        setIsLoading(false);
        event.target.value = "";
      }
    },
  };

  return (
    <div style={{ margin: "10px 0" }}>
      <Typography variant="caption">Profile Image</Typography>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {record?.id && record?.imageUrl ? (
            <>
              <Avatar
                src={`https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_STORAGE_PUBLIC_BUCKET}/${record.imageUrl}`}
                alt={record?.contactName + " Profile Image"}
                style={{ width: 80, height: 80, marginBottom: 10 }}
              />
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ display: "block" }}
              >
                {record?.imageUrl}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No image uploaded
            </Typography>
          )}
          <input type="file" {...uploadProps} />
        </>
      )}
    </div>
  );
};
const BasicInfoForm: React.FC<BasicInfoStepProps> = ({
  isAdditionalContact,
  isLaywerUser,
  isInsolvencyAdministrator,
  isPartner,
  isDebtor,
  onValidationChange,
  isEdit,
}) => {
  const notify = useNotify();
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const { values } = useFormState();
  const record = useRecordContext();
  const form = useForm();
  const basicInfoValidation = useFormValidation({
    contactName: {
      required: true,
    },
    email: {
      required: true,
    },
    username: {
      required: true,
    },
    password: {
      required: true,
    },
    roles: {
      required: true,
    },
  });

  const trimField = (fieldName) => {
    if (values && values[fieldName]) {
      form.change(fieldName, values[fieldName].trim());
    }
  };

  useEffect(() => {
    if (!onValidationChange) {
      return;
    }
    const hasErrors = basicInfoValidation.hasErrors();
    const errors = basicInfoValidation.getErrors();

    if (
      !values ||
      Object.keys(values).length === 0 ||
      !values.email ||
      !values.roles
    )
      return;

    // Trim fields before submitting
    if (values.contactName) {
      form.change("contactName", values.contactName.trim());
    }
    if (values.businessName) {
      form.change("businessName", values.businessName.trim());
    }
    if (values.phone) {
      form.change("phone", values.phone.trim());
    }
    // Note: email is already handled in EmailInput component

    if (hasErrors) {
      notify("Validation errors:" + JSON.stringify(errors));
    }
    onValidationChange && onValidationChange(!hasErrors);
  }, [values, basicInfoValidation, onValidationChange, notify, form]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        {parsedLocation.claimId && (
          <div
            style={{
              display: "hidden",
            }}
          >
            <ReferenceArrayInput
              filterToQuery={(searchText) => ({
                reference: { startsWith: searchText },
              })}
              isRequired
              defaultValue={[parsedLocation.claimId]}
              source="claimsAsContact"
              reference="Claim"
              label="Claim"
            >
              <AutocompleteArrayInput
                suggestionLimit={5}
                optionText={ClaimTitle}
              />
            </ReferenceArrayInput>
          </div>
        )}
        <Typography component={"span"} variant="caption">
          Contact Details
        </Typography>
        <TextInput
          fullWidth
          label="Contact Name"
          source="contactName"
          autoFocus
          required
          onBlur={() => trimField("contactName")}
        />
        <TextInput
          fullWidth
          required={isLaywerUser ? true : false}
          label="Business Name"
          source="businessName"
          onBlur={() => trimField("businessName")}
        />
        <EmailInput isEdit={isEdit} />
        <TextInput
          fullWidth
          label="Phone"
          source="phone"
          onBlur={() => trimField("phone")}
        />
        {isEdit && <AddressInput />}
        {isEdit ? (
          <ProfileImageUploader record={record} />
        ) : (
          <TextInput label="Image URL" source="imageUrl" />
        )}
        {!isAdditionalContact &&
          !isLaywerUser &&
          !isInsolvencyAdministrator && (
            <>
              <DateInput label="Birthday" fullWidth source="birthday" />
              <SelectInput
                fullWidth
                source="industry"
                label="Industry"
                choices={[
                  { label: "Banking", value: "Banking" },
                  { label: "Fintech", value: "Fintech" },
                  { label: "Insurance", value: "Insurance" },
                  { label: "Lending", value: "Lending" },
                  {
                    label: "PaymentServiceProvider",
                    value: "PaymentServiceProvider",
                  },
                  { label: "ECommerce", value: "ECommerce" },
                  { label: "Software", value: "Software" },
                  { label: "Telecom", value: "Telecom" },
                  { label: "Healthcare", value: "Healthcare" },
                  { label: "Fitness", value: "Fitness" },
                  { label: "Construction", value: "Construction" },
                  { label: "RealEstate", value: "RealEstate" },
                  { label: "Rental", value: "Rental" },
                  { label: "Government", value: "Government" },
                  { label: "Education", value: "Education" },
                  { label: "Consulting", value: "Consulting" },
                  { label: "Retail", value: "Retail" },
                  { label: "Marketplace", value: "Marketplace" },
                  { label: "Logistics", value: "Logistics" },
                  { label: "Fulfillment", value: "Fulfillment" },
                  { label: "Wholesale", value: "Wholesale" },
                  { label: "Manufacturing", value: "Manufacturing" },
                  { label: "Media", value: "Media" },
                  { label: "Mobility", value: "Mobility" },
                  { label: "Parking", value: "Parking" },
                ]}
                optionText="label"
                allowEmpty
                optionValue="value"
              />
              <SelectInput
                fullWidth
                source="defaultClaimType"
                label="Default Claim Type"
                choices={CLAIM_TYPES.map((claim) => ({
                  label: claim.title + " (" + claim.number + ")",
                  value: claim.key,
                }))}
                optionText="label"
                allowEmpty
                optionValue="value"
              />
            </>
          )}
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          Username & Password
        </Typography>
        <NameInput fullWidth />
        <UsernameInput fullWidth required isPartner />
        <PasswordInput
          initiallyVisible={!isAdditionalContact}
          fullWidth
          required
          source="password"
          defaultValue={isEdit ? undefined : randomPassword}
        />
        <SelectInput
          source="emailVerificationStatus"
          label="Email Status"
          choices={[
            { label: "VALID", value: "VALID" },
            { label: "INVALID", value: "INVALID" },
            { label: "UNKNOWN", value: "UNKNOWN" },
          ]}
          optionText="label"
          allowEmpty
          optionValue="value"
        />
        <SelectInput
          source="phoneVerificationStatus"
          label="Phone Status"
          choices={[
            { label: "VALID", value: "VALID" },
            { label: "INVALID", value: "INVALID" },
            { label: "UNKNOWN", value: "UNKNOWN" },
          ]}
          optionText="label"
          allowEmpty
          optionValue="value"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          User Roles & Permissions
        </Typography>
        <SelectInput
          source="userType"
          label="User Type"
          choices={[
            { id: "Default", name: "Default" },
            { id: "Lawyer", name: "Lawyer" },
            {
              id: "InsolvencyAdministrator",
              name: "Insolvency Administrator",
            },
            { id: "AdditionalContact", name: "Additional Contact" },
            { id: "Partner", name: "Partner" },
          ]}
          defaultValue={
            isLaywerUser
              ? "Lawyer"
              : isInsolvencyAdministrator
              ? "InsolvencyAdministrator"
              : isPartner
              ? "Partner"
              : isAdditionalContact
              ? "AdditionalContact"
              : "Default"
          }
          required
        />
        <SelectInput
          source="businessType"
          label="Business Type"
          choices={[
            { id: "Business", name: "B2B (Business)" },
            { id: "Consumer", name: "B2C (Consumer)" },
            { id: "Merchant", name: "B2C (Merchant)" },
          ]}
          defaultValue="Business"
        />
        {!isAdditionalContact &&
          !isLaywerUser &&
          !isInsolvencyAdministrator && (
            <>
              <SelectInput
                source="businessContactRelationType"
                label="Business Contact Relation"
                choices={Object.values(EnumUserBusinessContactRelationType).map(
                  (relationType) => ({
                    label: getReadableBusinessContactRelationType(relationType),
                    value: relationType,
                  })
                )}
                optionText="label"
                optionValue="value"
              />
              <ReferenceInput
                perPage={10}
                filterToQuery={(searchText) => ({
                  name: { startsWith: searchText },
                  businessName: { equals: "Debtist GmbH" },
                  roles: { array_contains: "manager" },
                })}
                sort={{ field: "contactName", order: "ASC" }}
                source="manager.id"
                reference="User"
                label="Manager"
              >
                <AutocompleteInput
                  suggestionLimit={10}
                  optionText={UserTitle}
                />
              </ReferenceInput>
            </>
          )}
        <Roles
          isNormalUser={!isAdditionalContact}
          isDebtor={isDebtor}
          isPartner={isPartner}
        />
      </Grid>
    </Grid>
  );
};

export default BasicInfoForm;
