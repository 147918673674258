// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bank: {
    accountHolder: "Debtist GmbH",
    iban: "DE88100400000250314201",
    bic: "COBADEFFXXX",
    companyAccount: {
      accountHolder: "Debtist GmbH",
      iban: "DE18100400000250314200",
      bic: "COBADEFFXXX",
    },
  },
  taxRate: {
    DEU: 19,
  },
};

export const TOOL_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "j.grieb@debtist.de",
  "n.hazrati@debtist.de",
  "l.zimmermann@debtist.de",
  "m.barth@debtist.de",
  "m.schoning@debtist.de",
  "p.hemmingson@debtist.de",
];

export const OPS_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "b.morgado@debtist.de",
  "s.jung@debtist.de",
  "a.lesnikowski@debtist.de",
  "j.grieb@debtist.de",
  "c.binder@debtist.de",
];

export const OPS_ELEVATED_RIGHTS_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "j.grieb@debtist.de",
  "s.jung@debtist.de",
  "s.koschny@debtist.de",
  "l.giles@debtist.de",
  "c.binder@debtist.de",
  "a.lesnikowski@debtist.de",
  "j.hoffmann@debtist.de",
  "o.pohl@debtist.de",
];

export const OPS_COURT_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "j.grieb@debtist.de",
  "s.jung@debtist.de",
  "s.koschny@debtist.de",
  "l.giles@debtist.de",
  "c.binder@debtist.de",
];

export const FINANCE_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "b.morgado@debtist.de",
  "s.jung@debtist.de",
  "n.hazrati@debtist.de",
  "l.zimmermann@debtist.de",
  "m.barth@debtist.de",
  "m.schoning@debtist.de",
  "p.hemmingson@debtist.de",
  "a.lesnikowski@debtist.de",
  "c.binder@debtist.de",
  "t.linek@debtist.de",
];

export const RESEARCH_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "b.morgado@debtist.de",
  "s.jung@debtist.de",
  "n.hazrati@debtist.de",
  "l.zimmermann@debtist.de",
  "a.lesnikowski@debtist.de",
  "i.moldovan@debtist.de",
];

export const VIP_USERS = ["b.baumgarten@debtist.de", "m.benedetti@debtist.de"];

export const DEV_USERS = [
  "b.baumgarten@debtist.de",
  "j.grieb@debtist.de",
  "admin",
];

export const ITALIAN_GUY = "clf4crymt0000s601r43i14j7"; // m.benedetti PROD
