import AlternateEmail from "@material-ui/icons/AlternateEmail";
import MarkunreadMailboxOutlined from "@material-ui/icons/MarkunreadMailboxOutlined";
import Phone from "@material-ui/icons/Phone";
import Reply from "@material-ui/icons/Reply";
import Sms from "@material-ui/icons/Sms";
import WhatsApp from "@material-ui/icons/WhatsApp";
import React from "react";
import { useListContext } from "react-admin";
import { Activity } from "../api/activity/Activity";
import { EnumActivityActivityType } from "../api/activity/EnumActivityActivityType";
import { EnumActivityResult } from "../api/activity/EnumActivityResult";
import { theme } from "../theme/theme";

// Pre-define styles to avoid recreation on each render
const iconBaseStyle = {
  width: "auto",
  height: 14,
  marginRight: 2,
};

const replyIconStyle = {
  ...iconBaseStyle,
  height: 16,
};

const counterSpanStyle = {
  padding: 2,
  display: "flex",
  alignItems: "center",
};

const infoColorStyle = {
  ...counterSpanStyle,
  color: theme.palette.info.main,
};

const greyColorStyle = {
  ...counterSpanStyle,
  color: theme.palette.grey[500],
};

const successColorStyle = {
  ...counterSpanStyle,
  color: theme.palette.success.main,
};

const errorColorStyle = {
  ...counterSpanStyle,
  color: theme.palette.error.main,
};

const warningColorStyle = {
  ...counterSpanStyle,
  color: theme.palette.warning.main,
};

const rowStyle = {
  display: "flex",
  flexDirection: "row" as const,
};

// Memoized activity counter component to reduce rerenders
const ActivityCounter = React.memo(
  ({ count, Icon, title, style, iconStyle = iconBaseStyle }: any) => {
    if (count === 0) return null;

    return (
      <span title={title} style={style}>
        {count}
        <Icon style={iconStyle} />
      </span>
    );
  }
);

export const ActivitySummary = React.memo(() => {
  const { data, loading } = useListContext();
  const activities: Activity[] = React.useMemo(
    () => (data ? Object.values(data) : []) as Activity[],
    [data]
  );

  const activityCounts = React.useMemo(() => {
    // Fast path: if no activities
    if (!activities || activities.length === 0) {
      return {
        failedFullPhoneCalls: 0,
        successfulPhoneCalls: 0,
        outboundEmails: 0,
        outboundLetters: 0,
        plannedEmails: 0,
        plannedLetters: 0,
        neutralResponses: 0,
        positiveResponses: 0,
        outboundWhatsApps: 0,
        plannedWhatsApps: 0,
        outboundSmss: 0,
        plannedSmss: 0,
      };
    }

    return activities.reduce(
      (counts, activity) => {
        switch (activity.activityType) {
          case EnumActivityActivityType.OutboundCall:
            if (
              activity.result === EnumActivityResult.NotReached &&
              !activity.isPlanned
            ) {
              counts.failedFullPhoneCalls++;
            } else if (!activity.isPlanned) {
              counts.successfulPhoneCalls++;
            }
            break;
          case EnumActivityActivityType.OutboundMail:
            activity.isPlanned
              ? counts.plannedEmails++
              : counts.outboundEmails++;
            break;
          case EnumActivityActivityType.OutboundLetter:
            activity.isPlanned
              ? counts.plannedLetters++
              : counts.outboundLetters++;
            break;
          case EnumActivityActivityType.Response:
            if (
              activity.result === EnumActivityResult.ReachedPromisedToPay ||
              activity.result === EnumActivityResult.ReachedPaymentPlanSetup
            ) {
              counts.positiveResponses++;
            } else {
              counts.neutralResponses++;
            }
            break;
          case EnumActivityActivityType.OutboundWhatsapp:
            activity.isPlanned
              ? counts.plannedWhatsApps++
              : counts.outboundWhatsApps++;
            break;
          case EnumActivityActivityType.OutboundSms:
            activity.isPlanned ? counts.plannedSmss++ : counts.outboundSmss++;
            break;
        }
        return counts;
      },
      {
        failedFullPhoneCalls: 0,
        successfulPhoneCalls: 0,
        outboundEmails: 0,
        outboundLetters: 0,
        plannedEmails: 0,
        plannedLetters: 0,
        neutralResponses: 0,
        positiveResponses: 0,
        outboundWhatsApps: 0,
        plannedWhatsApps: 0,
        outboundSmss: 0,
        plannedSmss: 0,
      }
    );
  }, [activities]);

  // Early return for empty data
  if (loading || !activities || !activities.length) {
    return null;
  }

  // Check if we have nothing to display at all
  const hasAnyActivity = Object.values(activityCounts).some(
    (count) => count > 0
  );
  if (!hasAnyActivity) {
    return null;
  }

  return (
    <div>
      <div style={rowStyle}>
        <ActivityCounter
          count={activityCounts.outboundEmails}
          Icon={AlternateEmail}
          title="Outbound emails"
          style={infoColorStyle}
        />
        <ActivityCounter
          count={activityCounts.plannedEmails}
          Icon={AlternateEmail}
          title="Planned emails"
          style={greyColorStyle}
        />
        <ActivityCounter
          count={activityCounts.outboundLetters}
          Icon={MarkunreadMailboxOutlined}
          title="Outbound letters"
          style={infoColorStyle}
        />
        <ActivityCounter
          count={activityCounts.plannedLetters}
          Icon={MarkunreadMailboxOutlined}
          title="Planned letters"
          style={greyColorStyle}
        />
      </div>

      <div style={rowStyle}>
        <ActivityCounter
          count={activityCounts.outboundWhatsApps}
          Icon={WhatsApp}
          title="Outbound WhatsApps"
          style={infoColorStyle}
        />
        <ActivityCounter
          count={activityCounts.plannedWhatsApps}
          Icon={WhatsApp}
          title="Planned WhatsApps"
          style={greyColorStyle}
        />
        <ActivityCounter
          count={activityCounts.outboundSmss}
          Icon={Sms}
          title="Outbound SMSs"
          style={infoColorStyle}
        />
        <ActivityCounter
          count={activityCounts.plannedSmss}
          Icon={Sms}
          title="Planned SMSs"
          style={greyColorStyle}
        />
      </div>

      <div style={rowStyle}>
        <ActivityCounter
          count={activityCounts.successfulPhoneCalls}
          Icon={Phone}
          title="Successful calls"
          style={successColorStyle}
        />
        <ActivityCounter
          count={activityCounts.failedFullPhoneCalls}
          Icon={Phone}
          title="Failed calls"
          style={errorColorStyle}
        />
      </div>

      <div style={rowStyle}>
        <ActivityCounter
          count={activityCounts.positiveResponses}
          Icon={Reply}
          title="Positive responses"
          style={successColorStyle}
          iconStyle={replyIconStyle}
        />
        <ActivityCounter
          count={activityCounts.neutralResponses}
          Icon={Reply}
          title="Neutral responses"
          style={warningColorStyle}
          iconStyle={replyIconStyle}
        />
      </div>
    </div>
  );
});
