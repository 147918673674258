import qs from "qs";
import * as React from "react";

import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
} from "../api/payment/EnumPaymentPaymentType";

import { Grid } from "@material-ui/core";
import currency from "currency.js";
import { useState } from "react";
import { ClaimTitle } from "../claim/ClaimTitle";
import { NonInput } from "../Components/NonInputComponent";
import AppConfig from "../config/AppConfig";
import { UserTitle } from "../user/UserTitle";

export const EURO_CURRENCY_FORMAT = new RegExp(/^\d{1,3}(\.\d{3})*(,\d+)?$/);
export const SIMPLE_EURO_CURRENCY_FORMAT = new RegExp(/^\d+,\d{2}$/);

const PaymentDataInputs = ({
  defaultValue,
  claimId,
  inline,
  reference,
}: any) => {
  const { values } = useFormState();
  const form = useForm();
  const [grossValue, setGrossValue] = useState<number>();

  React.useEffect(() => {
    if (defaultValue !== "0" && values.amount === undefined) {
      values.amount = Number(defaultValue);
    } else if (
      NEGATIVE_PAYMENT_TYPES.includes(
        values.paymentType as EnumPaymentPaymentType
      )
    ) {
      if (values.amount > 0) {
        form.change("amount", -values.amount);
      }
    } else if (values.paymentType !== "Payout" && values.amount < 0) {
      form.change("amount", -values.amount);
    }
  }, [defaultValue, form, values]);

  return (
    <>
      <Grid item xs={12} md={inline ? 12 : 4}>
        <NumberInput
          label="Amount"
          source="amount"
          defaultValue={defaultValue}
          required
          onPaste={async (e) => {
            e.preventDefault();
            let pasteContent = e.clipboardData
              .getData("text")
              .replace(/[^\d.,]/g, "");

            if (pasteContent) {
              const parsedInput =
                EURO_CURRENCY_FORMAT.test(pasteContent) ||
                SIMPLE_EURO_CURRENCY_FORMAT.test(pasteContent)
                  ? currency(pasteContent.replace(".", "").replace(",", "."), {
                      separator: "",
                      decimal: ".",
                      symbol: "€",
                    }).value
                  : currency(pasteContent, {
                      separator: ",",
                      decimal: ".",
                      symbol: "$",
                    }).value;

              if (!isNaN(parsedInput)) {
                form.change("amount", parsedInput);
              }
            }
          }}
          onFocus={(e) => {
            // eslint-disable-next-line no-self-assign
            e.target.value = e.target.value;
            setGrossValue(0);
          }}
        />
      </Grid>

      <Grid item xs={12} md={inline ? 12 : 4}>
        <TextInput
          label="Reference"
          source="reference"
          defaultValue={reference}
          placeholder="For example: Gerichtskosten Mahnbescheid"
        />
      </Grid>

      {!values.isTaxFree &&
        values.paymentType ===
          EnumPaymentPaymentType.DebtCollectorInboundPosition && (
          <Grid item xs={12} md={inline ? 12 : 4}>
            <div
              className="MuiFormControl-root MuiTextField-root MuiFormControl-marginDense"
              style={{
                position: "relative",
              }}
            >
              <label
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-marginDense MuiInputLabel-filled"
                data-shrink="false"
                htmlFor="reference"
                id="reference-label"
              >
                <span style={{ color: "red" }}>Use net value! Gross:</span>
              </label>
              <div className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense">
                <input
                  type="text"
                  className="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputMarginDense MuiFilledInput-inputMarginDense"
                  style={{
                    color: "red",
                    paddingLeft: "50%",
                  }}
                  value={grossValue || ""}
                  onChange={(e) => {
                    setGrossValue(Number(e.target.value));
                    values.amount = parseFloat(
                      Number(
                        Number(e.target.value || 0) / 1 +
                          AppConfig.taxRate["DEU"] / 100
                      ).toFixed(2)
                    );
                  }}
                />
              </div>
            </div>
          </Grid>
        )}
      {values.paymentType ===
        EnumPaymentPaymentType.DebtCollectorOutboundPosition && (
        <>
          <div
            style={{
              display: "inline-block",
              marginLeft: 20,
              fontSize: 12,
              color: "red",
              width: "100%",
            }}
          >
            Use gross value! And don't forget the invoice file 😊
          </div>
          <Grid item xs={12} md={inline ? 12 : 4}>
            <ReferenceInput
              perPage={5}
              fullWidth
              filterToQuery={(searchText) => ({
                name: { startsWith: searchText },
                roles: {
                  array_contains: "creditor",
                },
                userType: "Partner",
              })}
              source="recipient.id"
              reference="User"
              label="Recipient"
              validate={(value) =>
                values.paymentType ===
                  EnumPaymentPaymentType.DebtCollectorOutboundPosition && !value
                  ? "This field is required"
                  : undefined
              }
              create
            >
              <AutocompleteInput
                suggestionLimit={5}
                width="100%"
                optionText={UserTitle}
                createLabel="-- NEW --"
                createItemLabel="-- NEW --"
                createValue="-- NEW --"
                clearAlwaysVisible
                onSelect={(selectedItem) => {
                  if (selectedItem?.id === "-- NEW --") {
                    window.open(
                      "/#/User/create?userType=Partner&claimId=" + claimId,
                      "_blank"
                    );
                  }
                }}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={inline ? 12 : 4}>
            <TextInput
              label="Payment Reference (Bank)"
              source="externalId"
              placeholder="For example: RE001"
              validate={(value) =>
                values.paymentType ===
                  EnumPaymentPaymentType.DebtCollectorOutboundPosition && !value
                  ? "This field is required"
                  : undefined
              }
            />
          </Grid>
        </>
      )}
    </>
  );
};

const IsPlannedInput = () => {
  const { values } = useFormState();

  if (
    values.paymentType === EnumPaymentPaymentType.DebtCollectorInboundPosition
  ) {
    return null;
  }

  return (
    <BooleanInput
      label={
        values.paymentType ===
        EnumPaymentPaymentType.DebtCollectorOutboundPosition
          ? "Unpaid"
          : "Planned?"
      }
      source="isPlanned"
      defaultValue={
        values.paymentType ===
        EnumPaymentPaymentType.DebtCollectorOutboundPosition
          ? true
          : false
      }
      style={{ display: "inline" }}
    />
  );
};

const PaymentTypeInput = (props: any) => {
  const { values } = useFormState();
  const form = useForm();
  if (
    (values.paymentType === EnumPaymentPaymentType.Payout ||
      values.paymentType ===
        EnumPaymentPaymentType.DebtCollectorInboundPosition) &&
    values.amount !== 0
  ) {
    values.isPlanned = true;
  }

  return (
    <div
      style={
        props.defaultValue && {
          display: "none",
        }
      }
    >
      <SelectInput
        // eslint-disable-next-line no-self-assign
        onFocus={(e) => (e.target.value = e.target.value)}
        source="paymentType"
        label="Payment Type"
        required
        defaultValue={props.defaultValue}
        onChange={(e) => {
          if (
            NEGATIVE_PAYMENT_TYPES.includes(
              e.target.value as EnumPaymentPaymentType
            )
          ) {
            if (values.amount > 0) {
              form.change("amount", -values.amount);
            }
          } else if (values.amount < 0) {
            form.change("amount", -values.amount);
          }
        }}
        choices={[
          { label: "DunningCostExpense", value: "DunningCostExpense" },
          { label: "DunningCostFee", value: "DunningCostFee" },
          { label: "DefaultInterest", value: "DefaultInterest" },
          {
            label: "ExistingCreditorExtras",
            value: "ExistingCreditorExtras",
          },
          {
            label: "ExistingPayments",
            value: "ExistingPayments",
          },
          { label: "WriteOffDiscount", value: "WriteOffDiscount" },
          { label: "DebtClearance", value: "DebtClearance" },
          { label: "PaymentRate", value: "PaymentRate" },
          { label: "Payout", value: "Payout" },
          { label: "CreditorExpenses", value: "CreditorExpenses" },
          { label: "ClaimPosition", value: "ClaimPosition" },
          { label: "CreditorInterest", value: "CreditorInterest" },
          { label: "DebtCollectorTax", value: "DebtCollectorTax" },
          { label: "DebtCollectorFee", value: "DebtCollectorFee" },
          {
            label: "DebtCollectorOutboundPosition",
            value: "DebtCollectorOutboundPosition",
          },
          {
            label: "DebtCollectorInboundPosition",
            value: "DebtCollectorInboundPosition",
          },
        ]}
        optionText="label"
        allowEmpty
        optionValue="value"
      />
    </div>
  );
};

export const PaymentCreate = (props: CreateProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const history = useHistory();
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Create
      {...props}
      onSuccess={() => {
        notify("Success", "info", null, false);
        if (props.options?.inline) {
          refresh();
          return;
        }
        history.goBack();
      }}
    >
      <SimpleForm className={props.options?.inline ? "small-form" : ""}>
        <NonInput>
          <div
            style={{
              ...(props.options?.inline
                ? {
                    overflowX: "auto",
                  }
                : {
                    maxWidth: "auto",
                    width: "100%",
                  }),
            }}
          >
            <div
              style={
                props.options?.claimId && {
                  display: "none",
                }
              }
            >
              <Grid
                container
                style={{
                  width: "100%",
                  maxWidth: props.options?.inline ? "none" : 1500,
                  ...(props.options?.inline && {
                    display: "table-row",
                    verticalAlign: "middle",
                  }),
                }}
                spacing={props.options?.inline ? 0 : 3}
              >
                <Grid item xs={12} md={4}>
                  <ReferenceInput
                    perPage={1}
                    filterToQuery={(searchText) => ({
                      reference: { startsWith: searchText },
                    })}
                    isRequired
                    disabled={parsedLocation.claimId || props.options?.claimId}
                    defaultValue={
                      parsedLocation.claimId || props.options?.claimId
                    }
                    source="claim.id"
                    reference="Claim"
                    label="Claim"
                  >
                    <AutocompleteInput
                      suggestionLimit={5}
                      optionText={ClaimTitle}
                    />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </div>

            <Grid
              container
              style={{
                width: "100%",
                maxWidth: props.options?.inline ? "none" : 1500,
              }}
              spacing={props.options?.inline ? 0 : 3}
            >
              <Grid item xs={12} md={props.options?.inline ? 12 : 4}>
                <DateInput
                  label="Date"
                  source="paymentDate"
                  required
                  defaultValue={new Date()}
                />
              </Grid>
              <Grid item xs={12} md={props.options?.inline ? 12 : 4}>
                <PaymentTypeInput
                  defaultValue={
                    parsedLocation.paymentType || props.options?.paymentType
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                width: "100%",
                maxWidth: props.options?.inline ? "none" : 1500,
              }}
              spacing={props.options?.inline ? 0 : 3}
            >
              <PaymentDataInputs
                defaultValue={parseFloat(
                  parsedLocation.amount?.toString() || ""
                )}
                claimId={parsedLocation.claimId || props.options?.claimId}
                inline={props.options?.inline}
                reference={parsedLocation.reference || props.options?.reference}
              />
            </Grid>

            <div
              style={
                props.options?.inline && {
                  display: "none",
                }
              }
            >
              <Grid item xs={12} md={12}>
                <IsPlannedInput />
                {parsedLocation.paymentType &&
                  ([
                    "DebtCollectorInboundPosition",
                    "DebtCollectorOutboundPosition",
                  ].includes(parsedLocation.paymentType.toString()) ||
                    [
                      "DebtCollectorInboundPosition",
                      "DebtCollectorOutboundPosition",
                    ].includes(props.options?.paymentType)) && (
                    <BooleanInput
                      label="Tax free"
                      source="isTaxFree"
                      defaultValue={false}
                      style={{ display: "inline-block" }}
                    />
                  )}
              </Grid>
            </div>
          </div>
        </NonInput>
      </SimpleForm>
    </Create>
  );
};
