import { Grid, Tooltip } from "@material-ui/core";
import {
  AttachMoney,
  HourglassEmptyOutlined,
  NotificationsActiveOutlined,
  SortByAlphaOutlined,
} from "@material-ui/icons";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import React, { useEffect, useRef, useState } from "react";
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import { Claim } from "../../../api/claim/Claim";
import { theme } from "../../../theme/theme";
import { getAuthToken } from "../../../util/JsonFilter";
import { ClaimSummary } from "../../ClaimSummary";
import GridHeader from "../../GridHeader";

const ClaimsToDoTab = React.memo((props: any) => {
  const ref = useRef();

  const limitPerPage = 250;

  const [claims, setClaims] = useState<Claim[]>([]);
  const [claimsToShow, setClaimsToShow] = useState(0);
  const [userTypeFilter, setUserTypeFilter] = useState<String | undefined>();
  const [activityTypeFilter, setActivityTypeFilter] = useState<
    String | undefined
  >();
  const [legalTypeFilter, setLegalTypeFilter] = useState<String | undefined>();
  const [hideNewClaims, setHideNewClaims] = useState(true);
  const [openClaimsSortOrder, setOpenClaimsSortOrder] = useState("asc");
  const [openClaimsSortField, setOpenClaimsSortField] = useState("updatedAt");
  const [activeSearchTerm, setActiveSearchTerm] = useState("");

  function toggleHideNewClaims(): void {
    setHideNewClaims(!hideNewClaims);
  }

  function increaseClaimsToShow(): void {
    const newValue = claimsToShow + limitPerPage;
    setClaimsToShow(newValue);
    props.setIsLoading(true);
  }

  function toggleOpenClaimsSortOrder(): void {
    setOpenClaimsSortOrder(openClaimsSortOrder === "desc" ? "asc" : "desc");
  }

  function toggleOpenClaimsSortField(): void {
    setOpenClaimsSortField(
      openClaimsSortField === "updatedAt" ? "totalPending" : "updatedAt"
    );
    if (openClaimsSortOrder === "asc" && openClaimsSortField === "updatedAt") {
      setOpenClaimsSortOrder("desc");
    }
  }

  const fetchData = (replace?: boolean) => {
    const authHeader = {
      headers: {
        Authorization: getAuthToken(),
        "Content-Type": "application/json",
      },
    };

    props.setIsLoading(true);
    fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
      method: "POST",
      body: JSON.stringify({
        type: !hideNewClaims
          ? "claimsNew"
          : activityTypeFilter === "Other"
          ? "claimsOther"
          : activityTypeFilter === "Creditor"
          ? "claimsCreditor"
          : activityTypeFilter === "Debtor"
          ? "claimsDebtor"
          : "claims",
        disablePersonalization:
          props.personalization === false ? true : undefined,
        userId: !!props.selectedManager ? props.selectedManager : undefined,
        enableLegal: props.enableLegal === true ? true : undefined,
        where: {
          creditorFeedbackRequired: {
            not: true,
          },
          ...(hideNewClaims === true && {
            stage: {
              in: ["Precourt", "Court", "Monitoring"],
            },
            status: "Paused",
            ...(activityTypeFilter && {
              activities: {
                ...(activityTypeFilter === "Debtor" && {
                  some: {
                    activityType: {
                      in: ["Response"],
                    },
                    claimAction: null,
                    isPlanned: false,
                  },
                }),
                ...(activityTypeFilter === "Creditor" && {
                  some: {
                    activityType: {
                      in: ["CreditorFeedback", "CreditorDecision"],
                    },
                    claimAction: null,
                    isPlanned: false,
                  },
                }),
                ...(activityTypeFilter === "Other" && {
                  some: {
                    activityType: {
                      in: ["Other", "InternalDecision"],
                    },
                    claimAction: null,
                    isPlanned: false,
                  },
                }),
              },
            }),
          }),
          ...(hideNewClaims === false && {
            status: {
              in: ["Paused", "Open"],
            },
            activities: {
              none: {
                claimAction: {
                  in: [
                    "FirstDunning",
                    "FirstReminder",
                    "SecondDunning",
                    "SecondReminder",
                    "ThirdDunning",
                    "ThirdReminder",
                    "FourthDunning",
                    "FifthDunning",
                    "SixthDunning",
                  ],
                },
              },
            },
          }),
          ...(userTypeFilter === "Consumer" && {
            debtor: {
              businessType: "Consumer",
            },
          }),
          ...(userTypeFilter === "Business" && {
            debtor: {
              businessType: {
                not: "Consumer",
              },
            },
          }),
          ...(legalTypeFilter === "Nothing" && {
            stage: "Precourt",
            contacts: {
              none: {
                userType: {
                  in: ["Lawyer", "InsolvencyAdministrator"],
                },
              },
            },
          }),
          ...(legalTypeFilter === "Court" && {
            stage: "Court",
          }),
          ...(legalTypeFilter === "Lawyers" && {
            contacts: {
              some: {
                userType: {
                  in: ["Lawyer", "InsolvencyAdministrator"],
                },
              },
            },
            stage: "Precourt",
          }),
          ...(activeSearchTerm && {
            creditor: {
              id: activeSearchTerm,
            },
          }),
        },
        take: limitPerPage,
        skip: replace ? 0 : claimsToShow,
        orderBy: {
          [openClaimsSortField]: openClaimsSortOrder,
        },
      }),
      ...authHeader,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          if (replace) {
            props.setTotalWaiting(responseJson.total?.toString());
            setClaims(responseJson.results || []);
          } else {
            if (!!responseJson.total) {
              props.setTotalWaiting(
                (Number(props.totalWaiting) + responseJson.total).toString()
              );
            }

            setClaims((prevClaims) => [
              ...prevClaims,
              ...(responseJson.results || []),
            ]);
          }
          props.setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        props.setIsLoading(false);
      });
  };

  useEffect(() => {
    // Only fetch data when tab is active
    if (props.tabValue === 1) {
      if (!claims?.length) {
        // Initial load when tab is first selected
        fetchData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabValue]);

  useEffect(() => {
    // Only fetch when tab is active AND any filter/sort changes
    // This prevents duplicate fetches when tab becomes active
    if (props.tabValue === 1) {
      setClaimsToShow(0);
      setClaims([]);
      fetchData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hideNewClaims,
    openClaimsSortField,
    openClaimsSortOrder,
    props.selectedManager,
    props.enableLegal,
    activeSearchTerm,
    userTypeFilter,
    activityTypeFilter,
    legalTypeFilter,
  ]);

  useEffect(() => {
    if (props.tabValue === 1 && claimsToShow > 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimsToShow]);

  useEffect(() => {
    // Handle personalization change independently
    if (props.tabValue === 1 && props.personalization !== undefined) {
      setClaimsToShow(0);
      setClaims([]);
      fetchData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.personalization]);

  return (
    <div
      role="tabpanel"
      className="notranslate"
      hidden={props.tabValue !== 1}
      id={`vertical-tabpanel-${1}`}
    >
      <div ref={ref} style={{ flexDirection: "row" }}>
        <div className={"flex-row"} id={"filter-group"}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <SimpleForm toolbar={false} style={{ maxWidth: 340 }}>
                <ReferenceInput
                  perPage={5}
                  filterToQuery={(searchText) =>
                    !!searchText && searchText.trim().length > 2
                      ? {
                          businessName: { contains: searchText },
                          roles: { array_contains: "creditor" },
                        }
                      : { roles: { array_contains: "creditor" } }
                  }
                  isRequired
                  allowEmpty
                  emptyText="-"
                  source={"creditor.businessName"}
                  reference={"User"}
                  label={"Creditor"}
                  onChange={(searchTerm) => {
                    setClaimsToShow(0);
                    setClaims([]);
                    props.setIsLoading(true);
                    setActiveSearchTerm(searchTerm);
                  }}
                >
                  <AutocompleteInput
                    suggestionLimit={5}
                    shouldRenderSuggestions={(value) => {
                      return value && value.trim().length > 2;
                    }}
                    optionText={"businessName"}
                  />
                </ReferenceInput>
              </SimpleForm>
            </Grid>
            <Grid item xs={12} lg={6} className="filter-adjust">
              <Tooltip title={"Reload"}>
                <RotateLeftIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                  }}
                  onClick={() => {
                    setClaimsToShow(0);
                    setClaims([]);
                    props.setIsLoading(true);
                    fetchData(true);
                  }}
                />
              </Tooltip>

              <Tooltip
                title={
                  openClaimsSortField === "updatedAt"
                    ? "Sort by value"
                    : "Sort by date"
                }
              >
                <AttachMoney
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    height: 20,
                    color:
                      openClaimsSortField === "updatedAt"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortField();
                  }}
                >
                  {openClaimsSortField === "updatedAt"
                    ? "Sort by value"
                    : "Sort by date"}
                </AttachMoney>
              </Tooltip>
              <Tooltip
                title={
                  openClaimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"
                }
              >
                <SortByAlphaOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    height: 20,
                    color:
                      openClaimsSortOrder === "asc"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortOrder();
                  }}
                >
                  {openClaimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"}
                </SortByAlphaOutlined>
              </Tooltip>
              <Tooltip
                title={"Filter User Type"}
                onClick={() => {
                  setClaimsToShow(0);
                  setClaims([]);
                  props.setIsLoading(true);
                  setUserTypeFilter(
                    userTypeFilter === "Business"
                      ? ""
                      : userTypeFilter === "Consumer"
                      ? "Business"
                      : "Consumer"
                  );
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    color:
                      userTypeFilter === "Consumer"
                        ? "#995200"
                        : userTypeFilter === "Business"
                        ? "#000099"
                        : "",
                    background:
                      userTypeFilter === "Consumer"
                        ? "#ffc480"
                        : userTypeFilter === "Business"
                        ? "#d1d1ff"
                        : "",
                    padding: 5,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {userTypeFilter === "Consumer"
                    ? "B2C"
                    : userTypeFilter === "Business"
                    ? "B2B"
                    : "B2X"}
                </span>
              </Tooltip>
              <Tooltip
                title={"Filter Activity Type"}
                onClick={() => {
                  setClaimsToShow(0);
                  setClaims([]);
                  props.setIsLoading(true);
                  setActivityTypeFilter(
                    activityTypeFilter === "Other"
                      ? ""
                      : activityTypeFilter === "Debtor"
                      ? "Other"
                      : activityTypeFilter === "Creditor"
                      ? "Debtor"
                      : "Creditor"
                  );
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    color:
                      activityTypeFilter === "Creditor"
                        ? "#995200"
                        : activityTypeFilter === "Debtor"
                        ? "#000099"
                        : activityTypeFilter === "Other"
                        ? "#16450e"
                        : "",
                    background:
                      activityTypeFilter === "Creditor"
                        ? "#ffc480"
                        : activityTypeFilter === "Debtor"
                        ? "#d1d1ff"
                        : activityTypeFilter === "Other"
                        ? "#7fc46d"
                        : "",
                    padding: 5,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {activityTypeFilter === "Other"
                    ? "Other"
                    : activityTypeFilter === "Creditor"
                    ? "Creditors"
                    : activityTypeFilter === "Debtor"
                    ? "Debtors"
                    : "Activity Type"}
                </span>
              </Tooltip>
              <Tooltip
                title={"Filter Legal Type"}
                onClick={() => {
                  setClaimsToShow(0);
                  setClaims([]);
                  props.setIsLoading(true);
                  setLegalTypeFilter(
                    legalTypeFilter === "Court"
                      ? "Lawyers"
                      : legalTypeFilter === "Lawyers"
                      ? "Nothing"
                      : legalTypeFilter === "Nothing"
                      ? ""
                      : "Court"
                  );
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    color:
                      legalTypeFilter === "Court"
                        ? "#995200"
                        : legalTypeFilter === "Lawyers"
                        ? "#000099"
                        : legalTypeFilter === "Nothing"
                        ? "#16450e"
                        : "",
                    background:
                      legalTypeFilter === "Court"
                        ? "#ffc480"
                        : legalTypeFilter === "Lawyers"
                        ? "#d1d1ff"
                        : legalTypeFilter === "Nothing"
                        ? "#7fc46d"
                        : "",
                    padding: 5,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {legalTypeFilter === "Court"
                    ? "Court"
                    : legalTypeFilter === "Lawyers"
                    ? "Lawyers"
                    : legalTypeFilter === "Nothing"
                    ? "Non-Legal"
                    : "Legalities"}
                </span>
              </Tooltip>
              <Button
                style={{
                  position: "relative",
                  cursor: "pointer",
                  minWidth: 130,
                  marginLeft: 10,
                  fontWeight: "bold",
                }}
                size={"medium"}
                variant="outlined"
                label={hideNewClaims ? "Waiting" : "New"}
                startIcon={
                  hideNewClaims ? (
                    <HourglassEmptyOutlined />
                  ) : (
                    <NotificationsActiveOutlined />
                  )
                }
                onClick={() => {
                  setClaimsToShow(0);
                  setClaims([]);
                  props.setIsLoading(true);
                  toggleHideNewClaims();
                }}
              />
            </Grid>
          </Grid>
        </div>
        <GridHeader />
        {claims && claims.length > 0
          ? claims.map((claim: Claim) => {
              return (
                <ClaimSummary
                  claim={claim}
                  type="claim"
                  key={claim.id}
                  refresh={() => {
                    fetchData(true);
                  }}
                />
              );
            })
          : "-"}

        <Button
          style={{
            marginTop: 10,
            maxWidth: 200,
            marginLeft: 0,
          }}
          onClick={() => {
            increaseClaimsToShow();
          }}
          label="Load more"
          disabled={props.isLoading}
        />
      </div>
    </div>
  );
});

export default ClaimsToDoTab;
