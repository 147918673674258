import {
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AssignmentIndOutlined,
  CallOutlined,
  EditOutlined,
  Gavel,
  Mail,
  MoneyOff,
  Notifications,
  StarRounded,
} from "@material-ui/icons";
import Explore from "@material-ui/icons/Explore";
import Phone from "@material-ui/icons/Phone";
import Timelapse from "@material-ui/icons/Timelapse";
// @ts-ignore
import ct from "countries-and-timezones";
import { formatDistance } from "date-fns/formatDistance";
import React, { useCallback, useMemo, useState } from "react";
import { useGetIdentity, useGetOne, useNotify } from "react-admin";
import ReactCountryFlag from "react-country-flag";
import { useSwipeable } from "react-swipeable";
import {
  getReadableActivityType,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import {
  ADDRESS_COUNTRY_READABLE,
  ADDRESS_COUNTRY_SHORT,
  normalizePhoneNumber,
} from "../api/address/Address";
import {
  EnumAddressCountry,
  GERMAN_SPEAKING_COUNTRIES,
  getTimezoneNameByCountryAndZip,
} from "../api/address/EnumAddressCountry";
import { Claim } from "../api/claim/Claim";
import { usePhone } from "../context/PhoneContext";
import { theme } from "../theme/theme";
import { aircallService } from "../util/AirCallCalls";
import { searchMapByKey } from "../util/MapUtils";
import { getInitials, stringToHslColor } from "../util/StyleUtils";
import { commentWithMentions, sanitizeString } from "./ActivityEventItem";
import AssignClaimForm from "./fields/AssignClaimForm";

const SLIDE_OFFSET: number = 180;

export const ClaimSummary = React.memo(
  ({
    claim,
    type,
    refresh,
  }: {
    claim: Claim;
    type: "call" | "other" | "claim" | "research";
    refresh?: () => void;
  }) => {
    const [showQuickActions, setShowQuickActions] = useState(false);
    const [showAssigneeList, setShowAssigneeList] = useState(false);
    const { data: debtorData } = useGetOne("User", claim?.debtor.id, {
      enabled: !!claim?.debtor.id,
    });
    const { identity } = useGetIdentity();
    const notify = useNotify();
    const { airCallTab, setAirCallTab } = usePhone();

    const handleToggleQuickActions = useCallback(() => {
      setShowQuickActions((prev) => !prev);
    }, []);

    const handleCloseAssigneeModal = useCallback(() => {
      setShowAssigneeList((prev) => !prev);
    }, []);

    const handlers = useSwipeable({
      onSwipedLeft: () => setShowQuickActions(true),
      onSwiped: () => setShowQuickActions(!showQuickActions),
      onSwipedRight: () => setShowQuickActions(false),
      preventScrollOnSwipe: true,
      trackMouse: true,
    });

    const relevantActivity = claim?.activities?.[0];

    const lastUpdate = useMemo(() => {
      return new Date(
        (type === "claim"
          ? claim?.updatedAt
          : relevantActivity?.activityDate) || Date.now()
      );
    }, [type, claim?.updatedAt, relevantActivity?.activityDate]);

    const distance = useMemo(() => {
      return formatDistance(new Date(lastUpdate), new Date(), {
        addSuffix: true,
      });
    }, [lastUpdate]);

    const iso2CountryCode = useMemo(() => {
      return searchMapByKey(
        ADDRESS_COUNTRY_SHORT,
        (claim?.debtor?.address?.languageCountry ||
          claim?.debtor?.address?.country) as EnumAddressCountry
      );
    }, [
      claim?.debtor?.address?.languageCountry,
      claim?.debtor?.address?.country,
    ]);

    const timeZones = useMemo(() => {
      // Consider potential performance impact of this library/call
      return ct.getTimezonesForCountry(iso2CountryCode);
    }, [iso2CountryCode]);

    const relevantTimeZone = useMemo(() => {
      let relevantTimeZone: any = timeZones?.[0];
      if (iso2CountryCode === "US") {
        relevantTimeZone = timeZones?.find(
          (timeZone) => timeZone.name === "America/Chicago" // use central time (kinda average)
        );
      } else if (iso2CountryCode === "CA") {
        relevantTimeZone = timeZones?.find(
          (timeZone) => timeZone.name === "America/Toronto" // use eastern time (kinda average)
        );
      } else if (
        iso2CountryCode === "AU" &&
        claim?.debtor?.address?.postalcode
      ) {
        // Consider potential performance impact of this function
        const timezoneByZip = getTimezoneNameByCountryAndZip(
          iso2CountryCode,
          claim?.debtor?.address?.postalcode
        );
        if (!!timezoneByZip) {
          relevantTimeZone = {
            name: timezoneByZip,
          };
        }
      }

      return relevantTimeZone;
    }, [claim?.debtor?.address?.postalcode, iso2CountryCode, timeZones]);

    const timeZoneFormatter = useMemo(() => {
      if (relevantTimeZone) {
        return new Intl.DateTimeFormat("de-DE", {
          timeZone: relevantTimeZone.name,
          hour: "numeric",
          minute: "numeric",
        });
      }
    }, [relevantTimeZone]);

    const numberFormat = useMemo(() => {
      return Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
    }, []);

    const handleAddClaimAssignee = useCallback(() => {
      handleCloseAssigneeModal();
    }, [handleCloseAssigneeModal]);

    const handlePhoneDial = useCallback(async () => {
      let debtorPhone = debtorData?.phone;

      if (!debtorPhone) {
        console.error("No phone number available for this claim");
        notify("No phone number available for this claim", "warning"); // Notify user
        return;
      }

      try {
        // Ensure identity and claim are available
        if (
          !identity?.username ||
          !identity?.id ||
          !claim?.debtor?.address?.country
        ) {
          console.error(
            "Missing required data for dialing (identity or claim address)"
          );
          notify("Cannot initiate call: Missing user or claim data", "error");
          return;
        }

        const normalizedNumber = normalizePhoneNumber(
          debtorPhone,
          claim.debtor.address.country as EnumAddressCountry,
          true
        );

        if (!normalizedNumber) {
          console.error("Failed to normalize phone number");
          notify("Invalid phone number format", "error");
          return;
        }

        const result = await aircallService.dialNumber(
          identity.username,
          identity.id,
          normalizedNumber
        );

        if (result?.json) {
          // Assuming result indicates success
          notify("Dialing Call", "success");
          if (airCallTab && !airCallTab.closed) {
            airCallTab.focus();
          } else {
            const newTab = window.open("https://phone.aircall.io/", "_blank");
            setAirCallTab(newTab);
          }
        } else {
          notify("Could not dial :( Wrong number? Busy?", "error");
        }
      } catch (error) {
        console.error("Error creating individual call campaign:", error);
        notify("Error initiating call", "error");
      }
    }, [debtorData, identity, claim, notify, airCallTab, setAirCallTab]); // Added setAirCallTab dependency

    const hasLawyer = useMemo(() => {
      return claim?.contacts?.some((contact) => contact.userType === "Lawyer");
    }, [claim?.contacts]);

    const hasInsolvencyAdministrator = useMemo(() => {
      return claim?.contacts?.some(
        (contact) => contact.userType === "InsolvencyAdministrator"
      );
    }, [claim?.contacts]);

    const comment = useMemo(() => {
      const rawComment = relevantActivity?.comment;
      if (!rawComment) return "";
      // Ensure sanitizeString is robust against XSS
      return sanitizeString(
        rawComment.replace(commentWithMentions, "<b class='mention'>@$1</b>")
      );
    }, [relevantActivity?.comment]);

    if (!claim) {
      return null;
    }

    return (
      <div key={claim.id}>
        <Card {...handlers} variant="outlined" className="list__item">
          <div className="relativity">
            <CardActionArea
              href={
                `/#/Claim/${claim.id}/show` +
                (type !== "research" && type !== "other" ? "/2" : "")
              }
              style={{
                // Consider moving to CSS classes
                transform: showQuickActions
                  ? `translateX(-${SLIDE_OFFSET}px)`
                  : "translateX(0)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <Grid container className="grid-helper">
                {/* Grid Item 1: Reference/Stage */}
                <Grid
                  item
                  style={{
                    // Consider moving to CSS classes
                    maxWidth: "12%",
                    flexBasis: "12%",
                  }}
                >
                  <Typography
                    component={"span"}
                    variant={"body2"}
                    style={{
                      // Consider moving to CSS classes
                      fontWeight: "600",
                      color: theme.palette.grey[700],
                      wordBreak: "break-word",
                      maxWidth: "100%",
                    }}
                  >
                    {claim?.stage === "Reminder" ? (
                      <Typography
                        component={"span"}
                        variant={"caption"}
                        style={{ color: theme.palette.info.main }} // Consider moving to CSS classes
                      >
                        <Notifications
                          style={{ height: 14, position: "relative", top: 3 }} // Consider moving to CSS classes
                        />
                        <>Reminder</>
                      </Typography>
                    ) : (
                      <Typography
                        component={"span"}
                        variant={"caption"}
                        style={{
                          // Consider moving to CSS classes
                          color:
                            claim?.stage === "Court"
                              ? theme.palette.error.dark
                              : theme.palette.secondary.main,
                        }}
                      >
                        <>
                          {claim?.stage === "Court" ? (
                            <Gavel
                              style={{
                                // Consider moving to CSS classes
                                height: 14,
                                position: "relative",
                                top: 3,
                              }}
                            />
                          ) : (
                            <Mail
                              style={{
                                // Consider moving to CSS classes
                                height: 14,
                                position: "relative",
                                top: 3,
                              }}
                            />
                          )}
                          {claim.reference || claim.id}
                        </>
                      </Typography>
                    )}
                  </Typography>
                </Grid>

                {/* Grid Item 2: Creditor */}
                <Grid
                  style={{
                    // Consider moving to CSS classes
                    maxWidth: "20%",
                    flexBasis: "20%",
                  }}
                  item
                >
                  <div className="claim-creditor">
                    <Typography
                      component={"span"}
                      variant={"body2"}
                      style={{
                        // Consider moving to CSS classes
                        fontWeight: "600",
                        wordBreak: "break-word",
                        maxWidth: "100%",
                      }}
                    >
                      <span
                        style={{
                          // Consider moving to CSS classes
                          color: stringToHslColor(
                            claim.creditor?.businessName,
                            30
                          ),
                          background: stringToHslColor(
                            claim.creditor?.businessName,
                            95
                          ),
                          borderRadius: 4,
                          padding: "2px 6px",
                        }}
                      >
                        {claim.creditor?.businessName}
                      </span>
                      {!!claim.creditor?.operatorCategory && (
                        <Tooltip
                          title={
                            !!claim.creditor?.operatorCategory &&
                            ` ${claim.creditor?.operatorCategory}`
                          }
                        >
                          <span
                            style={{
                              // Consider moving to CSS classes
                              borderColor: stringToHslColor(
                                claim.creditor?.operatorCategory,
                                70
                              ),
                              color: stringToHslColor(
                                claim.creditor?.operatorCategory,
                                30
                              ),
                              borderWidth: 1,
                              borderStyle: "solid",
                              padding: "3px 6px",
                              marginLeft: 6,
                              verticalAlign: "text-top",
                              fontSize: 12,
                              fontWeight: "bold",
                              borderRadius: 8,
                            }}
                          >
                            {claim.creditor?.operatorCategory
                              ?.slice(0, 3)
                              .toUpperCase()}
                          </span>
                        </Tooltip>
                      )}
                      {claim.creditor?.isVip && (
                        <Tooltip title={"VIP"}>
                          <StarRounded
                            style={{
                              // Consider moving to CSS classes
                              marginLeft: -4,
                              marginTop: -4,
                              marginRight: -8,
                              height: 14,
                              color: theme.palette.warning.main,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Typography>
                  </div>
                </Grid>

                {/* Grid Item 3: Debtor */}
                <Grid
                  item
                  style={{
                    // Consider moving to CSS classes
                    maxWidth: "20%",
                    flexBasis: "20%",
                  }}
                >
                  <div className="claim-debtor">
                    <div className="row">
                      <Typography
                        component={"span"}
                        variant={"body2"}
                        style={{ lineHeight: "16px" }} // Consider moving to CSS classes
                      >
                        <span
                          style={{
                            // Consider moving to CSS classes
                            fontWeight: "bold",
                            wordBreak: "break-word",
                            maxWidth: "100%",
                          }}
                        >
                          {claim.debtor?.businessName ||
                            claim.debtor?.contactName ||
                            claim.debtor?.email}
                        </span>

                        <ReactCountryFlag
                          countryCode={iso2CountryCode}
                          svg
                          style={{ marginLeft: 4, marginRight: 2 }} // Consider moving to CSS classes
                        />
                        {claim.debtor?.address?.country &&
                          !GERMAN_SPEAKING_COUNTRIES.includes(
                            claim.debtor?.address?.country as EnumAddressCountry
                          ) && (
                            <div>
                              {ADDRESS_COUNTRY_READABLE.get(
                                claim.debtor?.address
                                  ?.country as EnumAddressCountry
                              )}
                              {timeZoneFormatter &&
                                ", " + timeZoneFormatter.format(new Date())}
                            </div>
                          )}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                {/* Grid Item 4: Due/Amount */}
                <Grid
                  item
                  style={{
                    // Consider moving to CSS classes
                    maxWidth: "22%",
                    flexBasis: "22%",
                  }}
                >
                  <div className="claim-due">
                    <Typography component={"span"} variant="body2">
                      <span
                        title="Business Type"
                        style={{
                          // Consider moving to CSS classes
                          color:
                            claim.debtor?.businessType === "Consumer"
                              ? "#995200"
                              : "#000099",
                          background:
                            claim.debtor?.businessType === "Consumer"
                              ? "#ffc480"
                              : "#d1d1ff",
                          padding: "2px 4px",
                          marginRight: 8,
                          fontSize: 12,
                          fontWeight: "bold",
                          borderRadius: 8,
                        }}
                      >
                        {claim.debtor?.businessType === "Consumer"
                          ? "B2C"
                          : "B2B"}
                      </span>
                      <span
                        style={{
                          // Consider moving to CSS classes
                          fontWeight: "bold",
                        }}
                      >
                        {distance}
                      </span>
                      {" | "}
                      <>{numberFormat.format(claim.totalPending)}</>
                    </Typography>
                  </div>
                </Grid>

                {/* Grid Item 5: Activity/Assignee */}
                <Grid xs={3} item>
                  <div
                    style={{
                      // Consider moving to CSS classes
                      fontSize: 14,
                      textAlign: "right",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tooltip title={comment}>
                      <div
                        style={{
                          // Consider moving to CSS classes
                          width: "66%",
                          fontSize: 11,
                          textAlign: "left",
                        }}
                      >
                        <>
                          <b>
                            {type === "claim" &&
                              relevantActivity?.activityType &&
                              `${getReadableActivityType(
                                relevantActivity.activityType
                              )}`}
                            {type === "other" &&
                              relevantActivity?.claimAction &&
                              `${getReadableSubjectByClaimAction(
                                relevantActivity.claimAction
                              )}`}
                          </b>
                          {type === "claim" &&
                          relevantActivity?.activityType &&
                          !!relevantActivity.isPlanned
                            ? `, ${formatDistance(
                                new Date(
                                  relevantActivity.activityDate || Date.now()
                                ),
                                new Date(),
                                {
                                  addSuffix: true,
                                }
                              )}`
                            : ""}
                        </>
                        <br />
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              comment?.slice(0, 66) +
                              (comment?.length > 66 ? "..." : ""),
                          }}
                        />
                      </div>
                    </Tooltip>
                    {claim.stage === "Court" && (
                      <span
                        title="Court Stage"
                        style={{
                          // Consider moving to CSS classes
                          color: theme.palette.error.dark,
                          padding: 5,
                          marginLeft: 5,
                          marginRight: 10,
                          fontSize: 12,
                          fontWeight: "bold",
                          borderRadius: 25, // This creates a circle, intentional?
                        }}
                      >
                        Court
                      </span>
                    )}

                    {hasLawyer && (
                      <span
                        title="Lawyer Involved"
                        style={{
                          // Consider moving to CSS classes
                          background: theme.palette.error.dark,
                          color: "white",
                          padding: 5,
                          marginRight: 10,
                          fontSize: 12,
                          fontWeight: "bold",
                          borderRadius: 25,
                        }}
                      >
                        <Gavel
                          style={{
                            // Consider moving to CSS classes
                            height: 12,
                            width: 12,
                            position: "relative",
                            top: 2,
                          }}
                        />
                      </span>
                    )}
                    {hasInsolvencyAdministrator && (
                      <span
                        title="Insolvency Administrator Involved"
                        style={{
                          // Consider moving to CSS classes
                          background: theme.palette.error.dark,
                          color: "white",
                          padding: 5,
                          marginRight: 10,
                          fontSize: 12,
                          fontWeight: "bold",
                          borderRadius: 25,
                        }}
                      >
                        <MoneyOff
                          style={{
                            // Consider moving to CSS classes
                            height: 12,
                            width: 12,
                            position: "relative",
                            top: 2,
                          }}
                        />
                      </span>
                    )}

                    {type === "call" && (
                      <Phone
                        style={{
                          // Consider moving to CSS classes
                          width: "auto",
                          height: 30,
                          opacity: 0.1,
                        }}
                      />
                    )}
                    {type === "claim" && (
                      <Timelapse
                        style={{
                          // Consider moving to CSS classes
                          width: "auto",
                          height: 30,
                          opacity: 0.1,
                        }}
                      />
                    )}
                    {type === "research" && (
                      <Explore
                        style={{
                          // Consider moving to CSS classes
                          width: "auto",
                          height: 30,
                          opacity: 0.1,
                        }}
                      />
                    )}

                    <div
                      style={{
                        // Consider moving to CSS classes
                        width: 35,
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!claim.assignee && claim.manager && (
                        <Tooltip
                          title={"Letzter Bearbeiter: " + claim.manager.name}
                        >
                          <div className="assigned-user assigned-user--operator">
                            {getInitials(claim.manager.name)}
                          </div>
                        </Tooltip>
                      )}

                      {claim.assignee && (
                        <Tooltip title={claim.assignee.name}>
                          <div className="assigned-user">
                            {getInitials(claim.assignee.name)}
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardActionArea>
            <Grid
              item
              style={{
                // Consider moving to CSS classes
                transform: showQuickActions
                  ? "translateX(0)"
                  : "translateX(100%)",
                transition: "transform 0.3s ease-in-out",
                background: "rgb(243, 246, 247)",
                borderTopLeftRadius: showQuickActions ? 8 : 0,
                borderBottomLeftRadius: showQuickActions ? 8 : 0,
                position: "absolute",
                right: 0,
                width: SLIDE_OFFSET,
                top: 0,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {claim.debtor?.phone && (
                <Tooltip title="Call Debtor">
                  <IconButton onClick={handlePhoneDial}>
                    <CallOutlined color={"secondary"} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Assign to User">
                <IconButton onClick={handleAddClaimAssignee}>
                  <AssignmentIndOutlined style={{ color: "#32c587" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Claim">
                <IconButton onClick={() => window.open(`/#/Claim/${claim.id}`)}>
                  <EditOutlined style={{ color: "var(--primary)" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </div>
          <Button
            onClick={handleToggleQuickActions}
            className={`toggle-quick-actions ${
              showQuickActions ? "-open" : ""
            }`} // Assumes CSS handles the button style/position
          >
            <span className="close-open"></span>{" "}
          </Button>
        </Card>

        {/* Assignee Modal */}
        <Modal open={showAssigneeList} onClose={handleCloseAssigneeModal}>
          <Box className="modal-box">
            {" "}
            <List>
              <ListItem>
                <Typography component={"span"} variant={"h5"} align={"left"}>
                  {"Assign User to Claim"}
                </Typography>
              </ListItem>
              <AssignClaimForm
                claimId={claim.id}
                setShow={setShowAssigneeList}
                refresh={refresh}
                assigneeId={undefined}
              />
            </List>
          </Box>
        </Modal>
      </div>
    );
  }
);
