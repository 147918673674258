import { Grid, Tooltip } from "@material-ui/core";
import { AttachMoney, SortByAlphaOutlined } from "@material-ui/icons";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import React, { useEffect, useRef, useState } from "react";
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { getReadableSubjectByClaimAction } from "../../../api/activity/Activity";
import { Claim } from "../../../api/claim/Claim";
import { theme } from "../../../theme/theme";
import { TODAY } from "../../../util/DateUtils";
import { getAuthToken } from "../../../util/JsonFilter";
import { ClaimSummary } from "../../ClaimSummary";
import GridHeader from "../../GridHeader";
import { NonInput } from "../../NonInputComponent";

const ResearchToDoTab = React.memo((props: any) => {
  const limitPerPage = 25;
  const [claims, setClaims] = useState<Claim[]>([]);

  const [claimsToShow, setClaimsToShow] = useState(25);
  const [activeSearchTerm, setActiveSearchTerm] = useState("");
  const [claimActionFilter, setClaimActionFilter] = useState("");
  const [openClaimsSortField, setOpenClaimsSortField] = useState("updatedAt");
  const [claimsSortOrder, setClaimsSortOrder] = useState("asc");
  const [openClaimsSortOrder, setOpenClaimsSortOrder] = useState("asc");
  const [userTypeFilter, setUserTypeFilter] = useState<String | undefined>();

  function toggleClaimsSortOrder(): void {
    setClaimsSortOrder(claimsSortOrder === "desc" ? "asc" : "desc");
  }

  function toggleOpenClaimsSortField(): void {
    setOpenClaimsSortField(
      openClaimsSortField === "updatedAt" ? "totalPending" : "updatedAt"
    );
    if (openClaimsSortOrder === "asc" && openClaimsSortField === "updatedAt") {
      setOpenClaimsSortOrder("desc");
    }
  }

  const ref = useRef();

  const fetchData = (replace?: boolean) => {
    const authHeader = {
      headers: {
        Authorization: getAuthToken(),
        "Content-Type": "application/json",
      },
    };

    props.setIsLoading(true);
    fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
      method: "POST",
      body: JSON.stringify({
        type: "research",
        disablePersonalization:
          props.personalization === false ? true : undefined,
        userId: !!props.selectedManager ? props.selectedManager : undefined,
        enableLegal: props.enableLegal === true ? true : undefined,
        where: {
          activities: {
            some: {
              ...(claimActionFilter && {
                claimAction: claimActionFilter,
              }),
              activityType: "Research",
              activityDate: {
                lte: TODAY,
              },
              isPlanned: true,
            },
            none: {
              comment: {
                contains: "Automated",
              },
            },
          },
          stage: {
            in: ["Precourt", "Court", "Monitoring"],
          },
          ...(activeSearchTerm && {
            creditor: {
              id: activeSearchTerm,
            },
          }),
          ...(userTypeFilter === "Consumer" && {
            debtor: {
              businessType: "Consumer",
            },
          }),
          ...(userTypeFilter === "Business" && {
            debtor: {
              businessType: {
                not: "Consumer",
              },
            },
          }),
        },
        orderBy: {
          [openClaimsSortField]: claimsSortOrder,
        },
      }),
      ...authHeader,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          props.setTotalResearch(responseJson.total);
          if (openClaimsSortField === "updatedAt") {
            const sortedClaims = responseJson.results?.sort(
              (a: Claim, b: Claim) => {
                if (claimsSortOrder === "asc") {
                  return (
                    new Date(
                      a.activities?.[0]?.activityDate || Date.now()
                    ).getTime() -
                    new Date(
                      b.activities?.[0]?.activityDate || Date.now()
                    ).getTime()
                  );
                } else {
                  return (
                    new Date(
                      b.activities?.[0]?.activityDate || Date.now()
                    ).getTime() -
                    new Date(
                      a.activities?.[0]?.activityDate || Date.now()
                    ).getTime()
                  );
                }
              }
            );
            if (replace) {
              setClaims(sortedClaims);
            } else {
              setClaims([...claims, ...sortedClaims]);
            }
          } else {
            if (replace) {
              setClaims(responseJson.results);
            } else {
              setClaims([...claims, ...responseJson.results]);
            }
          }
          props.setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    // Only fetch when tab is newly activated and no existing data
    if (props.tabValue === 3 && !claims?.length) {
      fetchData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabValue, props.load]);

  useEffect(() => {
    // Only refresh when filters change and tab is active
    // This prevents duplicate fetches when tab becomes active
    if (props.tabValue === 3) {
      // Only fetch if we already have claims or any filter is set
      // This prevents duplicate fetches with the first useEffect
      if (
        claims?.length ||
        activeSearchTerm ||
        claimActionFilter ||
        props.personalization === false ||
        props.selectedManager ||
        props.enableLegal === true ||
        userTypeFilter
      ) {
        fetchData(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.personalization,
    props.selectedManager,
    props.enableLegal,
    activeSearchTerm,
    claimsSortOrder,
    openClaimsSortField,
    claimActionFilter,
    userTypeFilter,
  ]);

  return (
    <div
      role="tabpanel"
      className="notranslate"
      hidden={props.tabValue !== 3}
      id={`vertical-tabpanel-${3}`}
    >
      <div ref={ref} style={{ flexDirection: "row" }}>
        <div className="flex-row" id="filter-group">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <SimpleForm toolbar={false} style={{ display: "flex" }}>
                <NonInput>
                  <div className="tab-row">
                    <div style={{ width: 310, marginRight: 20 }}>
                      <ReferenceInput
                        perPage={5}
                        filterToQuery={(searchText) =>
                          !!searchText && searchText.trim().length > 2
                            ? {
                                businessName: { contains: searchText },
                                roles: { array_contains: "creditor" },
                              }
                            : {
                                roles: { array_contains: "creditor" },
                              }
                        }
                        isRequired
                        allowEmpty
                        emptyText="-"
                        source={"creditor.businessName"}
                        reference={"User"}
                        label={"Creditor"}
                        onChange={(searchTerm) => {
                          setClaimsToShow(0);
                          setActiveSearchTerm(searchTerm);
                        }}
                      >
                        <AutocompleteInput
                          fullWidth
                          suggestionLimit={5}
                          shouldRenderSuggestions={(value) => {
                            return value && value.trim().length > 2;
                          }}
                          optionText={"businessName"}
                        />
                      </ReferenceInput>
                    </div>

                    <div style={{ width: 340 }}>
                      <SelectInput
                        style={{ width: 310 }}
                        source="claimAction"
                        label="Claim Action"
                        choices={[
                          {
                            label:
                              getReadableSubjectByClaimAction("OnlineResearch"),
                            value: "OnlineResearch",
                          },
                          {
                            label:
                              getReadableSubjectByClaimAction("PhoneResearch"),
                            value: "PhoneResearch",
                          },
                          {
                            label:
                              getReadableSubjectByClaimAction("EmailResearch"),
                            value: "EmailResearch",
                          },
                          {
                            label: getReadableSubjectByClaimAction(
                              "PublicAuthorityRequest"
                            ),
                            value: "PublicAuthorityRequest",
                          },
                        ]}
                        optionText="label"
                        allowEmpty
                        optionValue="value"
                        onChange={(e) => setClaimActionFilter(e.target.value)}
                      />
                    </div>
                  </div>
                </NonInput>
              </SimpleForm>
            </Grid>
            <Grid item xs={12} md={4} className="filter-container">
              <Tooltip title={"Reload"}>
                <RotateLeftIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                  }}
                  onClick={() => fetchData(true)}
                />
              </Tooltip>

              <Tooltip
                title={
                  openClaimsSortField === "updatedAt"
                    ? "Sort by value"
                    : "Sort by date"
                }
              >
                <AttachMoney
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    height: 20,
                    color:
                      openClaimsSortField === "updatedAt"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortField();
                  }}
                >
                  {openClaimsSortField === "updatedAt"
                    ? "Sort by value"
                    : "Sort by date"}
                </AttachMoney>
              </Tooltip>

              <Tooltip
                title={
                  claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"
                }
              >
                <SortByAlphaOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    color:
                      claimsSortOrder === "asc"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleClaimsSortOrder();
                  }}
                >
                  {claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"}
                </SortByAlphaOutlined>
              </Tooltip>

              <Tooltip
                title={"Filter B2X"}
                onClick={() => {
                  setUserTypeFilter(
                    userTypeFilter === "Business"
                      ? ""
                      : userTypeFilter === "Consumer"
                      ? "Business"
                      : "Consumer"
                  );
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    color:
                      userTypeFilter === "Consumer"
                        ? "#995200"
                        : userTypeFilter === "Business"
                        ? "#000099"
                        : "",
                    background:
                      userTypeFilter === "Consumer"
                        ? "#ffc480"
                        : userTypeFilter === "Business"
                        ? "#d1d1ff"
                        : "",
                    padding: 5,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {userTypeFilter === "Consumer"
                    ? "B2C"
                    : userTypeFilter === "Business"
                    ? "B2B"
                    : "B2X"}
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </div>

        <GridHeader />

        {claims && claims.length > 0
          ? claims
              .slice(0, claimsToShow || limitPerPage)
              .map((claim: Claim) => {
                return (
                  <ClaimSummary
                    claim={claim}
                    type="research"
                    key={claim.id}
                    refresh={() => {
                      fetchData(true);
                    }}
                  />
                );
              })
          : "-"}

        <Button
          style={{
            marginTop: 10,
            maxWidth: 200,
            marginLeft: 0,
          }}
          onClick={() => {
            setClaimsToShow(claimsToShow + limitPerPage);
          }}
          label="Load more"
        />
      </div>
    </div>
  );
});

export default ResearchToDoTab;
