import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceField,
  SelectInput,
  TextField,
} from "react-admin";
import CustomPagination from "../Components/Pagination";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const ServiceCallList = (props: ListProps): React.ReactElement => {
  const serviceCallFilters = [
    <SelectInput
      alwaysOn
      source="serviceCallType"
      label="Type"
      choices={[{ label: "CreditRating", value: "CreditRating" }]}
      optionText="label"
      optionValue="value"
    />,
    <SelectInput
      alwaysOn
      source="serviceCallSubType"
      label="Sub Type"
      choices={[
        { label: "B2CDE", value: "B2CDE" },
        { label: "B2CAT", value: "B2CAT" },
        { label: "B2CCH", value: "B2CCH" },
        { label: "B2BDE", value: "B2BDE" },
        { label: "B2BAT", value: "B2BAT" },
        { label: "B2BCH", value: "B2BCH" },
      ]}
      optionText="label"
      optionValue="value"
    />,
  ];

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"ServiceCalls"}
      filters={serviceCallFilters}
      perPage={50}
      exporter={false}
      sort={{ field: "createdAt", order: "DESC" }}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="show" optimized>
        <TextField label="Type" source="serviceCallType" />
        <TextField label="Type" source="serviceCallSubType" />
        <ReferenceField label="User" source="user.id" reference="User">
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
      </Datagrid>
    </List>
  );
};
